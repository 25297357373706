import { API_URL } from '../helpers';
import Http from 'axios';


class Api {
  constructor() {
    Http.defaults.baseURL = API_URL;
    Http.defaults.timeout = 300000;
  }

  async get(resource) {
    let {token} = JSON.parse(localStorage.getItem('sombarToken'))
    let config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    };
    try {
      let res = await Http.get(`${API_URL}${resource}`, config);

      return this.successResponse(res);
    } catch (error) {
      return this.errorResponse(error);
    }
  }

  async destroy(resource) {
    let {token} = JSON.parse(localStorage.getItem('sombarToken'))
    let config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    };
    try {
      let res = await Http.delete(`${API_URL}${resource}`, config);
      return this.successResponse(res);
    } catch (error) {
      return this.errorResponse(error);
    }
  }

  async patch(resource, data) {
    let {token} = JSON.parse(localStorage.getItem('sombarToken'))
    let config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    };
    try {
      let res = await Http.patch(`${API_URL}${resource}`, data, config);
      return this.successResponse(res);
    } catch (error) {
      return this.errorResponse(error);
    }
  }

  async post(resource, params) {
    let parsedValue = localStorage.getItem('sombarToken');
    let parsedToken;
    let token;
    if(parsedValue){
      parsedToken = JSON.parse(localStorage.getItem('sombarToken'))
      token = parsedToken.token
    }

    let config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    };
    try {
      let response = await Http.post(resource, params, config);
      return this.successResponse(response);
    } catch (error) {
      return this.errorResponse(error);
    }
  }

  errorResponse(error) {
    if (!error.response.data.success) {
      return this.response(error.response);
    } else {
      return this.response(
        {
          data: 'Network Error',
          success: false
        }
      );
    }
  }

  successResponse(response) {
    return this.response(response);
  }

  response({
    data,
    status,
    headers
  }) {
    return {
      body: data,
      status,
      headers
    };
  }

}

export default new Api();