import React, { Component } from "react";
import styled from "styled-components";
import ProjectApi from "../../services/ProjectApi";
import * as ProjectAction from "../../actions/project";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from 'moment';

class TaskList extends Component {
  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    let data = {};
    if (nextProps.project && nextProps.project !== prevState.project) {
      data.project = nextProps.project;
      data.auth = nextProps.auth;
      data.val = nextProps.val;
    }
    let filteredUser =
      nextProps.project &&
      nextProps.project.user_projects.filter(
        (p, i) => p.userId === nextProps.selectedUserId
      );
    data.filteredUser = filteredUser
    data.tasks = filteredUser.length > 0 ? filteredUser[0].tasks : [];

    return data;
  }

  forLeader = (p, i) => {
    const { val } = this.state;
    return (
      <input
        type="checkbox"

        checked={p.isCompleted}
        onChange={
          val.isEditable
            ? () => this.isCheckedOrNot(i)
            : () => {
              return true;
            }
        }
        className={val.isEditable ? "" : "disableCheckBox"}
      />
    );
  };

  forUser = (p) => {
    return (
      <input
        type="checkBox"
        readOnly
        checked={p.isCompleted}
        className="disable"
      />
    );
  };

  isCheckedOrNot = (key) => {
    const { project } = this.state;
    const tasks = [];
    project.project_deliverables[0].tasks.map((t, i) => {
      if (key === i) {
        t.isCompleted ? (t.isCompleted = false) : (t.isCompleted = true);
      }
      tasks.push(t);
    });
    let data = { tasks };
    let params = {
      weekId: this.props.project.project_deliverables[0].weekId,
      projectId: this.props.project.id
    };
    this.props.updateProjectDeliverable(params, data);
    this.setState({ deliverables: project.project_deliverables[0].tasks })
    this.props.updateDeliverable(data);

  };

  isTaskCheckedOrNot = (key) => {
    const { tasks, project, auth } = this.state;
    const tasksList = [];
    tasks.map((t, i) => {
      if (key === i) {
        if (t.isCompleted) {
          t.isCompleted = false;
          t.taskCompletedDate = null;
        } else {
          t.isCompleted = true;
          t.taskCompletedDate = moment(new Date()).format("DD-MM-YYYY");
        }
      }
      tasksList.push(t)
    });
    let params = {
      // id: project.user_projects[0].id
      id: project.user_projects.find(p => p.user.id === auth.user.id).id
    };
    let data = { tasks: tasksList };
    this.props.updateProjectTask(params, data);
    this.setState({ tasks: tasksList })
  }

  deleteUserProject(e, id) {
    e.preventDefault();
    let weekId = this.props.val.id;
    ProjectApi.deleteUserProject({ weekId: weekId, projectId: id })
      .then((p) => {
        toastr.success("", p.message);
        this.setState({ modalDeleteProjectShow: false });
        this.props.listUserProjects(weekId);
        this.props.fetchUserTasks({ weekId: weekId })
      })
      .catch((err) => {
        toastr.error("", err.error.message);
      });
  }

  deleteUserProjectDeliverable(e, id) {
    e.preventDefault();
    let weekId = this.props.val.id;
    ProjectApi.deleteProjectDeliveable({ weekId: weekId, projectId: id })
      .then((p) => {
        toastr.success("", p.message);
        this.setState({ modalDeleteDeliverableShow: false });
        this.props.listUserProjects(weekId);
        this.props.fetchUserLeadingProjects(weekId);
      })
      .catch((err) => {
        toastr.error("", err.error.message);
      });
  };

  render() {
    const { project, auth, val, projectsList, tasks, filteredUser } = this.state;
    const { selectedUserId, profile } = this.props;
    return (
      <TaskListWrap>
        {filteredUser.map((p, i) => (
          <Link to={`/profile/${p.user.id}`} key={i}>
            <img src={p.user.profilePic} alt="membersImage" className="membersImage" key={i}></img>
          </Link>
        ))}

        <YourTaskList>
          <div>
            <h3>
              {filteredUser.map((p, i) => (
                <div key={i} className="userTaskWeek">
                  <span>
                    {p.user.firstName === "You" ||
                      (p.user.id === this.props.auth.user.id)
                      ? "Your" : `${p.user.firstName}`}
                    {p.tasks.length === 1 ? " Task" : " Tasks"} this week
                  </span>
                  <span className="userHour">
                    {p.hour === 1 ? (
                      <strong>
                        {p.hour} <small>Hr</small>
                      </strong>
                    ) : (
                      <strong>
                        {p.hour} <small>Hrs</small>
                      </strong>
                    )}
                  </span>
                </div>
              ))}
            </h3>
            <ul>
              {
                tasks.map((project, i) => (
                  <li key={i} className={project.isCompleted ? 'completedTask' : ''}>
                    <div>
                      {
                        selectedUserId === auth.user.id ? (
                          <input
                            type="checkbox"
                            checked={project.isCompleted}
                            onChange={val.isEditable ? () => this.isTaskCheckedOrNot(i) : () => { return true }}
                            className={val.isEditable ? "" : "disableCheckBox"}
                          />
                        ) : <input
                          type="checkbox"
                          checked={project.isCompleted ? project.isCompleted : false}
                          readOnly
                          className="disable"
                        />
                      }

                      {project.hasHash ? (<p dangerouslySetInnerHTML={{ __html: project.plainText }}></p>) : (<p>{project.task}</p>)}
                    </div>
                    <strong>
                      <span>{project.hour}</span> hrs
                    </strong>
                  </li>
                ))
              }
            </ul>
            {auth.user.id === this.props.selectedUserId && val.isEditable && !profile ? (
              <AddTaskWrap onClick={() => this.props.taskOpen(projectsList, project)}>
                <span className="glyphicon glyphicon-plus">
                  {" "}
                  Add/ Edit a Task
                </span>
              </AddTaskWrap>
            ) : (
              ""
            )}
            {auth.user.id === this.props.selectedUserId && val.isEditable ? (
              <RemoveProject
                id={`removeProjectTask`}
                onClick={() =>
                  this.setState({
                    modalDeleteProjectShow: true,
                    projectId: project.id
                  })
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="10"
                  height="14"
                  viewBox="0 0 10 14"
                >
                  <defs>
                    <path
                      id="f"
                      d="M2.25 14.75h6c.825 0 2-1.175 2-2v-8h-10v8c0 .825 1.175 2 2 2zm8-13h-2l-1-1h-4l-1 1h-2v2h10v-2z"
                    />
                  </defs>
                  <use
                    fill="#333"
                    fillRule="evenodd"
                    transform="translate(-.25 -.75)"
                    xlinkHref="#f"
                  />
                </svg>
                <span>Delete</span>
              </RemoveProject>
            ) : (
              ""
            )}
            <RemoveProjectModal
              this={this}
              show={this.state.modalDeleteProjectShow}
              onHide={() => this.setState({ modalDeleteProjectShow: false })}
            />
          </div>
        </YourTaskList>

        <ProjectLevelTask>
          <h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
            >
              <g fill="none" fillRule="evenodd">
                <rect width="16" height="16" fill="#3D4252" rx="2" />
                <rect
                  width="6.667"
                  height="1.333"
                  x="6"
                  y="3.333"
                  fill="#FFF"
                  rx=".667"
                />
                <circle cx="4" cy="4" r="1" fill="#FFF" />
                <circle cx="4" cy="8" r="1" fill="#FFF" />
                <circle cx="4" cy="12" r="1" fill="#FFF" />
                <rect
                  width="6.667"
                  height="1.333"
                  x="6"
                  y="7.333"
                  fill="#FFF"
                  rx=".667"
                />
                <rect
                  width="6.667"
                  height="1.333"
                  x="6"
                  y="11.333"
                  fill="#FFF"
                  rx=".667"
                />
              </g>
            </svg>
            Project Deliverables
          </h3>
          {val.isEditable && project.leaderId === auth.user.id && project.project_deliverables.length > 0 ? (
            <RemoveProject
              id={`removeProjectTask`}
              onClick={() =>
                this.setState({
                  modalDeleteDeliverableShow: true,
                  projectId: project.id
                })
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="10"
                height="14"
                viewBox="0 0 10 14"
              >
                <defs>
                  <path
                    id="f"
                    d="M2.25 14.75h6c.825 0 2-1.175 2-2v-8h-10v8c0 .825 1.175 2 2 2zm8-13h-2l-1-1h-4l-1 1h-2v2h10v-2z"
                  />
                </defs>
                <use
                  fill="#333"
                  fillRule="evenodd"
                  transform="translate(-.25 -.75)"
                  xlinkHref="#f"
                />
              </svg>
              <span>Delete</span>
            </RemoveProject>
          ) : (
            ""
          )}
          <RemoveProjectDeliverableModal
            this={this}
            show={this.state.modalDeleteDeliverableShow}
            onHide={() => this.setState({ modalDeleteDeliverableShow: false })}
          />
          <ul>
            {project.project_deliverables.length > 0 ? (
              project.project_deliverables[0].tasks.map((p, i) => (
                <React.Fragment key={i}>
                  <li key={i}>
                    {project.leaderId === auth.user.id
                      ? this.forLeader(p, i)
                      : this.forUser(p)}

                    {p.hasHash ? (<p className={p.isCompleted ? 'completedTask' : ''} dangerouslySetInnerHTML={{ __html: p.plainText }}></p>) : (<p className={p.isCompleted ? 'completedTask' : ''}>{p.task}</p>)}
                  </li>
                </React.Fragment>
              ))
            ) : (
              <div className="no-deliverable">No any project deliverables</div>
            )}
          </ul>
          {project.leaderId ? (
            project.leaderId !== auth.user.id ? (
              <div>
                <span className="project-lead-info">
                  Only <strong>{project.leaderName}</strong> can add/edit the
                  deliverables. Please ask the lead to add/edit here.
                </span>
              </div>
            ) : (
              ""
            )
          ) : (
            <div>
              <span className="project-lead-info">
                Project lead is missing for this project. Please ask your
                supervisor to add project lead here.
              </span>
            </div>
          )}

          {project.leaderId === auth.user.id && !profile ? (
            val.isEditable ? (
              <AddTaskWrap
                onClick={() => this.props.deliverableOpen(null, project)}
                className="add-deliverable"
              >
                <span className="glyphicon glyphicon-plus">
                  {" "}
                  Add/ Edit a deliverable
                </span>
              </AddTaskWrap>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </ProjectLevelTask>
      </TaskListWrap>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
  updateProjectDeliverable: (params, data) => {
    dispatch(ProjectAction.userDeliverableFetching);
    ProjectApi.createProjectDeliverable(params, data).then((response) => {
      if (response.success) {
        dispatch(ProjectAction.userDeliverableFetched());
        toastr.success("", response.message);
      } else {
        toastr.error("", response.error.message);
      }
    });
  },
  listUserProjects: (weekId) => {
    dispatch(ProjectAction.userProjectFetching);
    ProjectApi.listUserWeekProject({ weekId: weekId })
      .then((response) => {
        if (response.success) {
          dispatch(ProjectAction.userProjectFetched(response));
        } else {
          dispatch(ProjectAction.userProjectError(response));
        }
      })
      .catch((err) => {
        dispatch(ProjectAction.userProjectError(err));
      });
  },
  updateProjectTask: (params, data) => {
    dispatch(ProjectAction.userTaskFetching);
    ProjectApi.updateUserProjectDetail(params, data).then((response) => {
      if (response.success) {
        dispatch(ProjectAction.userTaskFetched());
        toastr.success("", response.message);
      } else {
        toastr.error("", response.error.message);
      }
    })
  },
  fetchUserLeadingProjects: (weekId) => {
    dispatch(ProjectAction.userLeadingProjectsFetching());
    ProjectApi.listUserLeadingProjects(weekId)
      .then(response => {
        if (response.success) {
          dispatch(ProjectAction.userLeadingProjectsFetched(response.data));
        } else {
          dispatch(ProjectAction.error('error'))
        }
      })
      .catch(err => {
        dispatch(ProjectAction.error(err.message))
      })
  },
  fetchUserTasks: (weekId) => {
    dispatch(ProjectAction.userTasksFetching());
    ProjectApi.listTasks(weekId)
      .then(response => {
        if (response.success) {
          dispatch(ProjectAction.userTasksFetched(response.data.tasks));
        } else {
          dispatch(ProjectAction.userTasksError('error'))
        }
      })
      .catch(err => {
        console.log(err)
        dispatch(ProjectAction.userTasksError(err.message))
      })
  }

});
export default connect(mapStateToProps, mapDispatchToProps)(TaskList);

const AddTaskWrap = styled.div`
  cursor: pointer;
  display: inline-block;
  margin: 10px 3px 0 -36px;
  .glyphicon-plus {
    font-size: 21px;
    font-family: "Open Sans";
    font-weight: 700;
    -webkit-transform: scale(0.6);
    -ms-transform: scale(0.6);
    transform: scale(0.6);
    top: 2px;
    /* &:before {
      margin-right: 4px;
    } */
  }
  &.add-deliverable .glyphicon-plus {
    text-decoration: underline;
  }
`;

const TaskListWrap = styled.div`
  display: flex;
  justify-content: center;
  > div {
    padding: 20px;
    position: relative;
    width: 40%;
    &:first-of-type {
      padding-left: 0;
      width: 60%;
    }
  }
  .membersImage {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    padding: 20px;
    object-fit: cover;
    object-position: center top;
  }
  h3 {
    font-size: 12px;
    font-weight: 700;
    color: rgba(51, 51, 51, 0.5);
    margin: 30px 0 16px;
    text-transform: uppercase;
    svg {
      margin-right: 7px;
      vertical-align: middle;
    }
    i {
      font-size: 12px;
      font-weight: 400;
      margin-left: 42px;
      text-transform: initial;
    }
  }
  ul {
    list-style: none;
    padding-left: 4px;
    padding-right: 16px;
  }
  .userTaskWeek {
    display: flex;
    justify-content: space-between;
    span {
      padding-right: 16px;
    }
    .userHour {
      text-transform: lowercase;
      color: #333;
      font-size: 14px;
      small {
        color: #333;
        font-size: 10px;
        font-weight: 700;
      }
    }
  }
`;

const YourTaskList = styled.div`
  > div {
    width: 65%;
  }
  ul {
    padding-left: 0;
     
    
    li {
      /* border-bottom: 1px solid #e9eaeb; */
      display: flex;
      justify-content: space-between;
      margin-bottom: 17px;
      /* padding-bottom: 10px; */
      p {
        margin: 0;
      }
      > div {
        display: flex;
        input {
          margin-right: 10px;
        }
      }
      strong {
        font-size: 10px;
        min-width: 30px;
        margin-left: 14px;
        text-align: right;
        span {
          font-size: 14px;
        }
      }
      .disable{
        display: none;
      }       

    }
    .completedTask{
      color: rgba(51,51,51,0.5);
      text-decoration: line-through;
    }
  }
`;

const ProjectLevelTask = styled.div`
  background-color: #e6e6e6;
  overflow: hidden;
  h3 {
    margin-top: 4px;
    margin-bottom: 20px;
  }
  ul li {
    display: flex;
    .disable{
      display: none;
    }
    p {
      border-bottom: 1px solid #cbcbcb;
      margin-bottom: 10px;
      padding-bottom: 10px;
      width: 100%;
    }
     input[type="checkbox"] {
       margin-right: 10px;
       }
      .completedTask{
        color: rgba(51,51,51,0.5);
        text-decoration: line-through;
      }    


    }
    .disableCheckBox {
      pointer-events: none;
    }
  }
  .add-task {
    color: #333;
    font-size: 12px;
    font-weight: bold;
    text-decoration: underline;
    margin-left: 40px;
    .glyphicon-plus {
      font-size: 8px;
      margin-right: 4px;
      top: 0;
    }
  }
  .project-lead-info {
    color: #333;
    opacity: 0.6;
    font-size: 11px;
  }
`;

const RemoveProject = styled.a`
  cursor: pointer;
  font-weight: 400!important;
  right: 16px;
  position: absolute;
  text-align: center;
  top: 20px;
  transition: opacity 0.2s ease-in-out;
  width: 34px;
  span {
    display: block;
    font-size: 8px;
    color: rgba(51, 51, 51, 0.7);
    margin-left: -3px;
    margin-top: -2px;
    text-transform: uppercase;
  }
  &:hover,
  &:focus {
    color: #fff;
    text-decoration: none;
  }
`;

const RemoveProjectModal = (props) => {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Project?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Row>
            <Col sm={12}>
              <p> Are you sure you want to delete all your tasks ? </p>
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="btn-wrap">
          <Button
            bsStyle="primary"
            onClick={(e) => {
              props.this.deleteUserProject(e, props.this.state.projectId);
            }}
          >
            Yes, delete it!
          </Button>
          <Button onClick={props.onHide}> Cancel </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const RemoveProjectDeliverableModal = (props) => {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Project Deliverable?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Row>
            <Col sm={12}>
              <p> Are you sure you want to delete all the deliverables of this project ? </p>
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="btn-wrap">
          <Button
            bsStyle="primary"
            onClick={(e) => {
              props.this.deleteUserProjectDeliverable(e, props.this.state.projectId);
            }}
          >
            Yes, delete it!
          </Button>
          <Button onClick={props.onHide}> Cancel </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
