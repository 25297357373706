import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import Select from 'react-select';

import * as LeaveAction from '../../actions/leave';
import LeaveService from '../../services/LeaveApi';
import * as HolidayAction from '../../actions/holidays';
import HolidayService from '../../services/HolidayApi';
import AnnualIcon from '../../images/ic-annual-leave.svg';
import SickIcon from '../../images/ic-sick-leave.svg';
import WorkingFromHomeIcon from '../../images/ic-work-home.svg';
import OfficialIcon from '../../images/ic-official-trip.svg';
import MenstrualIcon from '../../images/menstrualleave.svg';
import PendingIcon from '../../images/ic-pending.svg';
import ApprovedIcon from '../../images/ic-approved.svg';
import RejectedIcon from '../../images/ic-rejected.svg';
import Modal from '../../components/layout/ModalView';
import ScrollBar from 'react-scrollbar/dist/no-css';
import 'react-scrollbar/dist/css/scrollArea.css';

import {
  ANNUAL_LEAVE,
  SICK_LEAVE,
  WORKING_FROM_HOME_LEAVE,
  OFFICIAL_TRIP_LEAVE,
  MENSTRUAL_LEAVE,
  getYearArr
} from '../../helpers/constant/types';

const options = getYearArr();

class Stats extends Component {
  constructor(props) {
    super(props);
    const date = moment(new Date());
    this.state = {
      year: date.format('YYYY'),
      filter: 'all',
      leaveFilter: 'all',
      leaveError: false,
      userLeaves: [],
      showModal: false,
      holidays: [],
      totalSickLeave: 0,
      sickLeaveTaken: 0,
      menstrualLeaveTaken: 0,
      totalAnnualLeave: 0,
      annualLeaveTaken: 0,
      workingFromHomeTaken: 0,
      officialTripTaken: 0,

      selectedOption: options[0]
    };
  }

  componentDidMount() {
    const { user } = this.state.auth;
    let year = this.state.year, fiscalYear = '';
    if (new Date() > new Date(`${year}-6-30`)) {
      fiscalYear = `${year}/${parseInt(year) + 1} `;

    } else {
      fiscalYear = `${parseInt(year) - 1} /${year}`;
    }
    this.setState({ selectedOption: { value: fiscalYear, label: fiscalYear } })


    this.props.fetchUserLeaves(user.id, fiscalYear);
    this.props.fetchHolidayPlans();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let data = {};
    if (nextProps.auth !== prevState.auth) {
      data.auth = nextProps.auth;
    }
    if (nextProps.holidays !== prevState.holidays) {
      data.holidays = nextProps.holidays;
    }
    if (nextProps.userLeaves !== prevState.userLeaves) {
      data.userLeaves = nextProps.userLeaves;
    }

    if (
      Object.keys(nextProps.leaveTypes).length &&
      nextProps.leaveTypes !== prevState.leaveTypes
    ) {
      data.leaveTypes = nextProps.leaveTypes.leaveTypes;
    }

    return data;
  }

  handleFilterClick = filter => {
    this.setState({ filter });
  };

  handleLeaveFilterClick = leaveFilter => {
    this.setState({ leaveFilter });
  };

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    const { user } = this.state.auth;
    const year = selectedOption.value;
    this.props.fetchUserLeaves(user.id, year);
  };

  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleDelete = (id, type) => {
    LeaveService.deleteUserLeave(id, type)
      .then(response => {
        if (response.success) {
          toastr.success('', `${response.message}`);
          const { user } = this.state.auth;
          const year = this.state.selectedOption.value;
          this.props.fetchUserLeaves(user.id, year);
        } else {
          toastr.error('', response.error.message);
        }
        this.setState({ showModal: false });
      })
      .catch(err => toastr.error('', err.error.message));
  };

  render() {
    let {
      filter,
      auth,
      leaveFilter,
      selectedOption,
      userLeaves,
      leaveTypes,
      totalSickLeave,
      sickLeaveTaken,
      menstrualLeaveTaken,
      totalAnnualLeave,
      annualLeaveTaken,
      workingFromHomeTaken,
      officialTripTaken
    } = this.state;

    const showLeaves = [];
    let workHomeCount = 0, officialWorkCount = 0;

    if (leaveTypes) {
      leaveTypes.forEach(l => {
        totalSickLeave =
          l.leave_setting.leaveType === SICK_LEAVE
            ? l.noOfDays
            : totalSickLeave;
        totalAnnualLeave =
          l.leave_setting.leaveType === ANNUAL_LEAVE
            ? l.noOfDays + l.accumulated
            : totalAnnualLeave;
      });
    }

    if (userLeaves.userLeaves) {
      userLeaves.userLeaves.map(l => {
        let date = moment(l.from);
        let month = date.format('MMM').toLowerCase();
        let year = date.format('YYYY').toLowerCase();
        let day = date.format('D');

        sickLeaveTaken =
          l['user_leave_type.leave_setting.leaveType'] === SICK_LEAVE &&
            l.approvedStatus === 'approved'
            ? sickLeaveTaken + l.days
            : sickLeaveTaken;

        menstrualLeaveTaken =
            l['user_leave_type.leave_setting.leaveType'] === MENSTRUAL_LEAVE &&
            l.approvedStatus === 'approved'
                ? menstrualLeaveTaken + l.days
                : menstrualLeaveTaken;

        annualLeaveTaken =
          l['user_leave_type.leave_setting.leaveType'] === ANNUAL_LEAVE &&
            l.approvedStatus === 'approved'
            ? annualLeaveTaken + l.days
            : annualLeaveTaken;

        if (
          (l.approvedStatus === filter &&
            l['user_leave_type.leave_setting.leaveType'] === leaveFilter) ||
          (filter === 'all' && leaveFilter === 'all') ||
          (filter === 'all' &&
            l['user_leave_type.leave_setting.leaveType'] === leaveFilter) ||
          (leaveFilter === 'all' && l.approvedStatus === filter)
        ) {
          showLeaves.push({
            id: l.id,
            from: l.from,
            approver: `${l['Approver.firstName']} ${l['Approver.lastName']}`,
            month: month,
            year,
            day: parseInt(day),
            period: l.halfDayPeriod ? l.halfDayPeriod : 'full',
            type: l['user_leave_type.leave_setting.leaveType'],
            noOfDays: l.days,
            status: l.approvedStatus,
            appliedOn: l.createdAt,
            approvedOn: l.updatedAt
          });
        }
      });
    }

    if (userLeaves.userWorkingHome) {
      userLeaves.userWorkingHome.map(w => {
        if (w.approvedStatus === 'approved') {
          workHomeCount += 1;
        }
        let date = moment(w.from);
        let month = date.format('MMM').toLowerCase();
        let year = date.format('YYYY').toLowerCase();
        let day = date.format('D');

        if (
          (w.approvedStatus === filter &&
            leaveFilter === WORKING_FROM_HOME_LEAVE) ||
          (filter === 'all' && leaveFilter === 'all') ||
          (filter === 'all' && leaveFilter === WORKING_FROM_HOME_LEAVE) ||
          (leaveFilter === 'all' && w.approvedStatus === filter)
        ) {
          showLeaves.push({
            id: w.id,
            from: w.from,
            approver: `${w.Approver.firstName} ${w.Approver.lastName}`,
            month: month,
            year,
            day: parseInt(day),
            period: 'full',
            type: WORKING_FROM_HOME_LEAVE,
            noOfDays: w.days,
            status: w.approvedStatus,
            appliedOn: w.createdAt,
            approvedOn: w.updatedAt
          });
        }
      });
    }

    if (userLeaves.userOfficialTrips) {
      userLeaves.userOfficialTrips.map(t => {
        if (t.approvedStatus === 'approved') {
          officialWorkCount += 1;
        }
        let date = moment(t.from);
        let month = date.format('MMM').toLowerCase();
        let year = date.format('YYYY').toLowerCase();
        let day = date.format('D');

        if (
          (t.approvedStatus === filter &&
            leaveFilter === OFFICIAL_TRIP_LEAVE) ||
          (filter === 'all' && leaveFilter === 'all') ||
          (filter === 'all' && leaveFilter === OFFICIAL_TRIP_LEAVE) ||
          (leaveFilter === 'all' && t.approvedStatus === filter)
        ) {
          showLeaves.push({
            id: t.id,
            from: t.from,
            approver: `${t.Approver.firstName} ${t.Approver.lastName}`,
            month: month,
            year,
            day: parseInt(day),
            period: 'full',
            type: OFFICIAL_TRIP_LEAVE,
            noOfDays: t.days,
            status: t.approvedStatus,
            appliedOn: t.createdAt,
            approvedOn: t.updatedAt
          });
        }
      });
    }

    workingFromHomeTaken = workHomeCount;
    officialTripTaken = officialWorkCount;

    showLeaves.sort(function (a, b) {
      return new Date(b.from) - new Date(a.from);
    });

    return (
      <div>
        <Select
          className="select-leave"
          classNamePrefix="react-select"
          value={selectedOption}
          onChange={this.handleChange}
          options={options}
          placeholder="2019/2020"
        />
        <LeaveStats>
          <Modal
            show={this.state.showModal}
            onHide={() => this.setState({ showModal: false })}
            header="Cancel Leave Request?"
            delete={() =>
              this.handleDelete(
                this.state.selectedLeaveId,
                this.state.selectedLeaveType
              )
            }
          />
          <LeaveTypeBlock>
            <ul>
              <li>
                <button
                    type="button"
                    onClick={() => this.handleLeaveFilterClick('all')}
                >
                  <span
                      className={`filters ${leaveFilter === 'all' ? 'selected' : ''
                      }`}
                  >
                    All
                  </span>
                </button>
              </li>
              <li>
                <button
                    type="button"
                    onClick={() => this.handleLeaveFilterClick(ANNUAL_LEAVE)}
                >
                  <span
                      className={`filters ${leaveFilter === ANNUAL_LEAVE ? 'selected' : ''
                      }`}
                  >
                    Annual Leave ({annualLeaveTaken})
                  </span>
                </button>
              </li>
              <li>
                <button
                    type="button"
                    onClick={() => this.handleLeaveFilterClick(SICK_LEAVE)}
                >
                  <span
                      className={`filters ${leaveFilter === SICK_LEAVE ? 'selected' : ''
                      }`}
                  >
                    Sick Leave ({sickLeaveTaken})
                  </span>
                </button>
              </li>
              {auth.user.gender === 'f' && <li>
                <button
                    type="button"
                    onClick={() => this.handleLeaveFilterClick(MENSTRUAL_LEAVE)}
                >
                  <span
                      className={`filters ${leaveFilter === MENSTRUAL_LEAVE ? 'selected' : ''
                      }`}
                  >
                    MENSTRUAL Leave ({menstrualLeaveTaken})
                  </span>
                </button>
              </li>}
              <li>
                <button
                    type="button"
                    onClick={() =>
                        this.handleLeaveFilterClick(WORKING_FROM_HOME_LEAVE)
                    }
                >
                  <span
                      className={`filters ${leaveFilter === WORKING_FROM_HOME_LEAVE ? 'selected' : ''
                      }`}
                  >
                    Work From Home ({workingFromHomeTaken})
                  </span>
                </button>
              </li>
              <li>
                <button
                    type="button"
                    onClick={() =>
                        this.handleLeaveFilterClick(OFFICIAL_TRIP_LEAVE)
                    }
                >
                  <span
                      className={`filters ${leaveFilter === OFFICIAL_TRIP_LEAVE ? 'selected' : ''
                      }`}
                  >
                    Official Trip ({officialTripTaken})
                  </span>
                </button>
              </li>
            </ul>
          </LeaveTypeBlock>
          <LeaveDetailBlock>
            <div className="filters-block">
              <button
                  type="button"
                  onClick={() => this.handleFilterClick('all')}
              >
                <span
                    className={`filters ${filter === 'all' ? 'selected' : ''}`}
                >
                  All
                </span>
              </button>
              <button
                type="button"
                onClick={() => this.handleFilterClick('pending')}
              >
                <span
                  className={`filters ${filter === 'pending' ? 'selected' : ''
                    }`}
                >
                  Pending
                </span>
              </button>
              <button
                type="button"
                onClick={() => this.handleFilterClick('approved')}
              >
                <span
                  className={`filters ${filter === 'approved' ? 'selected' : ''
                    }`}
                >
                  Approved
                </span>
              </button>
              <button
                type="button"
                onClick={() => this.handleFilterClick('rejected')}
              >
                <span
                  className={`filters ${filter === 'rejected' ? 'selected' : ''
                    }`}
                >
                  Rejected
                </span>
              </button>
            </div>
            <div className="leaves-block">
              <ScrollBar
                className="options"
                contentClassName="content"
                smoothScrolling={true}
                minScrollSize={30}
              >
                <div className="leave-list">
                  {showLeaves.length > 0 ? (
                    showLeaves.map((l, i) => (
                      <div key={i} className="leave-stat">
                        <div className="leave-icon-container">
                          <img
                            src={
                              l.type === SICK_LEAVE
                                ? SickIcon
                                : l.type === WORKING_FROM_HOME_LEAVE
                                  ? WorkingFromHomeIcon
                                  : l.type === OFFICIAL_TRIP_LEAVE
                                    ? OfficialIcon
                                    : l.type === MENSTRUAL_LEAVE
                                      ? MenstrualIcon
                                      : AnnualIcon
                            }
                            alt="sick-leave"
                          />
                        </div>
                        <div className="progress-block">
                          <span className="leave-label">
                            {`${this.capitalizeFirstLetter(l.month)} ${l.day
                              } - ${l.year} (${l.noOfDays} day)`}
                            <img
                              src={
                                l.status === 'approved'
                                  ? ApprovedIcon
                                  : l.status === 'rejected'
                                    ? RejectedIcon
                                    : PendingIcon
                              }
                              alt="status"
                            />
                          </span>
                          <span className="days-small">
                            {this.capitalizeFirstLetter(l.type) + ', '}
                            {l.status === 'approved' ? (
                              <p>
                                Approved on{' '}
                                <strong>
                                  {moment(l.approvedOn).format(
                                    'MMM' + ' DD, YYYY'
                                  )}
                                </strong>{' '}
                                by <strong>{l.approver}</strong>
                              </p>
                            ) : l.status === 'pending' ? (
                              'Not approved yet'
                            ) : (
                              <p>
                                Rejected on{' '}
                                <strong>
                                  {moment(l.approvedOn).format('MMM DD, YYYY')}
                                </strong>{' '}
                                by <strong>{l.approver}</strong>{' '}
                              </p>
                            )}
                          </span>
                        </div>
                        {l.status === 'pending' && (
                          <button
                            type="button"
                            onClick={() =>
                              this.setState({
                                showModal: true,
                                selectedLeaveId: l.id,
                                selectedLeaveType: l.type
                              })
                            }
                            className="cancel-leave-request"
                          >
                            {/* <img src={RemoveIcon} alt="remove" /> */}
                            Cancel leave Request
                          </button>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="leave-stat">No Leaves</div>
                  )}
                </div>
              </ScrollBar>
            </div>
          </LeaveDetailBlock>
        </LeaveStats>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  userLeaves: state.leave.userLeaves,
  holidays: state.holiday.holidays,
  leaveTypes: state.leave.leaveTypes
});

const mapDispatchToProps = dispatch => ({
  fetchUserLeaves: (userId, year) => {
    dispatch(LeaveAction.userLeaveFetching);
    LeaveService.fetchUserLeaves(userId, year)
      .then(response => {
        if (response.success) {
          dispatch(LeaveAction.userLeaveFetched(response));
        } else {
          dispatch(LeaveAction.userLeaveError('error'));
        }
      })
      .catch(err => dispatch(LeaveAction.userLeaveError(err.message)));
  },
  fetchHolidayPlans: () => {
    dispatch(HolidayAction.fetching());
    HolidayService.holidayPlans()
      .then(response => {
        if (response.success) {
          dispatch(HolidayAction.fetched(response));
        } else {
          dispatch(HolidayAction.error('error'));
        }
      })
      .catch(err => {
        dispatch(HolidayAction.error(err.message));
      });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Stats);

const LeaveStats = styled.div`
  display: flex;
  margin: 50px auto 0;
  position: relative;
  width: 924px;
  h1 {
    color: #fff;
    display: flex;
    font-size: 72px;
    font-weight: 300;
    margin-bottom: 40px;
  }
  .stats-block {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    .leave-days-block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
  .leave-stat {
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    display: flex;
    align-items: center;
    margin-right: 40px;
    padding: 0 6px;
    .leave-icon-container {
      width: 44px;
      height: 44px;
      border-radius: 4px;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      img {
        width: 24px;
      }
    }
  }
  .progress-block {
    .leave-stat-labels {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      .leave-label {
        font-size: 10px;
        color: #fff;
        text-transform: uppercase;
      }
      .days {
        color: #fff;
        font-size: 40px;
        font-weight: bold;
        line-height: 22px;
        .days-small {
          font-size: 9px;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
  .leaves-block {
    display: flex;
    justify-content: space-between;
    .leave-list {
      width: 100%;
      .leave-stat {
        position: relative;
        width: 100%;
        margin: 0;
        margin-top: 20px;
        justify-content: flex-start;
        & + .leave-stat {
          margin-top: 30px;
        }
        .btn-remove {
          background: transparent;
          border: none;
          font-size: 10px;
          position: absolute;
          right: -112px;
          top: 1px;
          img {
            transform: scale(0.7);
          }
        }
      }
      .progress-block {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        .leave-label {
          color: #fff;
          font-weight: 700;
          img {
            margin-left: 6px;
            margin-top: -1px;
          }
        }
        .days-small {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.5);
          margin-top: 3px;
            p {
              display: inline;
            }
        }
      }
    }
    .calendar-block {
      width: 40%;
    }
  }
  .filters-block {
    align-items: center;
    display: flex;
    margin: 0 0 20px;
    .select-leave {
      width: 150px;
      display: inline-block;
      border-radius: 2px;
      border: solid 1px #51804d;
      font-size: 12px;
      height: 34px;
      margin-right: 20px;
      div {
        background: transparent;
        border: none;
        min-height: initial;
      }
      .react-select {
        &__placeholder {
          color: #fff;
          font-size: 12px;
          font-weight: 300;
        }
        &__single-value {
          color: #ffffff;
          text-transform: uppercase;
        }
        &__indicator-separator {
          display: none;
        }
        &__menu {
          background: rgba(0, 0, 0, 0.9);
        }
        &__menu-list {
          border: 1px solid #51804d;
          border-radius: 4px;
          background: rgba(0, 0, 0, 0.9);
        }
        &__option {
          color: #ffffff;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 300;
        }
      }
    }
    .react-select__dropdown-indicator {
      padding-top: 7px;
    }
  }

  label {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    &.leave-days {
      margin: 0;
      font-size: 72px;
      font-weight: 700;
      display: block;
      line-height: 68px;
    }

    &.leave-days-label {
      color: #ffffff;
      display: block;
      font-size: 16px;
      font-size: 18px;
      font-weight: 300;
      opacity: 0.5;
    }
  }
  button {
    background: transparent;
    border: none;

    .filters {
      margin-right: 20px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.5);
      text-transform: uppercase;
      &:hover {
        color: #fff;
      }
      &.selected {
        color: #fff;
        font-weight: 700;
      }
    }
  }
`;

const LeaveTypeBlock = styled.div`
  margin-right: 56px;
  width: 234px;
  ul {
    list-style: none;
    margin-top: 1px;
    padding: 0;
    li {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      margin-bottom: 12px;
      padding-bottom: 12px;
      button {
        color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }
`;

const LeaveDetailBlock = styled.div`
  .cancel-leave-request {
    border: 1px solid #ff5555;
    border-radius: 12px;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    height: 24px;
    margin-left: 14px;
    text-transform: uppercase;
    width: 168px;
  }
  .scrollarea {
    height: 430px;
    width: 700px;
  }
`;
