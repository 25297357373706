import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as UserAction from "../../actions/user";
import { Link } from "react-router-dom";

import UserService from "../../services/UserApi";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProjects: 1,
      key: 1,
      isActive: true
    };
  }

  componentDidMount() {
    // this.props.fetchSupervisorTeam(this.state.selectedWeek.id);
  }

  componentDidUpdate(nextProps, prevState) {
    if (this.state.selectedWeek !== prevState.selectedWeek) {
      let weekId = this.state.selectedWeek ? this.state.selectedWeek.id : prevState.selectedWeek.id;
      this.props.fetchSupervisorTeam(weekId);
    }
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    let data = {};
    if (nextProps.user !== prevState.user) {
      data.userDetail = nextProps.user.userDetail;
    }
    if (
      Object.keys(nextProps.weekData).length &&
      nextProps.weekData !== prevState.weekData
    ) {
      data.weekList = nextProps.weekData.weeks;
      data.selectedWeek = nextProps.weekData.weeks.find(
        week => week.isCurrentWeek
      );
    }
    return data;
  }

  render() {
    const {teams, teamDetail} = this.props;
    return (
      <InnerTemplate>
        <h2>Your team's estimations</h2>
        {teams && teams.length ? (
          <table>
            <UserDetailHeader
              data={teams}
            />
            <UserDetailBody
              data={teams}
              rows={teamDetail}
            />
          </table>
        ) : (
          <span>
								{teams
                  ? 'No Team Member Assigned.'
                  : 'Loading...'}
					</span>
        )}
      </InnerTemplate>
    );
  }
}

const UserDetailHeader = props => {
  if (props.data) {
    const header = props.data.map((h, i) => {
      return <th key={i}>{
        <Link to={`/profile/${h.id}`}>
          <img src={h.profilePic} alt="" style={{maxWidth: "50%"}}
              title={h.firstName}/>
        </Link>
      }
      </th>;
    });

    const topHeader = props.data.map((h, i) => {
      return <th key={i}>{h.total}</th>
    });

    return (
      <thead>
      <tr>
        <th></th>
        {header}
      </tr>
      <tr>
        <th className="week-label">Total Hours this week →</th>
        {topHeader}
      </tr>
      </thead>
    );
  }
  return (
    <thead>
    <tr></tr>
    </thead>
  );
};

const UserDetailBody = props => {
  if (props.rows) {
    const rows = props.rows.map((row, index) => {
      return (
        <tr key={index}>
          <td>
            {row.title}
          </td>
          {props.data.map((t, i) => {
            return row.detail.map((r, i) => {
              if (t.id === r.user.id) {
                return <td key={i}>{r.hour}</td>;
              }
              return null;
            });
          })}
        </tr>
      );
    });
    return <tbody>
    {rows}</tbody>;
  }
  return null;
};

const mapStateToProps = state => ({
  auth: state.auth,
  teams: state.user.teams,
  teamDetail: state.user.teamDetail,
  weekData: state.week.weekData
});

const mapDispatchToProps = dispatch => ({
  fetchSupervisorTeam: weekId => {
    dispatch(UserAction.teamfetching);
    UserService.fetchSupervisorTeam({weekId: weekId})
      .then(response => {
        if (response.success) {
          dispatch(UserAction.teamfetched(response));
        }
        dispatch(UserAction.error(response));
      })
      .catch(err => dispatch(UserAction.error(err)));
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);


const InnerTemplate = styled.div`
  
`;

