export function fetching() {
	return { type: 'WEEK_DATA_FETCHING' };
}

export function fetched(payload) {
	return { type: 'WEEK_DATA_FETCHED', payload };
}

export function error(payload) {
	return { type: 'WEEK_DATA_ERROR', payload };
}
