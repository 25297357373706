import Api from './api';
import ApiUrlConstant from '../helpers/constant/api-url';

class LeaveApi {
	async getLeaveTypes(userId, supervisorId) {
		try {
			let leaveTypes = await Api.get(
				await ApiUrlConstant.USER_LEAVE_SETTING({
					userId: userId,
					supervisorId: supervisorId
				})
			);
			return leaveTypes.body;
		} catch (error) {
			return error;
		}
	}

	async getLeavesToday() {
		try {
			let leaveTypes = await Api.get(
				await ApiUrlConstant.USER_LEAVES_TODAY()
			);
			return leaveTypes.body;
		} catch (error) {
			return error;
		}
	}

	async submitLeave(formData) {
		try {
			let leaveTypes = await Api.post(
				await ApiUrlConstant.USER_LEAVE_APPLICATION(),
				formData
			);
			return leaveTypes.body;
		} catch (error) {
			return error;
		}
	}
	async fetchUserLeaves(userId, year=null) {
		try {
			let userLeaves = await Api.get(
				await ApiUrlConstant.USER_LEAVES({ userId, year})
			);
      return userLeaves.body;
		} catch (error) {
			return error;
		}
	}

	async deleteUserLeave(leaveId, type) {
		try {
			let userLeaves = await Api.destroy(
				await ApiUrlConstant.USER_LEAVE_DELETE({ leaveId, type })
			);
			return userLeaves.body;
		} catch (error) {
			return error;
		}
	}
}

export default new LeaveApi();
