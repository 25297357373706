/* eslint-disable no-loop-func */
import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import LeaveTag from './LeaveTag';
import AnnualIcon from '../../images/ic-annual-leave.svg';
import SickIcon from '../../images/ic-sick-leave.svg';
import MenstrualIcon from '../../images/menstrualleave.svg';
import MatPatIcon from '../../images/maternity-paternity.svg';
import WorkFromHomeIcon from '../../images/ic-work-home.svg';
import OfficialTripIcon from '../../images/ic-official-trip.svg';
import OfficialEventIcon from '../../images/podium.svg';
import ScrollBar from 'react-scrollbar/dist/no-css';
import 'react-scrollbar/dist/css/scrollArea.css';

class Calendar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			today: new Date(),
			currentMonth: new Date().getMonth(),
			currentYear: new Date().getFullYear(),
			months: [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec'
			],
			monthAndYear: document.getElementById('monthAndYear')
		};
	}

	componentDidMount() {
		const { currentMonth, currentYear } = this.state;
		this.setState(
			{
				monthAndYear: document.getElementById('monthAndYear')
			},
			() => {
				this.showCalendar(currentMonth, currentYear);
			}
		);
		this.setState({ type: this.props.type });
	}

	componentDidUpdate(prevProps, prevState) {
		const { currentMonth, currentYear } = this.state;
		if (prevProps.leaveList !== this.props.leaveList) {
			this.showCalendar(currentMonth, currentYear);
		}

		if (prevProps.userLeaves !== this.props.userLeaves) {
			this.showCalendar(currentMonth, currentYear);
		}

		if (prevProps.holidays !== this.props.holidays) {
			this.showCalendar(currentMonth, currentYear);
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let data = {};
		if (nextProps.clickable !== prevState.clickable) {
			data.clickable = nextProps.clickable;
		}
		if (nextProps.leaveList !== prevState.leaveList) {
			data.leaveList = nextProps.leaveList;
		}

		if (nextProps.userLeaves && nextProps.userLeaves !== prevState.userLeaves) {
			data.userLeaves = nextProps.userLeaves.userLeaves;
			data.userWorkingHome = nextProps.userLeaves.userWorkingHome;
			data.userOfficialTrips = nextProps.userLeaves.userOfficialTrips;
		}

		if (
			nextProps.holidays.length &&
			nextProps.holidays !== prevState.holidays
		) {
			data.holidays = nextProps.holidays;
		}

		return data;
	}

	next = () => {
		const { currentMonth, currentYear } = this.state;
		let year = currentMonth === 11 ? currentYear + 1 : currentYear;
		let month = (currentMonth + 1) % 12;
		this.setState({ currentMonth: month, currentYear: year });
		this.showCalendar(month, year);
	};

	previous = () => {
		const { currentMonth, currentYear } = this.state;
		let year = currentMonth === 0 ? currentYear - 1 : currentYear;
		let month = currentMonth === 0 ? 11 : currentMonth - 1;
		this.setState({ currentMonth: month, currentYear: year });
		this.showCalendar(month, year);
	};

	showCalendar = (month, year) => {
		const {
			today,
			months,
			monthAndYear,
			clickable,
			leaveList,
			userLeaves,
			userWorkingHome,
			userOfficialTrips,
			holidays,
			type
		} = this.state;
		let firstDay = new Date(year, month).getDay();
		let daysInMonth = 32 - new Date(year, month, 32).getDate();

		let tbl = document.getElementById('calendar-body'); // body of the calendar

		// clearing all previous cells
		tbl.innerHTML = '';

		// filing data about month and in the page via DOM.
		monthAndYear.innerHTML = months[month] + ' ' + year;
		// selectYear.value = year;
		// selectMonth.value = month;

		// creating all cells

		let date = 1;
		for (let i = 0; i < 6; i++) {
			// creates a table row
			let row = document.createElement('tr');

			//creating individual cells, filing them up with data.
			for (let j = 0; j < 7; j++) {
				if (i === 0 && j < firstDay) {
					let cell = document.createElement('td');
					let cellTextSpan = document.createElement('span');
					let cellText = document.createTextNode('');
					cellTextSpan.appendChild(cellText);
					cell.appendChild(cellTextSpan);
					row.appendChild(cell);
				} else if (date > daysInMonth) {
					break;
				} else {
					let cell = document.createElement('td');
					let cellTextSpan = document.createElement('span');
					let cellText = document.createTextNode(date);
					if (
						date === today.getDate() &&
						year === today.getFullYear() &&
						month === today.getMonth()
					) {
						cell.classList.add('bg-today');
					} else if (
						moment(`${year}-${month + 1}-${date}`).format('YYYY-MM-DD') >=
						moment(today).format('YYYY-MM-DD')
					) {
						cell.classList.add('bg-coming-days');
					}

					let d = {};
					if (leaveList) {
						d = leaveList.find(l => {
							if (
								l.month.toLowerCase() === months[month].toLowerCase() &&
								l.day === date &&
								+year === +l.year
							) {
								return l.day;
							}
						});
					}

					let h = {};
					if (holidays) {
						h = holidays.find(h => {
							let dt = moment(h.startDate);
							let mnth = dt.format('MMM').toLowerCase();
							let yr = dt.format('YYYY');
							let day = parseInt(dt.format('D'));
							let isWomensOnly = h.womenOnly;
							if (isWomensOnly) {
								if (
									this.props.user.gender === 'f' &&
									mnth === months[month].toLowerCase() &&
									day === date &&
									+year === +yr
								) {
									return { day: day };
								}

							} else {
								if (
									mnth === months[month].toLowerCase() &&
									day === date &&
									+year === +yr
								) {
									return { day: day };
								}
							}
						});
					}

					let u = {};
					if (userLeaves) {
						u = userLeaves
							.sort((a,b) => new moment.utc(b.createdAt) - new moment.utc(a.createdAt))
								.find(l => {
									let dt = moment(l.from);
									let yr = dt.format('YYYY');
									let mnth = dt.format('MMM').toLowerCase();
									let day = parseInt(dt.format('D'));
									if (
										+yr === +year &&
										mnth === months[month].toLowerCase() &&
										day === date
									) {
										return { day: day };
									}
								});
					}

					let w = {};
					if (userWorkingHome) {
						w = userWorkingHome.sort((a, b) => b.id - a.id).find(l => {
							let dt = moment(l.from);
							let yr = dt.format('YYYY');
							let mnth = dt.format('MMM').toLowerCase();
							let day = parseInt(dt.format('D'));
							if (
								+yr === +year &&
								mnth === months[month].toLowerCase() &&
								day === date
							) {
								return { day: day };
							}
						});
					}

					let t = {};
					if (userOfficialTrips) {
						t = userOfficialTrips.find(l => {
							let dt = moment(l.from);
							let yr = dt.format('YYYY');
							let mnth = dt.format('MMM').toLowerCase();
							let day = parseInt(dt.format('D'));
							if (
								+yr === +year &&
								mnth === months[month].toLowerCase() &&
								day === date
							) {
								return { day: day };
							}
						});
					}

					if (type === 'leave' && (j === 0 || j === 6)) {
						cellTextSpan.classList.add('bg-weekend');
					} else if (type === 'home' && (j === 0 || j === 6)) {
						cellTextSpan.classList.add('bg-weekend');
					} else {
						cellTextSpan.classList.add('bg-weekdays');
						const handleOnClickListner = () => {
							this.props.handleOnClick(months[month], cellText, cellTextSpan, year);
            };

						if (clickable) {
							cell.addEventListener('click', handleOnClickListner);
						}

						if (d && d.day === date) {
							cellTextSpan.classList.add(
								d.period === 'full'
									? 'bg-leave'
									: d.period === 'firstHalf'
									? 'bg-half-leave'
									: 'bg-half-leave-second'
							);
						}

						if (
							u &&
							Object.keys(u).length &&
							u.approvedStatus !== "rejected"
						) {
							let type = u['user_leave_type.leave_setting.leaveType']
								.split(' ')
								.join('-');

							cellTextSpan.classList.add(
								'bg-leave-taken-' + type.toLowerCase()
							);
							cell.removeEventListener('click', handleOnClickListner);
						}

						if (h && Object.keys(h).length) {
							cellTextSpan.classList.add('bg-holiday');
							cellTextSpan.innerHTML = `<small>${h.holiday.displayTitle}</small>`;
							cellTextSpan.setAttribute('title', h.holiday.displayTitle);
							cell.removeEventListener('click', handleOnClickListner);
						}

						if (
							w &&
							Object.keys(w).length &&
							w.approvedStatus !== "rejected"
						) {
							cellTextSpan.classList.add('bg-work-from-home');
							cellTextSpan.setAttribute('title', 'Working from home');
							cell.removeEventListener('click', handleOnClickListner);
						}

						if (t && Object.keys(t).length) {
							cellTextSpan.classList.add(`bg-${t.type}`);
							cellTextSpan.setAttribute('title', 'Official trip');
							cell.removeEventListener('click', handleOnClickListner);
						}

						// if (type === 'home') {
						// 	const toDayDate = new Date();
						// 	const toDay = toDayDate.getDate();
						// 	if (date < toDay) {
						// 		cellTextSpan.classList.add('bg-weekend');
						// 		cell.removeEventListener('click', handleOnClickListner);
						// 	}
						// }
					}
					cellTextSpan.appendChild(cellText);
					cell.appendChild(cellTextSpan);
					row.appendChild(cell);
					date++;
				}
			}

			tbl.appendChild(row); // appending each row into calendar body.
		}
	};

	render() {
		const { leaveError, leaveList, leaveTags, type } = this.props;
		const { clickable } = this.state;
		return (
			<CalendarWrapper clickable={clickable}>
				<div className="container">
					<div className={`card ${leaveError ? 'error' : ''}`}>
						<div className="form-inline">
							<button
								className="btn col-sm-1"
								type="button"
								id="previous"
								onClick={this.previous}
							>
								PREV
							</button>
							<label className="col-sm-6" id="monthAndYear" />
							<button
								className="btn col-sm-1"
								type="button"
								id="next"
								onClick={this.next}
							>
								NEXT
							</button>
						</div>
						<table className="table table-responsive-sm" id="calendar">
							<thead>
								<tr>
									<th>S</th>
									<th>M</th>
									<th>T</th>
									<th>W</th>
									<th>T</th>
									<th>F</th>
									<th>S</th>
								</tr>
							</thead>

							<tbody id="calendar-body" />
						</table>
					</div>
				</div>
				{leaveTags && (
					<ScrollBar
						className="options"
						contentClassName="content"
						smoothScrolling={true}
						minScrollSize={30}
					>
						<div className="container leave-tag-list">
							{leaveList.map(l => (
								<LeaveTag
									key={l.month + l.day}
									handleDayPeriod={this.props.handleDayPeriod}
									month={l.month}
									day={l.day}
									year={l.year}
									type={type}
								/>
							))}
						</div>
					</ScrollBar>
				)}
			</CalendarWrapper>
		);
	}
}

export default Calendar;
const CalendarWrapper = styled.div`
	display: flex;
	/* justify-content: space-between; */
	margin-bottom: 55px;
	.card {
		margin-right: 10px;
		width: 346px;
		/* height: 260px; */
		border-radius: 4px;
		border: solid 1px rgba(151, 151, 151, 0.5);
		font-size: 10px;
		color: rgba(255, 255, 255, 0.5);
		&.error {
			border: solid 1px red;
		}
		.form-inline {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: rgba(28, 32, 41, 0.5);
			padding: 10px 20px 0;
			width: 100%;

			.btn {
				font-size: 8px;
				height: 20px;
				line-height: 20px;
				padding: 0;
				background: transparent;
				&:hover {
					color: #fff;
				}
			}
			label {
				text-align: center;
				margin: 0;
			}
		}
	}
	#monthAndYear {
		font-size: 10px;
		font-weight: 700;
	}
	.leave-tag-list {
		> div {
			background: rgba(216, 216, 216, 0.1);
			border-radius: 2px;
		}
	}
	.container {
		margin: 0;
		padding: 0;
		width: auto;
		.card-header {
			text-align: center;
		}

		table {
			margin: 0;
			thead {
				tr {
					th {
						background: rgba(28, 32, 41, 0.5);
						border: none;
						color: rgba(255, 255, 255, 0.5);
						font-size: 10px;
						font-weight: 400;
						text-align: center;
						line-height: 30px;
						height: 16px;
						padding-top: 4px;
						padding-bottom: 4px;
						width: calc(100% / 7) !important;
					}
				}
			}
			tbody {
				tr {
					td {
						border: none;
						color: rgba(255, 255, 255, 0.1);
						font-size: 12px;
						font-weight: 700;
						text-align: center;
						line-height: 32px;
						padding: 2px;
						&.bg-today {
							color: #fff;
							font-size: 12px;
							font-weight: 700;
							.bg-weekdays {
								&:after {
									color: rgba(255, 255, 255, 0.5);
									content: 'Today';
									display: block;
									font-size: 6px;
									font-weight: 700;
									line-height: 0;
									margin-top: -6px;
									text-transform: uppercase;
								}
							}
						}
						&.bg-coming-days {
							color: #fff;
							font-size: 12px;
							font-weight: 700;
						}
						span {
							height: 32px;
							width: 32px;
							display: block;
							margin: 0 auto;
							&.bg-weekend {
								color: rgba(255, 255, 255, 0.1);
								/* border-radius: 50%;
							background-image: linear-gradient(262deg, #24e018, #1e6d19); */
								cursor: default;
							}
							&.bg-weekdays {
								cursor: ${props => (props.clickable ? 'pointer' : 'default')};
							}
							&.bg-leave,
							&.bg-half-leave,
							&.bg-half-leave-second {
								color: #fff;
								font-size: 12px;
								font-weight: 700;
								border-radius: 50%;
								cursor: default;
							}
							&.bg-leave {
								background-image: linear-gradient(224deg, #ff6200, #db2b2b);
							}
							&.bg-half-leave {
								background-image: linear-gradient(
									-45deg,
									#464a53 50%,
									#e84021 50%
								);
							}
							&.bg-half-leave-second {
								background-image: linear-gradient(
									135deg,
									#464a53 50%,
									#e84021 50%
								);
							}
							&.bg-leave-taken-annual-leave {
								text-indent: -9999px;
								background: url(${AnnualIcon}) no-repeat 5px;
								cursor: default;
							}
							&.bg-leave-taken-sick-leave {
								text-indent: -9999px;
								background: url(${SickIcon}) no-repeat 5px;
								cursor: default;
							}
							&.bg-leave-taken-menstrual-leave {
								text-indent: -9999px;
								background: url(${MenstrualIcon}) no-repeat 5px;
								cursor: default;
							}
							&.bg-leave-taken-paternity-leave {
								text-indent: -9999px;
								background: url(${MatPatIcon}) no-repeat 5px;
								cursor: default;
							}
							&.bg-leave-taken-maternity-leave {
								text-indent: -9999px;
								background: url(${MatPatIcon}) no-repeat 5px;
								cursor: default;
							}
							&.bg-holiday {
								color: rgba(255, 255, 255, 0.5);
								cursor: default;
								position: relative;
								text-align: center;
								small {
									bottom: 0;
									display: block;
									font-size: 6px;
									line-height: normal;
									overflow: hidden;
									position: absolute;
									text-overflow: ellipsis;
									text-transform: uppercase;
									width: 100%;
									white-space: nowrap;
								}
							}
							&.bg-work-from-home,
							&.bg-officialTrip,
							&.bg-officialEvent {
								color: rgba(255, 255, 255, 0.5);
								position: relative;
								&:before {
									content: '';
									height: 14px;
									position: absolute;
									width: 14px;
									right: -4px;
									top: 0;
								}
							}
							&.bg-work-from-home:before {
								background: url(${WorkFromHomeIcon}) no-repeat center center/84%
									74%;
							}
							&.bg-officialTrip:before {
								background: url(${OfficialTripIcon}) no-repeat center center/78%
									78%;
							}
							&.bg-officialEvent:before {
								background: url(${OfficialEventIcon}) no-repeat center
									center/78% 78%;
							}
						}
					}
				}
			}
		}
	}
`;
