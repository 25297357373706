import Api from './api';
import ApiUrlConstant from '../helpers/constant/api-url';

class ProjectApi {
	async listProjects() {
		try {
			let projects = await Api.get(await ApiUrlConstant.LIST_PROJECTS());

			return projects.body;
		} catch (error) {
			return error;
		}
	}

	async listUserLeadingProjects(weekId) {
		try {
			let projects = await Api.get(await ApiUrlConstant.LIST_USER_LEADING_PROJECTS(weekId));

			return projects.body;
		} catch (error) {
			return error;
		}
	}

	async createProject(data, userId) {
		try {
			let res = await Api.post(
				await ApiUrlConstant.CREATE_USER_PROJECT(userId),
				data
			);
			return res.body;
		} catch (error) {
			return error;
		}
	}

	async getProjectDetails(id) {
		try {
			let p = await Api.get(await ApiUrlConstant.USER_PROJECT_DETAIL(id));
			return p.body;
		} catch (error) {
			return error;
		}
	}

	async listUserWeekProject(params) {
		try {
			let p = await Api.get(await ApiUrlConstant.USER_WEEKLY_PROJECT(params));
			return p.body;
		} catch (error) {
			return error;
		}
	}

	async listUserCurrentProject(params) {
		try {
			let p = await Api.get(await ApiUrlConstant.USER_CURRENT_PROJECT(params));
			return p.body;
		} catch (error) {
			return error;
		}
	}

  async listUserProjectList(params) {
    try {
      let p = await Api.get(await ApiUrlConstant.USER_PROJECT_TASKS(params));
      return p.body;
    } catch (error) {
      return error;
    }
  }

  async listProjectDeliverable(params) {
    try {
      let p = await Api.get(await ApiUrlConstant.PROJECT_DELIVERABLE(params));
      return p.body;
    } catch (error) {
      return error;
    }
  }

	async deleteUserProject(params) {
		try {
			let p = await Api.destroy(await ApiUrlConstant.USER_PROJECT(params));
			return p.body;
		} catch (error) {
			return error;
		}
	}

	async updateUserProject(params, data) {
		try {
			let p = await Api.patch(await ApiUrlConstant.USER_PROJECT(params), data);
			return p.body;
		} catch (error) {
			return error;
		}
	}

  async updateUserProjectDetail(params, data) {
    try {
      let p = await Api.patch(await ApiUrlConstant.USER_PROJECT_DETAIL(params.id), data);
      return p.body;
    } catch (error) {
      return error;
    }
  }

	async listWeekProjectDetail(params) {
		try {
			let p = await Api.get(await ApiUrlConstant.LIST_WEEK_PROJECT_DETAIL(params));
			return p.body;
		} catch (error) {
			return error;
		}
	}

	async createProjectDeliverable(params, data) {
		try {
			let res = await Api.post(
				await ApiUrlConstant.PROJECT_DELIVERABLE(params),
				data
			);
			return res.body;
		} catch (error) {
			return error;
		}
	}

	async deleteProjectDeliveable(params) {
		try {
			let p = await Api.destroy(await ApiUrlConstant.PROJECT_DELIVERABLE(params));
			return p.body;
		} catch (error) {
			return error;
		}
	}

	async listTasks(params) {
		try {
			let projects = await Api.get(await ApiUrlConstant.USER_PROJECT_TASKS(params));

			return projects.body;
		} catch (error) {
			return error;
		}
	}
}

export default new ProjectApi();
