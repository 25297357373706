import React, { useState } from 'react';
import styled from 'styled-components';

const LeaveTag = ({month, day, handleDayPeriod, type, year}) => {
  const [leavePeriod, setLeavePeriod] = useState('full');
  return (
    <LeaveTagWrapper>
      <button type="button">{month + ' ' + day}</button>
      <button
        type="button"
        onClick={() => {
          handleDayPeriod(month, day, 'full',year);
          setLeavePeriod('full');
        }}
        className={leavePeriod === 'full' ? 'selected-leave-type' : ''}
      >
        Full
      </button>
      <FirstHalfButton type={type} handleDayPeriod={handleDayPeriod} month={month} day={day} year={year}
                       leavePeriod={leavePeriod} setLeavePeriod={setLeavePeriod}/>
      <SecondHalfButton type={type} handleDayPeriod={handleDayPeriod} month={month} day={day} year={year}
                        leavePeriod={leavePeriod} setLeavePeriod={setLeavePeriod}/>

    </LeaveTagWrapper>
  );
};

const FirstHalfButton = props => {
  if (props.type && props.type === 'leave') {
    return (
      <button
        type="button"
        onClick={() => {
          props.handleDayPeriod(props.month, props.day, 'firstHalf', props.year);
          props.setLeavePeriod('firstHalf');
        }}
        className={props.leavePeriod === 'firstHalf' ? 'selected-leave-type' : ''}
      >
        1st half
      </button>
    )
  }
  return null

}

const SecondHalfButton = props => {
  if (props.type && props.type === 'leave') {
    return (<button
      type="button"
      onClick={() => {
        props.handleDayPeriod(props.month, props.day, 'secondHalf', props.year);
        props.setLeavePeriod('secondHalf');
      }}
      className={props.leavePeriod === 'secondHalf' ? 'selected-leave-type' : ''}
    >
      2nd half
    </button>)
  }
  return null

}

export default LeaveTag;

const LeaveTagWrapper = styled.div`
	margin-top: 10px;
	height: 33px;
	width: fit-content;
	button {
		height: 33px;
		outline: none;
		border: 1px solid transparent;
		background: none;
		color: rgba(255,255,255,0.5);
		font-size: 12px;
		padding-left: 9px;
		padding-right: 9px;
		&:first-child {
			background-color: #1f232d;
			color: #fff;
			cursor: default;
			font-weight: 700;
			width: 66px;
		}
		&.selected-leave-type {
			border: 1px solid #51804d;
			border-radius: 2px;
			color: #fff;
			font-weight: 700;
		}
	}
`;
