export function fetching() {
	return { type: 'LEAVE_TYPE_FETCHING' };
}

export function fetched(payload) {
	return { type: 'LEAVE_TYPE_FETCHED', payload };
}

export function error(payload) {
	return { type: 'LEAVE_TYPE_ERROR', payload };
}

export function userLeaveFetching() {
	return { type: 'USER_LEAVE_FETCHING' };
}

export function userLeaveFetched(payload) {
	return { type: 'USER_LEAVE_FETCHED', payload };
}

export function userLeaveError(payload) {
	return { type: 'USER_LEAVE_ERROR', payload };
}

export function fetchingTodayLeaves() {
	return { type: 'TODAY_LEAVE_FETCHING' };
}

export function fetchedTodayLeaves(payload) {
	return { type: 'TODAY_LEAVE_FETCHED', payload };
}

export function todayLeaveError(payload) {
	return { type: 'TODAY_LEAVE_ERROR', payload };
}

export function fetchingTodayWFH() {
	return { type: 'TODAY_WFH_FETCHING' };
}

export function fetchedTodayWFH(payload) {
	return { type: 'TODAY_WFH_FETCHED', payload };
}

export function todayWFHError(payload) {
	return { type: 'TODAY_WFH_ERROR', payload };
}
