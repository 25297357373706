import Api from './api';
import ApiUrlConstant from '../helpers/constant/api-url';

class WorkingFromHomeApi {

	async submitLeave(params,formData) {
		try {
			let data = await Api.post(
				await ApiUrlConstant.USER_WORKING_FROM_HOME_APPLICATION(params.userId),
				formData
			);
			return data.body;
		} catch (error) {
			return error;
		}
	}

  async submitTrip(params,formData) {
    try {
      let data = await Api.post(
        await ApiUrlConstant.USER_OFFICIAL_TRIP(params.userId),
        formData
      );
      return data.body;
    } catch (error) {
      return error;
    }
  }

	async getTodayWFH(params,formData) {
		try {
			let data = await Api.get(
				await ApiUrlConstant.WFH_TODAY_USERS(),
				formData
			);
			return data.body;
		} catch (error) {
			return error;
		}
	}

}

export default new WorkingFromHomeApi();
