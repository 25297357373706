import React, { Component } from "react";
import { slide as Menu } from "react-burger-menu";
import { Button, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import Select from "react-select";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import TickIcon from "../../images/ic-tick.svg";
import TaskIcon from "../../images/ic-task-list.svg";
import ProjectApi from "../../services/ProjectApi";
import * as ProjectAction from "../../actions/project";
import ScrollBar from "react-scrollbar/dist/no-css";
import "react-scrollbar/dist/css/scrollArea.css";

const TaskComponent = (props) => {
  return (
    <li className="task-input-list">
      <div className="input-block">
        <textarea
          ref={(ref) => (props.self[props.idRef] = ref)}
          onFocus={(e) => props._handleFocus(e)}
          id={props.idRef}
          className={`input-task ${props.self.state[`taskError${props.count}`] ? 'error' : ''
            }`}
          placeholder="Enter your task here..."
          onChange={(e) => props._handleTaskEnter(e, props.count)}
          onKeyDown={props._handleKeyDown}
          value={props.self.state.tasks[props.count].task}
        ></textarea>
        {props.count > 0 && (
          <RemoveTask onClick={() => props._handleDelete(props.count)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="10"
              height="14"
              viewBox="0 0 10 14"
            >
              <defs>
                <path
                  id="f"
                  d="M2.25 14.75h6c.825 0 2-1.175 2-2v-8h-10v8c0 .825 1.175 2 2 2zm8-13h-2l-1-1h-4l-1 1h-2v2h10v-2z"
                />
              </defs>
              <use
                fill="#ddd"
                fillRule="evenodd"
                transform="translate(-.25 -.75)"
                xlinkHref="#f"
              />
            </svg>
          </RemoveTask>
        )}
      </div>
    </li>
  );
};

class AddDeliverable extends Component {
  constructor(props) {
    super(props);
    this.focusSelectProject = null;
    this.state = {
      focused: false,
      autoFocus: false,
      tasks: [],
      counter: 0,
      previousDeliverable: []
    };
  }

  componentDidUpdate() {
    if (this[`task${this.state.tasks.length - 1}`] && !this.state.focused) {
      this.setState({ focused: true });
      this[`task${this.state.tasks.length - 1}`].focus();
    }

    if (this.props.isOpen && !this.state.autoFocus) {
      let weekId = this.props.prevWeekId;
      let { projectId } = this.state;
      if (projectId && weekId) {
        this.listDeliverable({ weekId, projectId })
      }
      this.setState({ autoFocus: true });
    } else if (!this.props.isOpen && this.state.autoFocus) {
      this.setState({ autoFocus: false });
    }
  }

  _handleFocus = (e) => {
    const target = e.target;
    if (target)
      setTimeout(() => {
        target.placeholder = "Enter your task here...";
        target.value = target.value.trim() !== "" ? target.value : null;
        target.selectionEnd = 0;
      }, 0);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let data = {};
    if (nextProps.mode !== prevState.mode) {
      data.mode = nextProps.mode;
      data.projectDeliverableDetail = nextProps.projectDeliverableDetail;
      data.value = null;
    }
    if (
      nextProps.projectDeliverableDetail &&
      nextProps.projectDeliverableDetail !== prevState.projectDeliverableDetail
    ) {
      let tasks = [];
      nextProps.projectDeliverableDetail.project_deliverables.length &&
        nextProps.projectDeliverableDetail.project_deliverables[0].tasks.forEach(
          (t, i) => {
            tasks.push({
              task: t.task,
              isCompleted: t.isCompleted
            });
          }
        );

      data.tasks = tasks;
      data.projectDeliverableDetail = nextProps.projectDeliverableDetail;
      data.value = {
        value: nextProps.projectDeliverableDetail.id,
        label: nextProps.projectDeliverableDetail.title
      };
      data.projectId = nextProps.projectDeliverableDetail.id;
    }
    data.isOpen = nextProps.isOpen;
    data.isDeliverableOpen = nextProps.isDeliverableOpen;
    data.projectList = nextProps.projectList;
    return data;
  }

  _handleDelete = (key) => {
    let { tasks, previousDeliverable } = this.state;
    previousDeliverable.filter((p) => {
      return p.task === tasks[key].task
        ? (p.isCompleted = false)
        : p.isCompleted;
    });

    tasks.splice(key, 1);
    this.setState({ previousDeliverable });
    this.setState({ tasks, [`taskError${key}`]: false });
    this.state.counter--;
  };

  _handleKeyDown = (e) => {
    if (e.key === ("Enter" || "Tab")) {
      this.setState({
        focused: false,
        tasks: [
          ...this.state.tasks,
          {
            task: ""
          }
        ]
      });
      this.state.counter++;
    }
  };

  _handleTaskEnter = (e, key) => {
    let tasks = this.state.tasks;
    tasks[key].task = e.target.value;
    this.setState({ tasks, [`taskError${key}`]: false });
  };

  _handleTaskClick = (value) => {
    let { tasks, previousDeliverable } = this.state;

    previousDeliverable.filter((p) => {
      return p.task === value ? (p.isCompleted = true) : p.isCompleted;
    });

    this.setState({ previousDeliverable });

    let check = tasks.filter((t) => {
      let tr = t.task.trim();
      return tr.length === 0;
    });

    if (check.length === 0) {
      tasks.push({ task: value, isCompleted: false });
      this.setState({ tasks });
      this.state.counter++;
    } else {
      let counter = this.state.counter;
      for (let t = 0; t < tasks.length; t++) {
        let tr = tasks[t].task.trim();
        if (tr.length === 0) {
          counter = t;
          break;
        }
      }
      tasks[counter].task = value;
      tasks[counter].isCompleted = false;
      this.setState({ tasks, [`taskError${counter}`]: false });
    }
  };

  submitForm = (e) => {
    const { tasks } = this.state;
    let canSubmit = true;
    let pDeliverables = [];
    tasks.map((t, i) => {
      if (!t.isCompleted) {
        t.isCompleted = false;
      }
      if (t.task.trim() === "" || t.task.charCodeAt(0) === 10) {
        canSubmit = false;
        this.setState({ [`taskError${i}`]: true });
      }

    });
    for (let i = 0; i <= tasks.length - 1; i++) {
      if (tasks[i].task.trim() !== "") {
        pDeliverables.push({
          task: tasks[i].task.trim(),
          isCompleted: tasks[i].isCompleted
        });
      }
    }

    let data = {
      tasks: pDeliverables
    };
    if (!this.state.value) {
      canSubmit = false;
      this.setState({ deliverableError: true })
    }

    if (canSubmit) {
      let params = {
        weekId: this.props.weekId,
        projectId: this.state.value.value
      };

      ProjectApi.createProjectDeliverable(params, data)
        .then((response) => {
          if (response.success) {
            this.setState({
              isCompleted: "false",
              tasks: [],
              value: null
            });
            toastr.success("", response.message);
            this.props.listUserProjects(params.weekId);
            this.props.fetchUserLeadingProjects(params.weekId);
          } else {
            this.setState({ disabled: false });
            toastr.error("", response.error.message);
          }
        })
        .catch((err) => {
          this.setState({ disabled: false });
          toastr.error("", err.error.message);
        });
    }
  };

  changeProject = (e) => {
    const { prevWeekId } = this.props;

    if (prevWeekId) {
      this.listDeliverable({ weekId: prevWeekId, projectId: e.value })
    }

    this.setState(
      {
        value: { value: e.value, label: e.label },
        deliverableError: false
      },
      () => {
        let tasks = [];
        let selectedProject = this.props.projectList.projects.filter(
          (project, i) => project.id === this.state.value.value
        );
        selectedProject[0] && selectedProject[0].project_deliverables.length > 0 &&
          selectedProject[0].project_deliverables[0].tasks.forEach((t, i) => {
            tasks.push({
              task: t.task,
              isCompleted: t.isCompleted
            });
          });

        this.setState({ tasks: tasks });
        return true;
      }
    );
  };

  listDeliverable(params) {
    ProjectApi.listProjectDeliverable(params)
      .then(response => {
        this.setState({
          deliverables: response.data,
          // tasks: [],
          counter: 0
        });
      })
      .catch(err => {
        toastr.error('', err.error.message);
      });
  }

  clearState = (state) => {
    if (this.state.mode === "add") {
      this.setState({ tasks: [], value: null });
    }
    if (
      !state.isOpen &&
      !this.state.projectDeliverableDetail &&
      this.state.mode === "add"
    ) {
      this.setState({ tasks: [], value: null });
    }
  };

  render() {
    const { projectDeliverableDetail, deliverableProjectsList } = this.props;
    let { tasks, previousDeliverable, deliverables, value, deliverableError } = this.state;

    if (deliverables && value) {
      // let prevWeekProject = projectList.projects.find((p) => +p.id === +value.value);
      previousDeliverable = deliverables && deliverables.tasks
        ? deliverables.tasks.map((obj) => ({
          ...obj,
          isCopied: false
        }))
        : [];
    }

    previousDeliverable.map((p) => {
      tasks.filter((t) => {
        return t.task === p.task ? (p.isCopied = true) : p.isCompleted;
      });
    });

    if (tasks.length < 1) {
      tasks.push({ task: "", isCompleted: false });
    }
    let projectsList = deliverableProjectsList || [];
    return (
      <AddEstimateWrapper showMenu={this.state.isOpen}>
        <Menu
          onStateChange={(state) => {
            this.state.isDeliverableOpen(state);
            this.clearState(state);
          }}
          isOpen={this.state.isOpen}
        >
          <h3>Add project deliverables</h3>
          <Row>
            <Col className="project-name-block" sm={12}>
              <label className="project-name-label">Project Name</label>
              <span className="project-name">
                <Select
                  ref={(elem) => (this.focusSelectProject = elem)}
                  isDisabled={projectDeliverableDetail ? "isDisabled" : ""}
                  options={projectsList}
                  value={this.state.value}
                  onChange={this.changeProject}
                  placeholder="Choose a project"
                  className={`select-wrapper select-project ${
                    deliverableError ? 'error' : ''
                    }`}
                />
              </span>
            </Col>

            <Col sm={12}>
              <label>Add Tasks</label>
              <div className="flex task-block">
                <img src={TaskIcon} alt="task" width="24" height="24" />
                <div className="task-list">
                  <ScrollBar
                    className="options"
                    contentClassName="content"
                    smoothScrolling={true}
                  >
                    <ul id="taskLists">
                      {tasks.map((t, i) => (
                        <TaskComponent
                          key={`task${i}`}
                          count={i}
                          idRef={`task${i}`}
                          self={this}
                          _handleKeyDown={this._handleKeyDown}
                          _handleTaskEnter={this._handleTaskEnter}
                          // _handleKeyUp={this._handleKeyUp}
                          _handleDelete={this._handleDelete}
                          _handleFocus={this._handleFocus}
                        />
                      ))}
                    </ul>
                    <span className="help-text">
                      Press ↵ enter to add a new deliverable
                    </span>
                  </ScrollBar>
                </div>
                {value && previousDeliverable.length > 0 && (
                  <div className="lastweek-tasks-block">
                    <h3>
                      Last week Deliverables
                      <small> ({value.label})</small>
                    </h3>
                    <ul id="taskLists">
                      {previousDeliverable &&
                        previousDeliverable.map((t, i) => (
                          <li key={i}>
                            <div>
                              <p>
                                {t.task}{" "}
                                {!t.isCopied && !t.isCompleted ? (
                                  <span style={{ marginLeft: "50px" }}>
                                    <a
                                      onClick={(e) =>
                                        this._handleTaskClick(t.task)
                                      }
                                    >
                                      Copy
                                    </a>
                                  </span>
                                ) : (
                                    ""
                                  )}
                              </p>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <div className="btn-wrap">
            <Button
              bsStyle="primary"
              onClick={(e) => {
                this.submitForm(e);
              }}
            >
              Add Deliverables
            </Button>
          </div>
        </Menu>
      </AddEstimateWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  projectList: state.project.projectList
});

const mapDispatchToProps = (dispatch) => ({
  listUserProjects: (weekId) => {
    dispatch(ProjectAction.userProjectFetching);
    ProjectApi.listUserWeekProject({ weekId: weekId })
      .then((response) => {
        if (response.success) {
          dispatch(ProjectAction.userProjectFetched(response));
        } else {
          dispatch(ProjectAction.userProjectError(response));
        }
      })
      .catch((err) => {
        dispatch(ProjectAction.userProjectError(err));
      });
  },
  fetchUserLeadingProjects: (weekId) => {
    dispatch(ProjectAction.userLeadingProjectsFetching());
    ProjectApi.listUserLeadingProjects(weekId)
      .then(response => {
        if (response.success) {
          dispatch(ProjectAction.userLeadingProjectsFetched(response.data));
        } else {
          dispatch(ProjectAction.error('error'))
        }
      })
      .catch(err => {
        dispatch(ProjectAction.error(err.message))
      })
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(AddDeliverable);

const AddEstimateWrapper = styled.div`
  .css-w8afj7-Input {
    color: #fff;
  }
  .css-1uccc91-singleValue {
    color: #eee;
  }
  .select-hour {
    color: #fff;
  }
  .error {
    border-bottom: 1px solid red;
  }

  .project-name-block {
    margin-bottom: 40px;
    .project-name {
      display: block;
      padding: 7px 0;
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .bm-menu-wrap {
    top: 0;
    .bm-menu {
      overflow: unset !important;
    }
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .select-wrapper {
    margin-bottom: 35px;
  }
  .flex {
    align-items: center;
    /* margin-bottom: 35px; */
    img {
      margin-right: 8px;
    }
  }
  .time-help-text {
    font-size: 60%;
    color: #fff;
    opacity: 0.5;
    margin-bottom: 35px;
    display: block;
    margin-top: 10px;
  }

  label {
    margin-bottom: 8px;
  }

  .project-name-label {
    margin-bottom: 0;
  }

  .select-hour[readonly] {
    background-color: unset;
    border: 0;
    height: 26px;
    margin: 0;
    transition: background-color 5000s ease-in-out 0s !important;
    width: 40px;
  }

  .btn-wrap {
    .btn {
      &::before {
        content: "";
        display: inline-block;
        background-image: url(${TickIcon});
        background-repeat: no-repeat;
        height: 12px;
        width: 15px;
        margin-right: 16px;
      }
    }
  }
  ul {
    margin-left: 0;
    list-style: none;
    padding-left: 0;
  }

  ul > li {
    display: flex;
    align-items: baseline;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 4px;
    padding: 0 0 2px 30px;
  }

  /* Prevent nested li's from getting messed up */
  ul > li::before {
    content: "- ";
    margin-left: -15px;
  }
  .task-block {
    align-items: unset;
    position: relative;
    img {
      margin-top: 10px;
    }
    .scrollarea {
      height: initial;
      min-height: 130px;
      max-height: 250px;
      width: 100%;
    }
    .lastweek-tasks-block {
      display: ${(props) => (props.showMenu ? "block" : "none")};
      width: 282px;
      height: 300px;
      border-radius: 4px;
      box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.5);
      background-color: #d8d8d8;
      position: absolute;
      left: 100%;
      padding: 14px 19px;
      overflow: scroll;
      h3 {
        color: #333333;
        font-size: 12px;
        font-weight: 700;
        margin: 0;
        margin-bottom: 6px;
        small {
          color: #333333;
          font-size: 12px;
          font-weight: 500;
          font-style: italic;
        }
      }
      ul {
        padding-inline-start: 19px;
        li {
          color: #333333;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.86;
        }
      }
      ul {
        list-style: none;
        padding-left: 0;
      }

      ul > li {
        /* margin-left: 15px; */
        align-items: unset;
      }

      /* Prevent nested li's from getting messed up */
      ul > li::before {
        content: "- ";
        margin-left: -28px;
        /* margin-top: 6px; */
      }
    }
    .task-list {
      background-color: rgba(255, 255, 255, 0.07);
      border-radius: 4px;
      box-shadow: 0 2px -4px 0 rgba(0, 0, 0, 0.5);
      min-height: 130px;
      padding: 5px 0;
      width: 399px;

      .help-text {
        opacity: 0.5;

        font-size: 10px;

        color: #ffffff;
        margin-left: 35px;
      }

      .task-input-list {
        .input-block {
          display: flex;
          .input-task {
            width: 270px;
            padding: 6px 10px;
            background-color: transparent;
            border: 0;
            font-size: 14px;
            margin-right: 16px;
            height: auto;
            resize: none;
            &.error{
							border: 1px solid #f75a51;
              background: rgba(221, 59, 43, 0.4);
						}
          }
          .hrs-block {
            background-color: #2a2e38;
            border: none;
            border-radius: 2px;
            display: flex;
            height: 37px;
            align-items: baseline;
            outline: none;
            width: 64px;
            .input-hrs {
              background-color: transparent;
              width: 32px;
              border: none;
              padding-left: 10px;
              padding-right: 4px;
              padding-top: 8px;
              font-size: 14px;
              text-align: right;
              color: #ffffff;
            }
            span {
              opacity: 0.7;
              font-size: 12px;
              text-align: right;
              color: #ffffff;
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
  .bm-item-list .flex textarea {
    background-color: rgba(255, 255, 255, 0.07);
    border: 0;
    border-radius: 4px;
    box-shadow: 0 2px -4px 0 rgba(0, 0, 0, 0.5);
    color: #fff;
    height: 136px;
    opacity: 0.7;
    padding: 14px 20px;
  }
`;

const RemoveTask = styled.a`
  cursor: pointer;
  margin: 8px;
  text-align: center;
  transition: opacity 0.2s ease-in-out;
  &:hover,
  &:focus {
    color: #fff;
    text-decoration: none;
  }
`;