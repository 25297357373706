import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import Api from './api'
import UserLogin from './UserApi';
import { GET_ERRORS, SET_CURRENT_USER} from "../helpers/constant/types";

export const login = userData => dispatch => {
  Api.post('login', userData)
    .then(User => {
      if (User.body.success) {
        const {token} = User.body.data;
        Cookies.set('sombarToken', token);
        let object = {token: token, timestamp: new Date().getTime()}
        localStorage.setItem("sombarToken", JSON.stringify(object));
        const decoded = jwt_decode(token);
        dispatch(setCurrentUser(decoded))
      } else {
        throw new Error(User.body.error.message)
      }
    })
    .catch(error => {
      dispatch({
        type: GET_ERRORS,
        payload: error
      })
    })
}

export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

export const logoutUser = () => dispatch => {
  localStorage.removeItem("sombarToken");
  Cookies.remove('sombarToken');
  // Remove auth header for future requests
  // setAuthToken(false);
  //Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

export const userLogInData = () =>{
    UserLogin.saveUserLoginInformation();
 }
