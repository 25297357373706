import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import leaveReducer from './leaveReducer';
import holidayReducer from './holidayReducer';
import userReducer from './userReducer';
import weekReducer from './weekReducer';
import projectReducer from './projectReducer';
import leaveNotificationsReducer from './leaveNotificationsReducer';

const reducers = {
	auth: authReducer,
	errors: errorReducer,
	toastr: toastrReducer,
	leave: leaveReducer,
	holiday: holidayReducer,
	user: userReducer,
	week: weekReducer,
	project: projectReducer,
	leaveNotifications: leaveNotificationsReducer
};

export default combineReducers(reducers);
