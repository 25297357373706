import React, { Component } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Button, Col, FormControl, Row } from 'react-bootstrap';
import Select from 'react-select';
import styled from 'styled-components';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import ClockIcon from '../../images/ic-clock.svg';
import TickIcon from '../../images/ic-tick.svg';
import TaskIcon from '../../images/ic-task-list.svg';
import ProjectApi from '../../services/ProjectApi';
import * as ProjectAction from '../../actions/project';
import ScrollBar from 'react-scrollbar/dist/no-css';
import 'react-scrollbar/dist/css/scrollArea.css';

const TaskComponent = props => {
	return (
		<li className='task-input-list'>
			<div className='input-block'>
				<textarea
					ref={ref => (props.self[props.idRef] = ref)}
					onFocus={e => props._handleFocus(e)}
					id={props.idRef}
					className={`input-task ${props.self.state[`taskError${props.count}`] ? 'error' : ''
						}`}
					placeholder='Enter your task here...'
					onChange={e => props._handleTaskEnter(e, props.count)}
					onKeyDown={props._handleKeyDown}
					value={props.self.state.tasks[props.count].task}
				></textarea>
				<div className={`hrs-block ${props.self.state[`hourError${props.count}`] ? 'error' : ''
					}`} >
					<input
						className='input-hrs'
						type='number'
						value={props.self.state.tasks[props.count].hour}
						onChange={e => props._handleTaskHour(e, props.count)}
						onKeyDown={props._handleKeyDown}
						onKeyUp={props._handleKeyUp}
					/>
					<span>hrs</span>
				</div>
				{props.count > 0 && (
					<RemoveTask onClick={() => props._handleDelete(props.count)}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							xmlnsXlink='http://www.w3.org/1999/xlink'
							width='10'
							height='14'
							viewBox='0 0 10 14'
						>
							<defs>
								<path
									id='f'
									d='M2.25 14.75h6c.825 0 2-1.175 2-2v-8h-10v8c0 .825 1.175 2 2 2zm8-13h-2l-1-1h-4l-1 1h-2v2h10v-2z'
								/>
							</defs>
							<use
								fill='#ddd'
								fillRule='evenodd'
								transform='translate(-.25 -.75)'
								xlinkHref='#f'
							/>
						</svg>
					</RemoveTask>
				)}
			</div>
		</li>
	);
};

class AddEstimate extends Component {
	constructor(props) {
		super(props);
		this.focusSelectProject = null;
		this.state = {
			focused: false,
			hours: '',
			tasks: [],
			autoFocus: false,
			counter: 0,
			projectId: "",
			prevWeekTask: [],
			editMode: false
		};
	}

	componentDidUpdate() {
		if (this[`task${this.state.tasks.length - 1}`] && !this.state.focused) {
			this.setState({ focused: true });
			this[`task${this.state.tasks.length - 1}`].focus();
		}

		if (this.props.isOpen && !this.state.autoFocus) {
			let weekId = this.props.prevWeekId;
			let { projectId } = this.state;
			if (projectId && weekId) {
				this.listUserProjectTask({ weekId, projectId })
			}
			setTimeout(() => {
				if (this.focusSelectProject) {
					this.focusSelectProject.select.focus();
				}
				this.setState({ autoFocus: true });
			}, 0);
		} else if (!this.props.isOpen && this.state.autoFocus) {
			this.setState({ autoFocus: false });
		}
	}

	_handleFocus = e => {
		const target = e.target;
		if (target)
			setTimeout(() => {
				target.placeholder = 'Enter your task here...';
				target.value = target.value.trim() !== '' ? target.value : null;
				target.selectionEnd = 0;
			}, 0);
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		let data = {};

		if (nextProps.mode !== prevState.mode) {
			data.mode = nextProps.mode;
			data.projectData = nextProps.projectData;
			data.value = null;
			data.hours = '';
			data.tasks = [];
		}
		if (
			nextProps.projectData &&
			nextProps.projectData !== prevState.projectData
		) {
			data.projectData = nextProps.projectData;
			data.value = {
				value: nextProps.projectData.id,
				label: nextProps.projectData.title
			};
			data.projectId = nextProps.projectData.id;
			data.hours = nextProps.projectData.hour;
			let projectTask = nextProps.projectData.user_projects.find(
				p => +p.userId === +nextProps.userId
			);
			let tasks = [];
			projectTask.tasks.forEach((t, i) => {
				tasks.push({
					task: t.task,
					hour: t.hour,
					isCompleted: t.isCompleted,
					taskCompletedDate: t.taskCompletedDate,
					timeSpent: t.timeSpent,
					priority: t.priority
				});
			});
			data.tasks = tasks;
		}
		return data;
	}

	componentDidMount() {
		const { prevWeekId } = this.props;
		// if(prevWeekId){
		//  ProjectApi.listUserProjectList({ prevWeekId })
		//    .then(response => {
		//      this.setState({
		//        userProjectList: response.data.project,
		//        tasks: [],
		//        counter: 0
		//      });
		//    })
		//    .catch(err => {
		//      toastr.error('', err.error.message);
		//    });
		//  this.setState({
		//    projectError: false
		//  });
		// }
	}

	_handleDelete = key => {
		let { tasks, prevWeekTask } = this.state;

		prevWeekTask.filter(p => {
			return p.task === tasks[key].task
				? (p.isCopied = false)
				: (p.isCopied = true);
		});

		let hours = 0;
		tasks.splice(key, 1);
		this.state.tasks.forEach((t, i) => (hours = hours + +t.hour));
		this.setState({ prevWeekTask });
		this.setState({ tasks, hours, [`taskError${key}`]: false, [`hourError${key}`]: false });
		this.state.counter--;
	};

	_handleKeyUp = e => {
		let hours = 0;
		this.state.tasks.forEach((t, i) => (hours = hours + +t.hour));
		this.setState({
			hours
		});
	};

	_handleKeyDown = e => {
		if (e.key === 'Enter') {
			this.setState({
				focused: false,
				tasks: [
					...this.state.tasks,
					{
						task: '',
						hour: ''
					}
				]
			});
			this.state.counter++;
		} else if (e.key === 'Tab' && e.target.type === 'number') {
			this.setState({
				focused: false,
				tasks: [
					...this.state.tasks,
					{
						task: '',
						hour: ''
					}
				]
			});
		}
	};

	_handleTaskEnter = (e, key) => {
		let tasks = this.state.tasks;
		tasks[key].task = e.target.value;
		this.setState({ tasks, [`taskError${key}`]: false });
	};

	_handleTaskHour = (e, key) => {
		let tasks = this.state.tasks;
		tasks[key].hour = e.target.value;
		this.setState({ tasks, [`hourError${key}`]: false });
	};

	changeProject = e => {
		const { prevWeekId } = this.props;
		this.setState({
			editMode: false,
			hours: ''
		})
		if (prevWeekId) {
			this.listUserProjectTask({ weekId: prevWeekId, projectId: e.value })
		}

		this.setState({
			projectError: false,
			value: { value: e.value, label: e.label }
		},
			() => {
				let tasks = [];
				let selectedProject = this.props.projectList.projects.filter(
					(project, i) => project.id === e.value
				);
				selectedProject[0] &&
					selectedProject[0].tasks && selectedProject[0].tasks.length > 0 &&
					selectedProject[0].tasks.forEach((t, i) => {
						tasks.push({
							task: t.task,
							isCompleted: t.isCompleted,
							hour: t.hour,
							taskCompletedDate: t.taskCompletedDate || null,
							timeSpent: t.timeSpent || null,
							priority: t.priority || null
						});
						this.setState({
							editMode: true,
							hours: selectedProject[0].hour
						})
					});

				this.setState({ tasks: tasks });
				return true;
			});
	};

	clearState = (state) => {
		if (this.state.mode === "add") {
			this.setState({ tasks: [], value: null });
		}
		if (!state.isOpen && this.state.mode === "add"
		) {
			this.setState({ tasks: [], value: null });
		}
	};

	listUserProjectTask(params) {
		ProjectApi.listUserProjectList(params)
			.then(response => {
				this.setState({
					taskLists: response.data,
					counter: 0
				});

			})
			.catch(err => {
				toastr.error('', err.error.message);
			});
	}

	_handleTaskCopied = value => {
		let { tasks, prevWeekTask } = this.state;

		prevWeekTask.filter(p => {
			return p.task === value ? (p.isCopied = true) : (p.isCopied = false);
		});

		this.setState({ prevWeekTask });

		let check = tasks.filter(t => {
			let tr = t.task.trim();
			return tr.length === 0;
		});

		if (check.length === 0) {
			tasks.push({ task: value });
			this.setState({ tasks });
			this.state.counter++;
		} else {
			let counter = this.state.counter;
			for (let t = 0; t < tasks.length; t++) {
				let tr = tasks[t].task.trim();
				if (tr.length === 0) {
					counter = t;
					break;
				}
			}
			tasks[counter].task = value;
			tasks[counter].isCompleted = false;
			this.setState({ tasks, [`taskError${counter}`]: false });
		}
	};

	submitForm = e => {
		const { value, hours, tasks, projectData, editMode } = this.state;
		const userId = this.props.userId;
		let pTasks = [],
			remarks = '';

		let canSubmit = true;

		if (!value) {
			canSubmit = false;
			this.setState({ projectError: true });
		}

		tasks.map((task, index) => {
			if (task.task.trim() === "" || task.task.charCodeAt(0) === 10) {
				canSubmit = false;
				this.setState({ [`taskError${index}`]: true });
			}
			if (!task.hour) {
				canSubmit = false;
				this.setState({ [`hourError${index}`]: true });
			}
			if (!task.isCompleted) {
				task.isCompleted = false;
			}
			if (!task.isCompleted) {
				task.taskCompletedDate = null;
			}
			if (!task.isCompleted) {
				task.timeSpent = null;
			}
			if (!task.isCompleted) {
				task.priority = null;
			}
		})

		// tasks.map((t, i) => {
		// 	if (!t.isCompleted) {
		// 	  t.isCompleted = false;
		// 	}
		// });

		if (canSubmit) {
			for (let i = 0; i <= tasks.length - 1; i++) {
				if (tasks[i].task.trim() !== '') {
					remarks =
						remarks !== ''
							? `${remarks};${tasks[i].task.trim()}`
							: tasks[i].task.trim();
					pTasks.push({
						task: tasks[i].task.trim(),
						hour: +tasks[i].hour || 0,
						isCompleted: tasks[i].isCompleted,
						taskCompletedDate: tasks[i].taskCompletedDate || null,
						timeSpent: tasks[i].timeSpent || null,
						priority: tasks[i].priority || null
					});
				}
			}

			let data = {
				projectId: value.value,
				weekId: this.props.weekId,
				hour: hours,
				remarks: remarks,
				userId: userId,
				tasks: pTasks
			};

			if (projectData || editMode) {
				ProjectApi.updateUserProject(
					{ weekId: data.weekId, projectId: data.projectId },
					data
				)
					.then(response => {
						if (response.success) {
							this.setState({ value: null, hours: '', tasks: [] });

							this.props.listUserProjects(data.weekId);
							toastr.success('', response.message);
							this.setState({ editMode: false })
						} else {
							toastr.error('', response.error.message);
							this.setState({ editMode: true })
						}
					})
					.catch(err => {
						toastr.error('', err.error.message);
						this.setState({ editMode: true })
					});
			} else {
				ProjectApi.createProject(data, userId)
					.then(response => {
						if (response.success) {
							this.setState({ value: null, hours: '', tasks: [] });

							this.props.listUserProjects(data.weekId);
							this.props.fetchUserTasks({ weekId: data.weekId })
							toastr.success('', response.message);

						} else {
							this.setState({ disabled: false });
							toastr.error('', response.error.message);

						}
					})
					.catch(err => {
						this.setState({ disabled: false });
						toastr.error('', err.error.message);
					});
			}
		}
	};

	render() {
		const { isOpen, projects, isMenuOpen, projectData } = this.props;
		let {
			tasks,
			value,
			taskLists,
			hours,
			projectError,
			hourError,
			prevWeekTask
		} = this.state;

		let projectsList = projects || [];

		if (taskLists && value) {
			// let prevWeekProject = userProjectList.find(p => +p.id === +value.value);
			prevWeekTask = taskLists && taskLists.tasks ? taskLists.tasks.map(obj => ({ ...obj, isCopied: false })) : [];
		}

		prevWeekTask.map(p => {
			tasks.filter(t => {
				return t.task === p.task ? (p.isCopied = true) : p.isCompleted;
			});
		});

		if (tasks.length < 1) {
			tasks.push({ task: '', hour: '', isCompleted: false, taskCompletedDate: null, timeSpent: null, priority: null });
		}

		return (
			<AddEstimateWrapper showMenu={isOpen}>
				<Menu onStateChange={(state) => { isMenuOpen(state); this.clearState(state) }} isOpen={isOpen}>
					<h3>Add estimate</h3>
					<Row>
						<Col sm={12}>
							<label className='project-name-label'>Project Name</label>
							<Select
								ref={elem => (this.focusSelectProject = elem)}
								isDisabled={projectData ? 'isDisabled' : ''}
								value={value}
								options={projectsList}
								onChange={this.changeProject}
								placeholder='Choose a project'
								className={`select-wrapper select-project ${
									projectError ? 'error' : ''
									}`}
							/>
						</Col>
						<Col sm={12}>
							<div style={{ float: 'right', marginRight: '-14px' }}>
								<label>Total Estimated Time</label>
								<div className='flex'>
									<img src={ClockIcon} alt='clock' />
									<FormControl
										key='hour'
										type='number'
										name='hour'
										readOnly={true}
										className={`select-wrapper select-hour ${
											hourError ? 'error' : ''
											}`}
										value={hours}
										onChange={e =>
											this.setState({ hourError: false, hours: e.target.value })
										}
									/>
									<span>Hrs</span>
								</div>
								{/* <small className="time-help-text">
								The hours will be calculated based on the task's hour inserted
								below.
							</small> */}
							</div>
						</Col>

						<Col sm={12}>
							<label>Add Tasks</label>
							<div className='flex task-block'>
								<img src={TaskIcon} alt='task' width='24' height='24' />
								<div className='task-list'>
									<ScrollBar
										className='options'
										contentClassName='content'
										smoothScrolling={true}
									>
										<ul id='taskLists'>
											{tasks.map((t, i) => (
												<TaskComponent
													key={`task${i}`}
													count={i}
													idRef={`task${i}`}
													self={this}
													_handleKeyDown={this._handleKeyDown}
													// task={tasks[i].task}
													// taskHour={tasks[i].hour}
													_handleTaskEnter={this._handleTaskEnter}
													_handleTaskHour={this._handleTaskHour}
													_handleKeyUp={this._handleKeyUp}
													_handleDelete={this._handleDelete}
													_handleFocus={this._handleFocus}
												/>
											))}
										</ul>
										<span className='help-text'>
											Press ↵ enter to add a new task
										</span>
									</ScrollBar>
								</div>
								{value && prevWeekTask.length > 0 && (
									<div className='lastweek-tasks-block'>
										<h3>
											Last week tasks
											<small> ({value.label})</small>
										</h3>
										<ul id='taskLists'>
											{prevWeekTask &&
												prevWeekTask.map((t, i) => (
													<li key={i}>
														<div>
															<p>
																{t.task}{' '}
																<span
																	style={{ marginLeft: '12px', color: 'brown' }}
																>
																	({t.hour}hr)
																</span>
																{!t.isCopied ? (
																	<span style={{ marginLeft: '50px' }}>
																		<a
																			onClick={e =>
																				this._handleTaskCopied(t.task)
																			}
																		>
																			Copy
																		</a>
																	</span>
																) : (
																		''
																	)}
															</p>
														</div>
													</li>
												))}
										</ul>
									</div>
								)}
							</div>
						</Col>
					</Row>
					<div className='btn-wrap'>
						<Button
							// disabled={props.this.state.disabled}
							bsStyle='primary'
							onClick={e => {
								// props.this.setState({ disabled: true });
								this.submitForm(e);
							}}
						>
							Save this estimate
						</Button>
						{/* <Button
						onClick={e => {
							// props.this.submitForm(e, true);
						}}
					>
						{' '}
						Save & add another{' '}
					</Button> */}
					</div>
				</Menu>
			</AddEstimateWrapper>
		);
	}
}

const mapStateToProps = state => ({
	projectList: state.project.projectList
});

const mapDispatchToProps = dispatch => ({
	listUserProjects: weekId => {
		dispatch(ProjectAction.userProjectFetching);
		ProjectApi.listUserWeekProject({ weekId: weekId })
			.then(response => {
				if (response.success) {
					dispatch(ProjectAction.userProjectFetched(response));
				} else {
					dispatch(ProjectAction.userProjectError(response));
				}
			})
			.catch(err => {
				dispatch(ProjectAction.userProjectError(err));
			});
	},
	fetchUserTasks: (weekId) => {
		dispatch(ProjectAction.userTasksFetching());
		ProjectApi.listTasks(weekId)
			.then(response => {
				if (response.success) {
					dispatch(ProjectAction.userTasksFetched(response.data.tasks));
				} else {
					dispatch(ProjectAction.userTasksError('error'))
				}
			})
			.catch(err => {
				console.log(err)
				dispatch(ProjectAction.userTasksError(err.message))
			})
	}
});
export default connect(mapStateToProps, mapDispatchToProps)(AddEstimate);

const AddEstimateWrapper = styled.div`
	/* .css-w8afj7-Input {
		color: #fff;
	} */
	.css-1uccc91-singleValue {
		color: #fff;
	}
	.select-hour {
		color: #fff;
		color: rgba(255, 255, 255, 0.25);
	}
	.error {
		border-bottom: 1px solid red;
	}
	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type='number'] {
		-moz-appearance: textfield;
	}
	.bm-menu-wrap {
		top: 0;
		.bm-menu {
			overflow: unset !important;
		}
	}
	.css-1okebmr-indicatorSeparator {
		display: none;
	}
	.select-project {
		margin-bottom: 35px;
		input {
			color: #fff !important;
		}
	}

	.select-wrapper {
		margin-bottom: 35px;
	}
	.flex {
		align-items: center;
		/* margin-bottom: 35px; */
		img {
			margin-right: 8px;
		}
	}
	.time-help-text {
		font-size: 60%;
		color: #fff;
		opacity: 0.5;
		margin-bottom: 35px;
		display: block;
		margin-top: 10px;
	}

	label {
		margin-bottom: 8px;
	}

	.project-name-label {
		margin-bottom: 0;
	}

	.select-hour[readonly] {
		background-color: unset;
		border: 0;
		font-weight: 700;
		height: 26px;
		margin: 0;
		transition: background-color 5000s ease-in-out 0s !important;
		width: 28px;
	}

	.btn-wrap {
		.btn {
			&::before {
				content: '';
				display: inline-block;
				background-image: url(${TickIcon});
				background-repeat: no-repeat;
				height: 12px;
				width: 15px;
				margin-right: 16px;
			}
		}
	}
	ul {
		margin-left: 0;
		list-style: none;
		padding-left: 0;
	}

	ul > li {
		display: flex;
		align-items: baseline;
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		margin-bottom: 4px;
		padding: 0 0 2px 30px;
	}

	/* Prevent nested li's from getting messed up */
	ul > li::before {
		content: '- ';
		margin-left: -15px;
	}
	.task-block {
		align-items: unset;
		margin-bottom: 80px;
		position: relative;
		img {
			margin-top: 10px;
		}
		.scrollarea {
			height: initial;
			min-height: 130px;
			max-height: 200px;
			width: 100%;
		}
		.lastweek-tasks-block {
			display: ${props => (props.showMenu ? 'block' : 'none')};
			width: 282px;
			height: 250px;
			border-radius: 4px;
			box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.5);
			background-color: #d8d8d8;
			position: absolute;
			left: 100%;
			padding: 14px 19px;
			overflow: scroll;
			h3 {
				color: #333333;
				font-size: 12px;
				font-weight: 700;
				margin: 0;
				margin-bottom: 6px;
				small {
					color: #333333;
					font-size: 12px;
					font-weight: 500;
					font-style: italic;
				}
			}
			ul {
				padding-inline-start: 19px;
				li {
					color: #333333;
					font-size: 14px;
					font-weight: 500;
					line-height: 1.86;
				}
			}
			ul {
				list-style: none;
				padding-left: 0;
			}

			ul > li {
				/* margin-left: 15px; */
				align-items: unset;
			}

			/* Prevent nested li's from getting messed up */
			ul > li::before {
				content: '- ';
				margin-left: -28px;
				/* margin-top: 6px; */
			}
		}
		.task-list {
			background-color: rgba(255, 255, 255, 0.07);
			border-radius: 4px;
			box-shadow: 0 2px -4px 0 rgba(0, 0, 0, 0.5);
			min-height: 130px;
			padding: 5px 0;
			width: 100%;

			.help-text {
				opacity: 0.5;

				font-size: 10px;

				color: #ffffff;
				margin-left: 35px;
			}

			.task-input-list {
				.input-block {
					display: flex;
					width: 100%;
					align-items:center;
					.input-task {
						width: 100%;
						padding: 6px 10px;
						background-color: transparent;
						border: 0;
						font-size: 14px;
						margin-right: 16px;
						height: auto;
						resize: none;
						&.error{
							border: 1px solid #f75a51;
              background: rgba(221, 59, 43, 0.4);
						}
					}
					.hrs-block {
						background-color: #2a2e38;
						border: none;
						border-radius: 2px;
						display: flex;
						height: 37px;
						align-items: center;
						outline: none;
						width: 64px;
						opacity:0.7;
						&.error{
							border: 1px solid #f75a51;
              background: rgba(221, 59, 43, 0.4);
						}
						.input-hrs {
							background-color: transparent;
							width: 32px;
							border: none;
							padding-left: 10px;
							padding-right: 4px;
							padding-bottom: 3px;
							font-size: 14px;
							text-align: right;
							color: #ffffff;
						}
						span {
							opacity: 0.7;
							font-size: 12px;
							text-align: right;
							color: #ffffff;
							padding-right: 10px;
						}
					}
				}
			}
		}
	}
	.bm-item-list .flex textarea {
		background-color: rgba(255, 255, 255, 0.07);
		border: 0;
		border-radius: 4px;
		box-shadow: 0 2px -4px 0 rgba(0, 0, 0, 0.5);
		color: #fff;
		height: 136px;
		opacity: 0.7;
		padding: 14px 20px;
	}
`;

const RemoveTask = styled.a`
	cursor: pointer;
	margin: 8px;
	text-align: center;
	transition: opacity 0.2s ease-in-out;
	&:hover,
	&:focus {
		color: #fff;
		text-decoration: none;
	}
`;
