import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import AnnualIcon from '../../images/ic-annual-leave.svg';
import SickIcon from '../../images/ic-sick-leave.svg';
import MenstrualIcon from '../../images/menstrualleave.svg';
import MaternityIcon from '../../images/maternity-paternity.svg';
import WorkFromHomeIcon from '../../images/ic-work-home.svg';
import OfficialEventIcon from '../../images/podium.svg';
import OfficialTripIcon from '../../images/ic-official-trip.svg';

class WeekStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let data = {};
        if (nextProps.filters.todayStatus !== prevState.todayStatus) {
            data.todayStatus = nextProps.filters.todayStatus
        }
        if (nextProps.filters.weekStatus !== prevState.weekStatus) {
            data.weekStatus = nextProps.filters.weekStatus
        }
        return data;
    }

    getLeaveCount = () => {

    }

    render() {
        const { peopleFullInfo } = this.props;
        
        let sick_leave_c = 0, sick_leave_tc = 0,
            menstrual_leave_c = 0, menstrual_leave_tc = 0,
            annual_leave_c = 0, annual_leave_tc = 0,
            paternity_leave_c = 0, paternity_leave_tc = 0,
            wfh_c = 0, wfh_tc=0,
            official_event_c = 0, official_event_tc = 0,
            official_trip_c = 0, official_trip_tc = 0,
            loaded = false;

        let annual_leave_values = ["Annual Leave", "First Half Annual Leave", "Second Half Annual Leave"];
        let sick_leave_values = ["Sick Leave", "First Half Sick Leave", "Second Half Sick Leave"];
        let menstrual_leave_values = ["Menstrual Leave", "First Half Menstrual Leave", "Second Half Menstrual Leave"];
        let paternity_leave_values = ["Paternity Leave", "Maternity Leave", "First Half Paternity Leave", "Second Half Paternity Leave", "First Half Maternity Leave", "Second Half Maternity Leave"];
        
        peopleFullInfo && peopleFullInfo.length == 0? loaded = true: loaded = false;

        peopleFullInfo && peopleFullInfo.map(p => {
            loaded = true
            if (annual_leave_values.indexOf(p.leave) !== -1){
                annual_leave_c += 1;
            }

            if (sick_leave_values.indexOf(p.leave) !== -1){
                sick_leave_c += 1;
            }

            if (menstrual_leave_values.indexOf(p.leave) !== -1){
                menstrual_leave_c += 1;
            }

            if (paternity_leave_values.indexOf(p.leave) !== -1){
                paternity_leave_c += 1;
            }
            
            if (p.isWorkingFromHome) {
                wfh_c += 1;
            }
            if (p.officialTrip === "officialTrip") {
                official_trip_c += 1;
            }
            if (p.officialTrip === "officialEvent") {
                official_event_c += 1;
            }
            if(p.weekLeaves) {
                sick_leave_tc += p.weekLeaves.sick_leave.length;
                menstrual_leave_tc += p.weekLeaves.menstrual_leave.length;
                annual_leave_tc += p.weekLeaves.annual_leave.length;
                paternity_leave_tc += p.weekLeaves.paternity_leave.length;
            }

            if(p.weekOfficialTrips) {
                official_event_tc += p.weekOfficialTrips.official_event.length;
                official_trip_tc += p.weekOfficialTrips.official_trip.length;
            }

            if(p.weekWorkingFromHome) {
                wfh_tc += p.weekWorkingFromHome.length
            }

        })

        return (
            <WeekLeaveStatusWrapper>
                <TodayOverViewWrapper>
                    <p> Today's Overview </p>
                    <div className="status-container">
                        {annual_leave_c > 0?
                            <StatusDiv icon={AnnualIcon} alt="Annual Leave" count={annual_leave_c} handleClick={this.props.setTodayFilter} status="todayStatus" currentState={this.state} />: ''
                        }
                        {sick_leave_c > 0?
                            <StatusDiv icon={SickIcon} alt="Sick Leave" count= {sick_leave_c} handleClick={this.props.setTodayFilter} status="todayStatus" currentState={this.state} /> :''
                        }
                        {menstrual_leave_c > 0?
                            <StatusDiv icon={MenstrualIcon} alt="Sick Leave" count= {menstrual_leave_c} handleClick={this.props.setTodayFilter} status="todayStatus" currentState={this.state} /> :''
                        }
                        {paternity_leave_c > 0?
                            <StatusDiv icon={MaternityIcon} alt="Paternity/Maternity Leave" count= {paternity_leave_c} handleClick={this.props.setTodayFilter} status="todayStatus" currentState={this.state} /> :''
                        }
                        {wfh_c > 0?
                            <StatusDiv icon={WorkFromHomeIcon} alt="Work From Home" count= {wfh_c} handleClick={this.props.setTodayFilter} status="todayStatus" currentState={this.state} /> :''
                        }
                        {official_trip_c > 0?
                            <StatusDiv icon={OfficialTripIcon} alt="Official Trip" count= {official_trip_c} handleClick={this.props.setTodayFilter} status="todayStatus" currentState={this.state} /> :''
                        }
                        {official_event_c > 0?
                            <StatusDiv icon={OfficialEventIcon} alt="Official Event" count= {official_event_c} handleClick={this.props.setTodayFilter} status="todayStatus" currentState={this.state} /> :''
                        }
                        {(annual_leave_c + sick_leave_c + menstrual_leave_c + paternity_leave_c + wfh_c + official_trip_c + official_event_c) === 0 && loaded?
                            <p className="no-leaves"> No one is on any leave / official trip / remote work today. </p>: ''
                        }
                    </div>
                </TodayOverViewWrapper>
                <ThisWeekOverviewWrapper>
                    <p className="week-status-head"> This week's overview </p>
                    <div className="status-container week-status-container">
                        {official_trip_tc > 0?
                            <StatusDiv icon={OfficialTripIcon} alt="Official Trip" count= {official_trip_tc} handleClick={this.props.setWeekFilter} status="weekStatus" currentState={this.state} /> :''
                        }
                        {official_event_tc > 0?
                            <StatusDiv icon={OfficialEventIcon} alt="Official Event" count= {official_event_tc} handleClick={this.props.setWeekFilter} status="weekStatus" currentState={this.state} /> :''
                        }
                        {wfh_tc > 0?
                            <StatusDiv icon={WorkFromHomeIcon} alt="Work From Home" count= {wfh_tc} handleClick={this.props.setWeekFilter} status="weekStatus" currentState={this.state} /> :''
                        }
                        {paternity_leave_tc > 0?
                            <StatusDiv icon={MaternityIcon} alt="Paternity/Maternity Leave" count= {paternity_leave_tc} handleClick={this.props.setWeekFilter} status="weekStatus" currentState={this.state} /> :''
                        }
                        {sick_leave_tc > 0?
                            <StatusDiv icon={SickIcon} alt="Sick Leave" count= {sick_leave_tc} handleClick={this.props.setWeekFilter} status="weekStatus" currentState={this.state} /> :''
                        }
                        {menstrual_leave_tc > 0?
                            <StatusDiv icon={MenstrualIcon} alt="Menstrual Leave" count= {menstrual_leave_tc} handleClick={this.props.setWeekFilter} status="weekStatus" currentState={this.state} /> :''
                        }
                        {annual_leave_tc > 0?
                            <StatusDiv icon={AnnualIcon} alt="Annual Leave" count={annual_leave_tc} handleClick={this.props.setWeekFilter} status="weekStatus" currentState={this.state} />: ''
                        }
                        {(annual_leave_tc + sick_leave_tc + paternity_leave_tc + wfh_tc + official_trip_tc + official_event_tc) === 0 && loaded?
                            <p className="no-leaves"> No one is on any leave / official trip / remote work this week. </p>: ''
                        }
                    </div>
                </ThisWeekOverviewWrapper>
            </WeekLeaveStatusWrapper>
        );
    }
}

const StatusDiv = (props) => {
    const { currentState, status, alt } = props;
	return(
        <div className={
            currentState.todayStatus && status === 'todayStatus'? 
                currentState.todayStatus === alt?
                'status-box active':
                'status-box':
                currentState.weekStatus && status === 'weekStatus'?
                currentState.weekStatus === alt?
                'status-box active':
                'status-box':
                'status-box'
        }
            onClick={() => props.handleClick(props.alt)}
        >
            <span>
                <img
                    src={props.icon}
                    className="status-icon"
                    alt={props.alt}
                />
            </span>
            <span className="people-on-leave"> {props.count} </span>
        </div>
	)
}

const WeekLeaveStatusWrapper = styled.div`
    padding: 20px;
    margin: 20px 0px;
    border: 1px solid rgba(151, 151, 151, 0.2);
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    p{
        font-size: 14px;
        color: #959595;
        text-transform: uppercase;
    }
    .week-status-head{
        display: flex;
        flex-direction: row-reverse;
    }
    .status-container{
        display: flex;
        padding-top: 10px;
        .status-box{
            background: #3e4352;
            padding: 4px 8px;
            border: 1px solid #3e4352;
            border-radius: 12px;
            margin: 0 4px;
            display: flex;
            align-items: center;
            cursor: pointer;
            &:hover{
                border-color: #fff;
            }
            span{
                display: flex;
                .status-icon{
                    height: 18px;
                    width: 18px;
                    margin-right: 4px;
                }
            }
            .people-on-leave{
                font-size: 10px;
            }
        }
        .active {
            background: #fff;
            color: #3e4352;
        }
        &> :first-child{
            margin-left: 0px;
        }
        .no-leaves{
            text-transform: none;
            font-style: italic;
            font-size: 10px;
            line-height: 16px;
            margin-top: 3px;
        }
    }
    .week-status-container{
        flex-direction: row-reverse;
        &> :first-child{
            margin-right: 0px;
            margin-left: 4px;
        }
    }
`;

const TodayOverViewWrapper = styled.div`
  p {
    margin: 0px;
  }   
`;

const ThisWeekOverviewWrapper = styled.div`
  p {
    margin: 0px;
  }       
`;

WeekStatus.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(WeekStatus);
