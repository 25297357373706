import React, {Component} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {toastr} from 'react-redux-toastr';
import {connect} from 'react-redux';
import queryString from 'query-string';

import * as LeaveAction from '../../actions/leave';
import LeaveService from '../../services/LeaveApi';
import * as HolidayAction from '../../actions/holidays';
import HolidayService from '../../services/HolidayApi';
import Calendar from '../../components/calendar/Index';
import AnnualIcon from '../../images/ic-annual-leave.svg';
import SickIcon from '../../images/ic-sick-leave.svg';
import MenstrualIcon from '../../images/menstrualleave.svg';
import MaternityIcon from '../../images/maternity-paternity.svg';
import WeekLists from '../../components/layout/WeekList';
import Background from '../../components/Background';
import TextareaAutosize from 'react-autosize-textarea';

import {ANNUAL_LEAVE} from "../../helpers/constant/types";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leaveTypeId: 0,
            from: moment().format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD'),
            days: 0,
            halfDay: false,
            halfDayPeriod: 'first half',
            holidays: [],
            remarks: '',
            leaveList: [],
            userLeaves: [],
            leaveError: false,
            months: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec'
            ],
            type: ANNUAL_LEAVE
        };
    }

    componentDidMount() {
        const {user} = this.state.auth;

        this.props.fetchHolidayPlans();
        this.props.fetchLeaveTypes(user.id, user.supervisor);
    }

    componentDidUpdate() {
        const keyType = queryString.parse(this.props.location.search);

        if (keyType && keyType.type && keyType.type !== this.state.type) {
            this.setState({type: keyType.type})
        }

        if (this.state.leaveTypes) {

            let leave = this.state.leaveTypes.leaveTypes.find(l => l.leave_setting.leaveType === this.state.type)

            if (leave && leave.id !== this.state.leaveTypeId) {
                this.setState({leaveTypeId: leave.id})
            }
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let data = {};

        if (nextProps.auth !== prevState.auth) {
            data.auth = nextProps.auth;
        }

        if (nextProps.userLeaves !== prevState.userLeaves) {
            data.userLeaves = nextProps.userLeaves;
        }

        if (nextProps.holidays !== prevState.holidays) {
            data.holidays = nextProps.holidays;
        }

        if (
            Object.keys(nextProps.leaveTypes).length &&
            nextProps.leaveTypes !== prevState.leaveTypes
        ) {
            data.leaveTypes = nextProps.leaveTypes;
            // data.leaveTypeId = nextProps.leaveTypes.leaveTypes[0].id;
            data.supervisor = nextProps.leaveTypes.supervisor;
        }

        data.user = nextProps.auth.user;

        return data;
    }

    handleSave = e => {
        e.preventDefault();
        const {
            auth,
            leaveTypes,
            leaveList,
            supervisor,
            leaveTypeId,
            remarks,
            months
        } = this.state;

        let canSubmit = true;
        if (leaveList.length < 1) {
            canSubmit = false;
            this.setState({leaveError: true});
        }
        if (leaveTypes.leaveTypes.length === 0) {
            canSubmit = false;
            this.setState({leaveError: true, leaveList: []});
            toastr.error('', 'Please contact to your supervisor to assign your leaves');
        }
        if (canSubmit) {
            const dates = [];
            const formData = [];

            leaveList.map(l => {
                let date = moment();
                let year = l.year;
                let month = months.indexOf(l.month) + 1;
                let day = l.day;
                let leave = moment(`${year}-${month}-${day}`).format('YYYY-MM-DD'); // <--- IOS ko problem
                let days = l.period === 'full' ? 1 : 0.5;
                let period = l.period !== 'full' ? l.period : null;

                let d = moment(Date.parse(leave)).format('MMM DD, YYYY');
                dates.push(`${d} ${l.period !== 'full' ? '(' + l.period + ')' : ''}`);

                formData.push({
                    userId: auth.user.id,
                    leaveTypeId: leaveTypeId,
                    from: leave,
                    to: leave,
                    days: days,
                    halfDayPeriod: period,
                    remarks: remarks,
                    approvedBy: supervisor.id
                });
            });

            let type = leaveTypes.leaveTypes.find(l => l.id === leaveTypeId);

            let data = {
                from: auth.user.name,
                fromEmail: auth.user.email,
                to: supervisor.firstName,
                toEmail: supervisor.email,
                body: dates,
                type: type.leave_setting.leaveType || 'Annual Leave',
                remarks: remarks || ''
            };

			LeaveService.submitLeave({ formData, data })
				.then(response => {
					if (response.success) {
						toastr.success(
							'',
							`Leave Application has been submitted successfully`
						);
						this.props.history.push('/profile?key=3');
					} else {
						toastr.error('', response.error.message);
					}
				})
				.catch(err => toastr.error('', err.error.message));
		}
	};

    handleDayPeriod = (month, day, period, year) => {
        const {leaveList} = this.state;
        let days = leaveList.length;
        let list = [];

        leaveList.map((l, i) => {
            let m = l.month;
            let d = l.day;
            let p = l.period;
            let y = l.year;
            l.cell.classList.add('bg-leave');
            l.cell.classList.remove('bg-half-leave');
            l.cell.classList.remove('bg-half-leave-second');
            if (l.day !== day || l.month !== month || l.year !== year) {
                if (l.period !== 'full') {
                    days = days - 0.5;
                    l.cell.classList.remove('bg-leave');
                    l.cell.classList.add(
                        l.period === 'firstHalf' ? 'bg-half-leave' : 'bg-half-leave-second'
                    );
                }
            }
            if (l.day === day && l.month === month || l.year !== year) {
                if (period !== 'full') {
                    days = days - 0.5;
                    l.cell.classList.remove('bg-leave');
                    l.cell.classList.add(
                        period === 'firstHalf' ? 'bg-half-leave' : 'bg-half-leave-second'
                    );
                }
                p = period;
            }

            list.push({month: m, day: parseInt(d), period: p, cell: l.cell, year: y});
        });

        this.setState({leaveList: list, days});
    };

    calculateDays = leaves => {
        let days = 0;
        leaves.forEach(l => {
            if (l.period === 'full') {
                days = days + 1;
            } else {
                days = days + 0.5;
            }
        });
        return days;
    };

    handleOnClick = (month, day, cell, year) => {
        const {leaveList} = this.state;
        let append = true,
            index = 0;
        leaveList.map((l, i) => {
            if (l.day === parseInt(day.nodeValue) && l.month === month && l.year === year) {
                append = false;
                index = i;
            }
        });
        let days = this.calculateDays(leaveList);
        if (append) {
            cell.classList.add('bg-leave');
            leaveList.push({
                month: month,
                year,
                day: parseInt(day.nodeValue),
                period: 'full',
                cell: cell
            });
            days = days + 1;

			this.setState({ leaveList, days: days, leaveError: false });
		} else {
			cell.classList.remove('bg-leave');
			cell.classList.remove('bg-half-leave');
			cell.classList.remove('bg-half-leave-second');
			leaveList.splice(index, 1);
			days = this.calculateDays(leaveList);
			this.setState({ leaveList, days: days, leaveError: false });
		}
	};

	handleTabSelect = week => {
		this.setState({ selectedWeek: week, isActive: true });
		this.props.history.push(`/dashboard/${week.weekNo}/${week.year}`);
	};

	handleLeaveTypeSelect = l => {
    this.setState({ leaveTypeId: l.id})
    this.props.history.push(`/leave?type=${l.leave_setting.leaveType}`)
	}

	render() {
		const {
			leaveTypeId,
			days,
			remarks,
			leaveList,
			leaveTypes,
			userLeaves,
			supervisor,
			holidays,
			leaveError,
			type
		} = this.state;


		return (
			<LeaveWrapper>
				<Background />
				<WeekLists
					selectedKey={this.state.key}
					handleTabSelect={this.handleTabSelect}
					selectedWeek={this.state.selectedWeek}
					isActive={this.state.isActive}
				/>
				<LeaveApplication>
					<h1>Leave Application</h1>
					<form>
						<div className="row">
							<div className="form-group col-md-9">
								<label htmlFor="exampleInputEmail1">
									What type of Leave do you want?
								</label>
								<div className="row">
									{leaveTypes &&
										Object.keys(leaveTypes).length &&
										leaveTypes.leaveTypes.map(l => (
											<button
												key={l.id}
												type="button"
												className={type === l.leave_setting.leaveType ? 'btn' +
													' btn-leave-type' +
													' selected-leave-type' : 'btn' +
													' btn-leave-type'}
												onClick={(e) => this.handleLeaveTypeSelect(l)}
											>
												<span
													className={l.leave_setting.leaveType
                            .replace('Leave', '')
                            .trim()
                            .toLowerCase()}
												>
													{l.leave_setting.leaveType
                            .replace('Leave', '')
                            .trim()}
												</span>
											</button>
										))}
								</div>
							</div>
							<div className="form-group col-md-3 leave-days-block">
								<label className="leave-days" htmlFor="exampleInputEmail1">
									{days}
								</label>
								<label
									className="leave-days-label"
									htmlFor="exampleInputEmail1"
								>
									{days <= 1 ? 'Day' : 'Days'}
								</label>
							</div>
						</div>
						<div className="row">
							<div className="form-group col-md-9">
								<label htmlFor="exampleInputEmail1">
									When do you want the leave ?
								</label>
								<Calendar
									leaveError={leaveError}
									leaveList={leaveList}
									leaveTags={true}
									clickable={true}
									userLeaves={userLeaves}
									holidays={holidays}
									handleOnClick={this.handleOnClick}
									handleDayPeriod={this.handleDayPeriod}
									type={'leave'}
									user={this.props.auth.user}
								/>
							</div>
							<div className="form-group col-md-3">
								<div>
									{/* <div className="supervisors">
										<label htmlFor="exampleInputEmail1">
											To Be Approved By
										</label>
										<div className="approver-wrapper">
											{approver && (
												<React.Fragment>
													<div className="approver">
														<img src={approver.image} alt="approver" />
													</div>
													<label htmlFor="approverName">{`${approver.firstName} ${approver.lastName}`}</label>
												</React.Fragment>
											)}
										</div>
									</div> */}
									<div className="supervisors">
										<label htmlFor="exampleInputEmail1">
											To Be Approved By
										</label>
										<div className="approver-wrapper">
											{supervisor && (
												<React.Fragment>
													<div className="approver">
														<img src={supervisor.image} alt="supervisor" />
													</div>
													<label htmlFor="approverName">
														{`${supervisor.firstName} ${supervisor.lastName}`}
													</label>
												</React.Fragment>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="form-group col-md-6">
								<label htmlFor="exampleInputEmail1">
									Any remarks or reason you'd like to add?
								</label>
								<TextareaAutosize
									style={{ minHeight: 38, maxHeight: 100 }}
									className="leave-remarks"
									onChange={e => this.setState({ remarks: e.target.value })}
									value={remarks}
									placeholder="Type your remarks or reasons here …"
								/>
							</div>
						</div>
						<div className="row">
							<div className="form-group col-md-1">
								<button
									type="submit"
									onClick={this.handleSave}
									className="btn btn-primary btn-apply"
								>
									APPLY LEAVE
								</button>
							</div>
						</div>
					</form>
				</LeaveApplication>
			</LeaveWrapper>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	leaveTypes: state.leave.leaveTypes,
	userLeaves: state.leave.userLeaves,
	holidays: state.holiday.holidays
});

const mapDispatchToProps = dispatch => ({
	fetchLeaveTypes: (userId, supervisorId) => {
		dispatch(LeaveAction.fetching());
		LeaveService.getLeaveTypes(userId, supervisorId)
			.then(response => {
				if (response.success) {
					dispatch(LeaveAction.fetched(response));
				} else {
					dispatch(LeaveAction.error('error'));
				}
			})
			.catch(err => {
				dispatch(LeaveAction.error(err.message));
			});
	},
	fetchHolidayPlans: () => {
		dispatch(HolidayAction.fetching());
		HolidayService.holidayPlans()
			.then(response => {
				if (response.success) {
					dispatch(HolidayAction.fetched(response));
				} else {
					dispatch(HolidayAction.error('error'));
				}
			})
			.catch(err => {
				dispatch(HolidayAction.error(err.message));
			});
	}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Index);

const LeaveWrapper = styled.div``;
const LeaveApplication = styled.div`
	margin: 0 auto;
	padding: 150px 0 40px;
	position: relative;
	width: 924px;
	h1 {
		color: #fff;
		display: flex;
		font-size: 72px;
		font-weight: 300;
		margin-bottom: 40px;
	}
	.row {
		display: flex;
		justify-content: space-between;
		margin: 0 -9px;
		.form-group > .row {
			margin: 0;
			justify-content: flex-start;
		}
		&:first-child,
		&:nth-child(2) {
			> .form-group:last-child {
				padding-right: 0;
				width: 160px;
			}
		}
		&:before {
			display: none;
		}
		/* .leave-days-block {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			width: 150px;
		} */
		.supervisors {
			margin: 37px 0 20px;
			.approver-wrapper {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				width: 200px;
				> label {
					color: rgba(255, 255, 255, 0.7);
					font-size: 12px;
					text-transform: none;
					margin-bottom: 0;
				}
				.approver {
					align-items: center;
					height: 32px;
					width: 32px;
					background: #dad9d9;
					border-radius: 50%;
					display: flex;
					margin-right: 10px;
					overflow: hidden;
					img {
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}
			}
		}
		label {
			text-transform: uppercase;
			font-weight: 500;
			font-size: 14px;
			margin-bottom: 16px;
			&.leave-days {
				display: block;
				font-size: 72px;
				font-weight: 700;
				line-height: 64px;
				margin-bottom: 0;
				text-align: center;
				width: 85px;
			}
			&.leave-days-label {
				color: rgba(255, 255, 255, 0.5);
				font-size: 18px;
				display: block;
				text-align: center;
				width: 85px;
			}
		}
		textarea {
			display: block;
			width: 342px;
			background: transparent;
			border: none;
			border-bottom: 1px solid rgba(216, 216, 216, 0.3);
			line-height: 20px;
			padding-bottom: 10px;
			margin: 12px 0 50px;
			padding: 0;
		}
		.form-check {
			margin-top: 28px;
			label {
				margin-left: 10px;
			}
		}
		.btn-leave-type {
			height: 42px;
			border-radius: 4px;
			border: solid 1px rgba(151, 151, 151, 0.25);
			background-color: transparent;
			margin-right: 10px;
			position: relative;
			padding: 7px 18px 6px 10px;
			&:hover {
				color: #fff;
			}

			&.selected-leave-type {
				border: solid 1px #80d968;
				&:after {
					content: '';
					position: absolute; /* Sit on top of the page content */
					/* Hidden by default */
					width: 100%; /* Full width (cover the whole page) */
					height: 100%; /* Full height (cover the whole page) */
					border: 1px solid #979797;
					border-radius: 4px;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					border: none;
					color: #fff;
					background-color: transparent;
					z-index: 2;
				}
				span {
					color: #fff;
					&:before {
						opacity: 1;
					}
				}
			}

			span {
				color: rgba(255, 255, 255, 0.5);
				font-size: 16px;
				display: inline-flex;
				align-items: center;
				&:before {
					content: '';
					display: inline;
					height: 26px;
					width: 26px;
					background-repeat: no-repeat;
					object-fit: cover;
					margin-right: 4px;
					opacity: 0.5;
				}
				&.annual {
					&:before {
						background-image: url(${AnnualIcon});
					}
				}
				&.sick {
					&:before {
						background-image: url(${SickIcon});
					}
				}
				&.paternity,
				&.maternity {
					&:before {
						background-image: url(${MaternityIcon});
					}
				}

                &.menstrual {
                    &:before {
                        background-image: url(${MenstrualIcon});
                    }
                }
			}
		}
		.btn-apply {
			width: 294px;
			height: 59px;
			border-radius: 4px;
			font-size: 16px;
		}
	}
`;
