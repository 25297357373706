const initialState = {
	fetching: false,
	fetched: false,
	error: null,
	leaveTypes: {},
	userLeaveFetching: false,
	userLeaveFetched: false,
	userLeaveError: null,
	userLeaves: [],
	todayLeavesFetching: false,
	todayLeavesFetched: false,
	todayLeaves: [],
	todayLeaveError: null,
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case 'LEAVE_TYPE_FETCHING':
			state = {
				...state,
				...{ error: null, fetching: true, fetched: false }
			};
			break;
		case 'LEAVE_TYPE_FETCHED':
			state = {
				...state,
				...{
					error: null,
					leaveTypes: payload.data,
					fetching: false,
					fetched: true
				}
			};
			break;
		case 'LEAVE_TYPE_ERROR':
			state = {
				...state,
				...{
					error: payload,
					fetching: false,
					fetched: true
				}
			};
			break;
		case 'USER_LEAVE_FETCHING':
			state = {
				...state,
				...{
					userLeaveError: null,
					userLeaveFetching: true,
					userLeaveFetched: false
				}
			};
			break;
		case 'USER_LEAVE_FETCHED':
			state = {
				...state,
				...{
					userLeaveError: null,
					userLeaves: payload.data,
					userLeaveFetching: false,
					userLeaveFetched: true
				}
			};
			break;
		case 'USER_LEAVE_ERROR':
			state = {
				...state,
				...{
					userLeaveError: payload,
					userLeaveFetching: false,
					userLeaveFetched: true
				}
			};
			break;

		case 'TODAY_LEAVE_FETCHING':
			return {
				...state,
				todayLeaveError: null,
				todayLeavesFetching: true,
				todayLeavesFetched: false
			};

		case 'TODAY_LEAVE_FETCHED':
			return {
				...state,
				todayLeaves: payload.data.todayLeaves,
				todayLeaveError: null,
				todayLeavesFetching: false,
				todayLeavesFetched: true
			};

		case 'TODAY_LEAVE_ERROR':
			state = {
				...state,
				...{
					todayLeaveError: payload,
					todayLeavesFetching: false,
					todayLeavesFetched: true
				}
			};
			break;

		case 'TODAY_WFH_FETCHING':
			return {
				...state,
				todayWFHError: null,
				todayWFHFetching: true,
				todayWFHFetched: false
			};

		case 'TODAY_WFH_FETCHED':
			return {
				...state,
				todayWFH: payload.data.todayWFH,
				todayWFHError: null,
				todayWFHFetching: false,
				todayWFHFetched: true
			};

		case 'TODAY_WFH_ERROR':
			state = {
				...state,
				...{
					todayWFHError: payload,
					todayWFHFetching: false,
					todayWFHFetched: true
				}
			};
			break;



		default:
			break;
	}
	return state;
};
export default reducer;
