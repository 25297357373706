import React from 'react';

const Background = () => {
	return (
		<div>
			<div className="blob blob-left">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="219"
					height="219"
					viewBox="0 0 219 219"
					xmlns="http://www.w3.org/2000/svg"
					xmlns="http://www.w3.org/1999/xlink"
					style={{ opacity: 0.4 }}
				>
					<defs>
						<linearGradient
							id="blob-1"
							x1="92.345%"
							x2="1.568%"
							y1="4.569%"
							y2="100%"
						>
							<stop offset="0%" stopColor="#FF6200" />
							<stop offset="100%" stopColor="#DB2B2B" />
						</linearGradient>
					</defs>
					<path
						fill="url(#blob-1)"
						fillRule="evenodd"
						d="M84.432 37h82.136c16.493 0 22.474 1.717 28.504 4.942 6.03 3.225 10.761 7.957 13.986 13.986 3.225 6.03 4.942 12.01 4.942 28.504v82.136c0 16.493-1.717 22.474-4.942 28.504-3.225 6.03-7.957 10.761-13.986 13.986-6.03 3.225-12.01 4.942-28.504 4.942H84.432c-16.493 0-22.474-1.717-28.504-4.942-6.03-3.225-10.761-7.957-13.986-13.986-3.225-6.03-4.942-12.01-4.942-28.504V84.432c0-16.493 1.717-22.474 4.942-28.504 3.225-6.03 7.957-10.761 13.986-13.986C61.958 38.717 67.938 37 84.432 37z"
						transform="rotate(-40 95.52 139.48)"
					/>
				</svg>
			</div>
			<div className="blob blob-right">
				<svg
					width="329px"
					height="329px"
					viewBox="0 0 329 329"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					xmlns="http://www.w3.org/1999/xlink"
					style={{ opacity: 0.4 }}
				>
					<defs>
						<linearGradient
							x1="92.345%"
							y1="4.569%"
							x2="1.568%"
							y2="100%"
							id="linearGradient-1"
						>
							<stop stopColor="#FF6200" offset="0%"></stop>
							<stop stopColor="#DB2B2B" offset="100%"></stop>
						</linearGradient>
					</defs>
					<g
						id="Page-1"
						stroke="none"
						strokeWidth="1"
						fill="none"
						fillRule="evenodd"
					>
						<g
							id="ic-blob"
							transform="translate(-24.000000, -24.000000)"
							fill="url(#linearGradient-1)"
						>
							<path
								d="M126.815953,55.5735943 L250.183571,55.5735943 C274.955925,55.5735943 283.93934,58.1525146 292.996352,62.9964389 C302.053364,67.8403632 309.159288,74.9477895 314.003212,84.0032994 C318.847137,93.0603114 321.426057,102.042224 321.426057,126.81608 L321.426057,250.183698 C321.426057,274.956052 318.847137,283.939467 314.003212,292.996479 C309.159288,302.053491 302.051862,309.159415 292.996352,314.003339 C283.93934,318.847264 274.957427,321.426184 250.183571,321.426184 L126.815953,321.426184 C102.043599,321.426184 93.0601844,318.847264 84.0031725,314.003339 C74.9461605,309.159415 67.8402362,302.051989 62.9963119,292.996479 C58.1523876,283.939467 55.5734673,274.957554 55.5734673,250.183698 L55.5734673,126.81608 C55.5734673,102.043726 58.1523876,93.0603114 62.9963119,84.0032994 C67.8402362,74.9462875 74.9476625,67.8403632 84.0031725,62.9964389 C93.0601844,58.1525146 102.042097,55.5735943 126.815953,55.5735943 Z"
								id="Path"
								transform="translate(188.499762, 188.499889) rotate(-40.000000) translate(-188.499762, -188.499889) "
							></path>
						</g>
					</g>
				</svg>
			</div>
		</div >
	);
};

export default Background;
