import Api from './api';
import ApiUrlConstant from '../helpers/constant/api-url';

class AdminApi {
    async getPendingAdminLeaves(id, role) {
		try {
			let pendingAdminLeaves = await Api.get(
				await ApiUrlConstant.ADMIN_PENDING_LEAVES({
                    id,
                    role
                })
			);
			return pendingAdminLeaves.body;
		} catch (error) {
			return error;
		}
	}
}

export default new AdminApi();