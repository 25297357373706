import Api from './api';
import ApiUrlConstant from '../helpers/constant/api-url';
import store from '../store';
import { setCurrentUser } from './Auth';
import jwt_decode from 'jwt-decode';

class LeaveApi {
	async fetchUserProfile(userId) {
		try {
			let userDetail = await Api.get(
				await ApiUrlConstant.USER_DETAIL({
					userId: userId
				})
			);
			return userDetail.body;
		} catch (error) {
			return error;
		}
	}
	async updateUserProfile(formData) {
		try {
			let userDetail = await Api.patch(
				await ApiUrlConstant.UPDATE_USER(),
				formData
			);
			let object = {
				token: userDetail.body.data.token,
				timestamp: new Date().getTime()
			};
			localStorage.setItem('sombarToken', JSON.stringify(object));
			const decoded = jwt_decode(object.token);
			store.dispatch(setCurrentUser(decoded));
			return userDetail.body;
		} catch (err) {
			return err;
		}
	}

	async updateUserProfileImage(formData) {
		try {
			let userDetail = await Api.patch(
				await ApiUrlConstant.UPDATE_USER_IMAGE(),
				formData
			);
			let object = {
				token: userDetail.body.data.token,
				timestamp: new Date().getTime()
			};
			localStorage.setItem('sombarToken', JSON.stringify(object));
			const decoded = jwt_decode(object.token);
			store.dispatch(setCurrentUser(decoded));
			return userDetail.body;
		} catch (err) {
			return err;
		}

	}
	async fetchSupervisorTeam(params){
		try {
			let userDetail = await Api.get(
				await ApiUrlConstant.SUPERVISOR_TEAM(params)
			);
			return userDetail.body;
		} catch (error) {
			return error;
		}
	}

	async fetchUserAttendance(weekId) {
		try {
			let userAttendance = await Api.get(
				await ApiUrlConstant.USER_ATTENDANCE({ weekId })
			);
			return userAttendance.body;
		} catch (err) {
			return err;
		}
	}

	async getPeopleInformation() {
		try {
			let userDetail = await Api.get(await ApiUrlConstant.PEOPLE_INFORMATION());
			return userDetail.body;
		} catch (err) {
			return err;
		}
	}

	async saveUserLoginInformation(){
		try{
			let userLogInInfo = await Api.post(await ApiUrlConstant.USER_LOGIN_INFORMATION());
			return userLogInInfo.body;
			}
		catch(err){
			return err;

		}
	}

	async listBirthdayUsers(){
		try{
			let userLogInInfo = await Api.get(await ApiUrlConstant.USER_BIRTHDAYS());
			return userLogInInfo.body;
			}
		catch(err){
			return err;

		}
	}
	
}

export default new LeaveApi();
