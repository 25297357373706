export function fetching() {
	return { type: 'HOLIDAYS_FETCHING' };
}

export function fetched(payload) {
	return { type: 'HOLIDAYS_FETCHED', payload };
}

export function error(payload) {
	return { type: 'HOLIDAYS_ERROR', payload };
}
