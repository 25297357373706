import Api from './api'
import ApiUrlConstant from '../helpers/constant/api-url';

class WeekApi {
  async userWeekDetail(params){
    try {
      let weeks = await Api.get(await ApiUrlConstant.USER_WEEK_DETAIL(params))
      return weeks.body;
    }
    catch (error) {
      return error;
    }
  }

  async listWeeks(params){
    try {
      let projects = await Api.get(await ApiUrlConstant.LIST_WEEKS(params))
      return projects.body;
    }
    catch (error) {
      return error;
    }
  }
  async listWeeksByYear(params){
    try {
      let projects = await Api.get(await ApiUrlConstant.LIST_WEEKS_BY_YEAR(params))
      return projects.body;
    }
    catch (error) {
      return error;
    }
  }

}

export default new WeekApi();
