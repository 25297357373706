export function fetching() {
	return { type: 'USER_FETCHING' };
}

export function fetched(payload) {
	return { type: 'USER_FETCHED', payload };
}

export function error(payload) {
	return { type: 'USER_ERROR', payload };
}

export function attendanceFetching() {
	return { type: 'USER_ATTENDANCE_FETCHING' };
}

export function attendanceFetched(payload) {
	return { type: 'USER_ATTENDANCE_FETCHED', payload };
}

export function attendanceError(payload) {
  return {type: 'USER_ATTENDANCE_ERROR', payload};
}
export function teamfetching(){
	return {type:'TEAM_USER_FETCHING'};
}
export function teamfetched(payload){
	return{type:'TEAM_USER_FETCHED',payload};
}
export function peopleFetching() {
	return { type: 'PEOPLE_FETCHING' };
}
export function peopleFetched(payload) {
	return { type: 'PEOPLE_FETCHED', payload };
}

export function userProfileFetching() {
	return { type: 'USER_PROFILE_FETCHING' };
}

export function userProfileFetched(payload) {
	return { type: 'USER_PROFILE_FETCHED', payload };
}



