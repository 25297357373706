const initialState = {
	fetching: true,
	fetched: false,
	error: null,
	holidays: []
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case 'HOLIDAYS_FETCHING':
			state = {
				...state,
				...{ error: null, fetching: true, fetched: false }
			};
			break;
		case 'HOLIDAYS_FETCHED':
			state = {
				...state,
				...{
					error: null,
					holidays: payload.data.plans,
					fetching: false,
					fetched: true
				}
			};
			break;
		case 'HOLIDAYS_ERROR':
			state = {
				...state,
				...{
					error: payload,
					fetching: false,
					fetched: true
				}
			};
			break;

		default:
			break;
	}
	return state;
};
export default reducer;
