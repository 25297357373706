const initialState = {
	fetching: true,
	fetched: false,
	teamUserfetching:true,
	teamUserfetched:false,
	error: null,
	userDetail: {},
	attendanceFetching: true,
	attendanceFetched: false,
	attendanceError: null,
	userAttendanceDetail: {},
	peopleDetail: {},
	peopleFetched: false,
	peopleFetching: true,
	teams:{},
  teamDetail:{},
  userProfileDetail:{},
  userProfileFetching:true,
  userProfileFetched:false
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case 'USER_FETCHING':
			state = {
				...state,
				...{ error: null, fetching: true, fetched: false }
			};
			break;
		case 'USER_FETCHED':
			state = {
				...state,
				...{
					error: null,
					userDetail: payload.data,
					fetching: false,
					fetched: true
				}
			};
			break;
		case 'PEOPLE_FETCHING':
			state = {
				...state,
				...{ error: null, peopleFetching: true, peopleFetched: false }
			};
			break;
		case 'PEOPLE_FETCHED':
			state = {
				...state,
				...{
					error: null,
					peopleDetail: payload.data,
					peopleFetching: false,
					peopleFetched: true
				}
			};
			break;
		case 'TEAM_USER_FETCHING':
			state = {
				...state,
				...{ error: null, teamuserfetching: true, teamuserfetched: false }
			};
			break;
		case 'TEAM_USER_FETCHED':
		state = {
			...state,
			...{
				error: null,
				teams: payload.data.users,
				teamDetail:payload.data.teams,
				teamUserFetching: false,
				teamUserFetched: true
			}
		};
		break;
		case 'USER_ERROR':
			state = {
				...state,
				...{
					error: payload,
					fetching: false,
					fetched: true
				}
			};
			break;
		case 'USER_ATTENDANCE_FETCHING':
			state = {
				...state,
				...{
					attendanceError: null,
					attendanceFetching: true,
					attendanceFetched: false
				}
			};
			break;
		case 'USER_ATTENDANCE_FETCHED':
			state = {
				...state,
				...{
					attendanceError: null,
					userAttendanceDetail: payload.data,
					attendanceFetching: false,
					attendanceFetched: true
				}
			};
			break;
		case 'USER_ATTENDANCE_ERROR':
			state = {
				...state,
				...{
					attendanceError: payload,
					attendanceFetching: false,
					attendanceFetched: true
				}
			};
			break;
		case 'USER_PROFILE_FETCHING':
				state = {
					...state,
					...{ error: null, userProfileFetching: true, userProfileFetched: false }
				};
				break;				
		case 'USER_PROFILE_FETCHED':
				state = {
					...state,
					...{
						error: null,
						userProfileDetail: payload.data,
						userProfileFetching: false,
						userProfileFetched: true
					}
				};
				break;
		default:
			break;
	}
	return state;
};
export default reducer;
