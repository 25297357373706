import React, { Component } from "react";
import { toastr } from "react-redux-toastr";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading
} from "react-accessible-accordion";
import styled from "styled-components";

import { Tab, Tabs } from "react-bootstrap";

import ProjectApi from "../../services/ProjectApi";

import * as ProjectAction from "../../actions/project";
import TaskList from "./taskList";

import Shimmer from '../../components/layout/ProjectShimmer';
import EmptyState from './EmptyState';

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addProjectSelected: {
        hour: "",
        remarks: "",
        projectId: "",
        weekId: "",
        userId: ""
      },
      edit: false,
      disabled: false,
      accordionOpen: false,
      accordionId: null,

      activeTab: this.props.auth.user.id,
      selectedProjectDeliverable: [],

      loading: false,
    };
  }

  /**
   * Navigating with keyword
   *
   * Add new task, Add new deliverable and Apply Leave
   * keycode 78 is for N button.
   * keycode 68 is for D button.
   * keycode 76 is for L button.
   */
  triggerWithKeydown = (event) => {
    switch (event.keyCode) {
      case 78:
        if (!this.props.isMenuOpenOrClosed && !this.props.deliverableOpenStatus) {
          this.props.addEstimate(this.state.projects);
          this.props.history.push('/dashboard/add-task');
        }
        break;

      case 68:

        if (!this.props.isMenuOpenOrClosed && !this.props.deliverableOpenStatus) {
          let dPL = this.state.userDeliverableProjects;
          if (dPL && dPL.length > 0 && !this.props.profile) {
            this.props.addDeliverable(
              this.state.dropdownProjectsDeliverable
            );
            this.props.history.push('/dashboard/add-deliverable');
          }
        }
        break;

      case 76:
        if (!this.props.isMenuOpenOrClosed && !this.props.deliverableOpenStatus) {
          this.props.history.push('/leave?type=Annual Leave');
        }
        break;

      default:
        break;
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.triggerWithKeydown);
    this.setState({ loading: true });
    this.listProject(this.props.val);
    this.props.userId ?
      this.props.listCurrentProjects(this.props.val.id, this.props.userId) :
      this.props.listUserProjects(this.props.val.id);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.triggerWithKeydown);
  }

  componentDidUpdate(prevProps) {
    if (this.props.val !== prevProps.val) {
      this.setState({
        accordionOpen: false,
        accordionId: null,
      })
      this.listProject(this.props.val);
      if (this.props.userId) {
        this.props.listCurrentProjects(this.props.val.id, this.props.userId);
        this.setState({ userId: this.props.userId })
      } else {
        this.props.listUserProjects(this.props.val.id);
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let data = {};

    if (
      nextProps.projectList &&
      nextProps.projectList !== prevState.projectList
    ) {
      data.projectList = nextProps.projectList;
    }
    if (
      nextProps.userId &&
      nextProps.userId !== prevState.userId
    ) {
      nextProps.listCurrentProjects(nextProps.val.id, nextProps.userId)
      data.userId = nextProps.userId
    }

    return data;
  }

  listProject(val) {
    if (val && val.weekNo && val.year) {
      ProjectApi.listProjects()
        .then((projects) => {
          this.setState({
            projects: projects.data,
            userDeliverableProjects: projects.projectsForDeliverable,
            dropdownProjectsDeliverable: projects.dropdownProjectsForDeliverable,
            loading: false,
          });

          if (this.props.match.path === '/dashboard/:add' || this.props.match.path === '/dashboard/:weekNo/:year/:add') {
            if (this.props.match.params.add === 'add-task') {
              this.props.addEstimate(projects.data)
            }
            if (this.props.match.params.add === 'add-deliverable') {
              this.props.addDeliverable(this.state.dropdownProjectsDeliverable)
            }
          }

        })
        .catch((err) => {
          toastr.error("", err.error.message);
        });
    }
  }

  listUserProjects(weekId) {
    ProjectApi.listUserWeekProject({ weekId: weekId })
      .then((response) => {
        this.setState({ projectList: response.data, loading: false });
      })
      .catch((err) => {
        toastr.error("", err.error.message);
      });
  }

  deleteUserProject(e, id) {
    e.preventDefault();
    ProjectApi.deleteUserProject({ weekId: this.props.val.id, projectId: id })
      .then((p) => {
        toastr.success("", p.message);
        this.setState({ modalDeleteShow: false });
        this.props.sendData(this.props.val, this.props.auth.user.id);
        // this.listUserProjects(this.props.val.id);
      })
      .catch((err) => {
        toastr.error("", err.error.message);
      });
  }

  clickedAccordion = (e, i, accordionLength, users) => {
    if (
      document.getElementById(`accbody${i}`) &&
      document.getElementById(`accbody${i}`).contains(e.target)
    )
      return true;
    for (let userIndex = 0; userIndex < accordionLength; userIndex++) {
      if (
        document.getElementById(`userTask${userIndex}`) &&
        document.getElementById(`userTask${userIndex}`).contains(e.target)
      )
        return true;
    }
    // if (
    //   document.getElementById(`userTask${i}-tab-${this.state.activeTab}`) &&
    //   document.getElementById(`userTask${i}-tab-${this.state.activeTab}`).contains(e.target)
    // )
    //   return true;
    if (
      document.getElementById(`removeProject${i}`) &&
      document.getElementById(`removeProject${i}`).contains(e.target)
    )
      return true;
    // if (
    //   document.getElementById(`editProject${i}`) &&
    //   document.getElementById(`editProject${i}`).contains(e.target)
    // )
    //   return true;
    var element = document.getElementById(`project${i}`);
    for (let x = 0; x < accordionLength; x++) {
      document.getElementById(`project${x}`).classList.add("overlay");
    }
    if (this.state.accordionOpen && this.state.accordionId === i) {
      this.setState({ accordionOpen: false, accordionId: null });
    } else if (this.state.accordionOpen && this.state.accordionId !== i) {
      this.setState({
        accordionOpen: true,
        accordionId: i,
        activeTab:
          users.length > 0
            ? users[0].userId === this.props.auth.user.id
              ? this.props.auth.user.id
              : users[0].userId
            : 0
      });
      element.classList.remove("overlay");
    } else if (!this.state.accordionOpen && this.state.accordionId !== i) {
      this.setState({
        accordionOpen: true,
        accordionId: i,
        activeTab:
          users.length > 0
            ? users[0].userId === this.props.auth.user.id
              ? this.props.auth.user.id
              : users[0].userId
            : 0
      });
      element.classList.remove("overlay");
    }
  };

  handleSelect = (selectedTab, i) => {
    this.setState({
      activeTab: selectedTab
    });
  };
  updateDeliverable = (data) => {
    this.setState({ selectedProjectDeliverable: data });
  };

  render() {

    if (this.state.loading || this.state.projectList.length === 0) {
      return <Shimmer />;
    }

    // if (this.state.projectList.length === 0) {
    //   return <div></div>
    // }

    let deliverableProjectLists = this.state.userDeliverableProjects;
    return (
      < EstimateWrapper accordionOpen={this.state.accordionOpen} >
        <Accordion allowZeroExpanded={true}>
          {this.state.projectList.projects.map((p, i) => {
            return (
              <AccordionItem
                key={i}
                onClick={(e) => this.clickedAccordion(e, i, this.state.projectList.projects.length, p.user_projects)}
                id={`project${i}`}
              >
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="accordion__button--inner">
                      <div>
                        <ProjectInfo>
                          <div id={`projectItem${i}`}>
                            <ProjectName>{p.title}</ProjectName>
                            <ProjectDeliverables>
                              {p.project_deliverables.length > 0 && (
                                <ProgressHourWrap>
                                  <ProgressHour
                                    style={{
                                      width:
                                        (p.project_deliverables[0].tasks.filter(
                                          (t) => {
                                            return t.isCompleted === true;
                                          }
                                        ).length /
                                          p.project_deliverables[0].tasks
                                            .length) *
                                        100 +
                                        "%"
                                    }}
                                  ></ProgressHour>
                                </ProgressHourWrap>
                              )}
                              {p.project_deliverables.length > 0 && (
                                <div>
                                  <strong>
                                    {
                                      p.project_deliverables[0].tasks.filter(
                                        (t) => {
                                          return t.isCompleted === true;
                                        }
                                      ).length
                                    }
                                    /{p.project_deliverables[0].tasks.length}
                                  </strong>{" "}
                                  deliverables completed
                                </div>
                              )}
                            </ProjectDeliverables>
                          </div>
                        </ProjectInfo>
                        {(!this.state.accordionOpen &&
                          !this.state.accordionId) ||
                          this.state.accordionId !== i ? (
                            p.hour ? (
                              <HoursInvolved>{p.hour} hrs</HoursInvolved>
                            ) : (
                                ""
                              )
                          ) : (
                            ""
                          )}
                      </div>
                      <AccordionDetails>
                        <div>
                          {(!this.state.accordionOpen &&
                            !this.state.accordionId) ||
                            this.state.accordionId !== i ? (
                              <ProjectMembers>
                                {p.user_projects.map((up, i) => {
                                  if (up.user.firstName === "You") {
                                    return <li key={i}>{up.user.firstName}</li>;
                                  } else if (
                                    this.props.auth.user.id &&
                                    this.props.auth.user.id === up.user.id
                                  ) {
                                    return <li key={i}>You</li>
                                  } else {
                                    return (
                                      <li key={i}>
                                        <Link to={`/profile/${up.user.id}`}>
                                          {up.user.firstName}
                                        </Link>
                                        <span> ({up.hour})</span>
                                      </li>
                                    );
                                  }
                                })}
                              </ProjectMembers>
                            ) : (
                              <ProjectMembersTab>
                                <div key={i}>
                                  <Tabs
                                    activeKey={this.state.activeTab}
                                    id={`userTask${i}`}
                                    onSelect={(selectedTab) =>
                                      this.handleSelect(selectedTab, i)
                                    }
                                  >
                                    {p.user_projects.length > 0 ? (
                                      p.user_projects.map((up, i) => {
                                        return (
                                          <Tab
                                            eventKey={up.userId}
                                            title={
                                              this.props.auth.user.id &&
                                                this.props.auth.user.id === up.user.id ?
                                                'You' : up.user.firstName
                                            }
                                            className={`tabClass${i}`}
                                            key={i}
                                          >
                                            <TaskList
                                              project={p}
                                              auth={this.props.auth}
                                              deliverableOpen={
                                                this.props.addDeliverable
                                              }
                                              projectsList={this.state.projects}
                                              val={this.props.val}
                                              taskOpen={this.props.addEstimate}
                                              selectedUserId={
                                                this.state.activeTab
                                              }
                                              updateDeliverable={
                                                this.updateDeliverable
                                              }
                                              profile={this.props.profile}
                                            />
                                          </Tab>
                                        );
                                      })
                                    ) : (
                                        <TaskList
                                          project={p}
                                          auth={this.props.auth}
                                          deliverableOpen={
                                            this.props.addDeliverable
                                          }
                                          projectsList={this.state.projects}
                                          val={this.props.val}
                                          taskOpen={this.props.addEstimate}
                                          selectedUserId={this.state.activeTab}
                                          updateDeliverable={
                                            this.updateDeliverable
                                          }
                                          profile={this.props.profile}
                                        />
                                      )}
                                  </Tabs>
                                </div>
                              </ProjectMembersTab>
                            )}
                        </div>
                      </AccordionDetails>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                {/* {this.props.val.isEditable === true ? (
                  <RemoveProject
                    id={`removeProject${i}`}
                    onClick={() =>
                      this.setState({
                        modalDeleteShow: true,
                        projectId: p.id
                      })
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="10"
                      height="14"
                      viewBox="0 0 10 14"
                    >
                      <defs>
                        <path
                          id="f"
                          d="M2.25 14.75h6c.825 0 2-1.175 2-2v-8h-10v8c0 .825 1.175 2 2 2zm8-13h-2l-1-1h-4l-1 1h-2v2h10v-2z"
                        />
                      </defs>
                      <use
                        fill="#333"
                        fillRule="evenodd"
                        transform="translate(-.25 -.75)"
                        xlinkHref="#f"
                      />
                    </svg>
                    <span>Delete</span>
                  </RemoveProject>
                ) : (
                  ""

                )} */}

              </AccordionItem>
            );
          })}
        </Accordion>
        {!this.state.projectList.projects.length ? <EmptyState /> : ''}
        <FloatingButton>
          {this.props.val.isEditable && !this.props.profile ? (
            <AddEstimation>
              {/* {this.state.projectList.length} */}
              {!this.state.projectList.projects.length ?
                <div className="first-task">
                  <div>Start by adding your first task for the week!</div>
                </div>

                : ''}
              <div
                className="btn btn-primary"
                onClick={() => {
                  this.props.addEstimate(this.state.projects);
                }}
              >
                <span className="glyphicon glyphicon-plus"></span>Add a task
              </div>
            </AddEstimation>
          ) : (
              ""
            )}

          {deliverableProjectLists && deliverableProjectLists.length > 0 && !this.props.profile ? (
            this.props.val.isEditable ? (
              <AddEstimation>
                <div
                  className="btn btn-white"
                  onClick={() =>
                    this.props.addDeliverable(
                      this.state.dropdownProjectsDeliverable
                    )
                  }
                >
                  <span className="glyphicon glyphicon-plus"> </span>
                Add a team deliverable
                </div>
              </AddEstimation>
            ) : (
                ""
              )
          ) : (
              ""
            )}
          {/* <RemoveProjectModal
              project={projectLists}
              this={this}
              show={this.state.modalDeleteShow}
              onHide={() => this.setState({ modalDeleteShow: false })}
            /> */}
        </FloatingButton>
      </EstimateWrapper >
    );
  }
}

const EstimateWrapper = styled.div`
  .overlay {
    &:before {
      content: "";
      background-color: ${(props) =>
    props.accordionOpen ? "rgba(0,0,0,0.5)" : "inherit"};
      width: 100%;
      height: calc(100% + 1px);
      display: block;
      position: absolute;
      top: -1px;
    }
  }
`;
const ProjectInfo = styled.div`
  padding-right: 20px;
  width: 78%;
  > div {
    align-items: flex-end;
    display: flex;
    margin-bottom: 4px;
    p {
      color: rgba(51, 51, 51, 0.5);
      font-style: italic;
      font-size: 12px;
      margin: 0;
      overflow: hidden;
      padding-right: 2px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const ProjectName = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-right: 8px;
  white-space: nowrap;
`;

const ProjectDeliverables = styled.div`
  align-items: center;
  color: rgba(51, 51, 51, 0.5);
  display: flex;
  font-size: 12px;
  > div:first-child {
    margin-right: 9px;
    width: 120px;
  }
`;

const ProjectMembers = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 8px 0 0;
  padding: 0 0 18px 40px;
  li {
    font-size: 12px;
    &:not(:last-child):after {
      content: ",";
    }
    a {
      color: #333;
      font-weight: 700;
      margin-left: 3px;
    }
    span {
      color: rgba(51, 51, 51, 0.7);
    }
  }
`;

const HoursInvolved = styled.div`
  width: 20%;
`;

const AddEstimation = styled.div`
position: relative;

.btn {
  position: relative;
  border-radius: 33px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 48px;
  margin-right: 3px;
  padding: 0 19px;

  &.btn-primary {
    margin-right: 12px;
    padding: 0 28px;
  }

  .glyphicon-plus {
    margin-right: 2px;
    transform: scale(0.6);
    top: 2px;
  }
}

.first-task {
  position: absolute;
  width: 237px;
  white-space: normal;
  font-size: 18px;
  line-height: 1.45;
  font-weight: 400;
  text-align: center;
  top: calc(0% - 127px);
  left: 50%;
  transform: translate(-50%, -100%);

  @media screen and (max-height: 854px){
    top: calc(0% - 47px);
  }

  &:before,&:after{
    content: "";
    position: absolute;
    bottom: calc(0% - 102px);
    transform: translateX(-50%);
    @media screen and (max-height: 854px){
      bottom: calc(0% - 35px);
    }
  }

  &:before{
    width: 1px;
    height: 86px;
    background-color: #fff;
    @media screen and (max-height: 854px){
      height: 25px;
    }
  }

  &:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 3px 0 3px;
    border-color: #fff transparent transparent transparent;
    margin-bottom: -2px
  }
}
`;


const ProgressHourWrap = styled.div`
  background: #d8d8d8;
  border-radius: 5px;
  height: 4px;
`;

const ProgressHour = styled.div`
  background: #53c075;
  border-radius: 5px;
  height: 4px;
  -webkit-animation: grower 1s linear;
  animation: grower 1s linear;
  -webkit-transition: 0.5s all linear;
  transition: 0.5s all linear;
`;

const ProjectMembersTab = styled.div`
  padding-bottom: 0;
  a {
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    color: #333 !important;
  }
`;

const AccordionDetails = styled.div`
  padding: 0 !important;
  > div {
    width: 100%;
  }
  .nav-tabs {
    border-bottom: 0;
    display: inline-block;
    padding: 0 40px;
    width: 100%;
    vertical-align: middle;
    li {
      a,
      a:hover {
        background: transparent;
        border: 0 !important;
        padding: 5px 6px;
      }
      &.active a {
        background: #f0f0f0;
        font-weight: normal;
      }
      a:focus {
        outline: 0;
      }
    }
    &:before,
    &:after {
      display: none;
    }
    &:empty + .tab-content {
      margin-top: 10px;
    }
  }
  .tab-content {
    background: #f0f0f0;
  }
`;

const FloatingButton = styled.div`
  position: fixed;
  right: 44px;
  bottom: 56px;
  display: flex;
  @media screen and (max-height: 768px){
    bottom: 15px;
  }
`;

Project.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  projectList: state.project.projectList
});
const mapDispatchToProps = (dispatch) => ({
  listUserProjects: (weekId) => {
    dispatch(ProjectAction.userProjectFetching);
    ProjectApi.listUserWeekProject({ weekId: weekId })
      .then((response) => {
        if (response.success) {
          dispatch(ProjectAction.userProjectFetched(response));
        } else {
          dispatch(ProjectAction.userProjectError(response));
        }
      })
      .catch((err) => {
        dispatch(ProjectAction.userProjectError(err));
      });
  },
  listCurrentProjects: (weekId, userId) => {
    dispatch(ProjectAction.userProjectFetching);
    ProjectApi.listUserCurrentProject({ weekId, userId })
      .then((response) => {
        if (response.success) {
          dispatch(ProjectAction.userProjectFetched(response));
          this.setState({ loading: false });
        } else {
          dispatch(ProjectAction.userProjectError(response));
        }
      })
      .catch((err) => {
        dispatch(ProjectAction.userProjectError(err));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Project));
