import React, { Component } from 'react';
import styled from 'styled-components';
import DragDropIcon from '../images/drag-and-drop.svg';

const maxSize = 2 * 1024 * 1024;
const _validFileExtensions = ['.jpg', '.jpeg', '.bmp', '.png'];
class Dropzone extends Component {
	constructor(props) {
		super(props);
		this.state = { hightlight: false, filename: '', error: '' };
		this.fileInputRef = React.createRef();

		this.openFileDialog = this.openFileDialog.bind(this);
		this.onFilesAdded = this.onFilesAdded.bind(this);
		this.onDragOver = this.onDragOver.bind(this);
		this.onDragLeave = this.onDragLeave.bind(this);
		this.onDrop = this.onDrop.bind(this);
	}

	openFileDialog() {
		if (this.props.disabled) return;
		this.fileInputRef.current.click();
	}

	onFilesAdded(evt) {
		if (this.props.disabled) return;
		const files = evt.target.files;

		const fileSize = files[0].size;
		if (fileSize > maxSize) {
			this.setState({ filename: '', error: 'File size exceeds 2 MB.' });
			return;
		}

		const fileTypeCheck = this.Validate(files[0].name);

		if (!fileTypeCheck) {
			this.setState({
				filename: '',
				error:
					'File type not allowed. Use "jpeg", "jpg","png" or "bmp" file type.'
			});
			return;
		}

		if (this.props.onFilesAdded) {
			const array = this.fileListToArray(files);
			this.props.onFilesAdded(array);
		}

		const reader = new FileReader();
		reader.addEventListener(
			'load',
			() => this.setState({ imageUrl: String(reader.result) }),
			false
		);
		reader.readAsDataURL(files[0]);
		this.setState({ filename: files[0].name, error: '' });
	}

	onDragOver(evt) {
		evt.preventDefault();

		if (this.props.disabled) return;

		this.setState({ hightlight: true });
	}

	onDragLeave() {
		this.setState({ hightlight: false });
	}

	onDrop(event) {
		event.preventDefault();

		if (this.props.disabled) return;

		const files = event.dataTransfer.files;

		const fileSize = files[0].size;
		if (fileSize > maxSize) {
			this.setState({ filename: '', error: 'File size exceeds 2 MB.' });
			return;
		}

		const fileTypeCheck = this.Validate(files[0].name);

		if (!fileTypeCheck) {
			this.setState({
				filename: '',
				error:
					'File type not allowed. Use "jpeg", "jpg","png" or "bmp" file type.'
			});
			return;
		}

		if (this.props.onFilesAdded) {
			const array = this.fileListToArray(files);
			this.props.onFilesAdded(array);
		}
		const reader = new FileReader();
		reader.addEventListener(
			'load',
			() => this.setState({ imageUrl: String(reader.result) }),
			false
		);
		reader.readAsDataURL(files[0]);
		this.setState({ hightlight: false, filename: files[0].name, error: '' });
	}

	Validate = filename => {
		var blnValid = false;
		for (var j = 0; j < _validFileExtensions.length; j++) {
			var sCurExtension = _validFileExtensions[j];
			if (
				filename
					.substr(filename.length - sCurExtension.length, sCurExtension.length)
					.toLowerCase() === sCurExtension.toLowerCase()
			) {
				blnValid = true;
				break;
			}
		}

		if (!blnValid) {
			return false;
		}

		return true;
	};

	fileListToArray(list) {
		const array = [];
		for (var i = 0; i < list.length; i++) {
			array.push(list.item(i));
		}
		return array;
	}

	handleRemovePic = () => {
		this.setState({ imageUrl: null, filename: '', error: '' });
	};

	render() {
		return (
			<DropzoneWrapper>
				<div
					className={`dropzone ${this.state.hightlight ? 'Highlight' : ''}`}
					onDragOver={this.onDragOver}
					onDragLeave={this.onDragLeave}
					onDrop={this.onDrop}
					onClick={this.openFileDialog}
					style={{ cursor: this.props.disabled ? 'default' : 'pointer' }}
				>
					<input
						ref={this.fileInputRef}
						className="FileInput"
						accept="image/*"
						type="file"
						onChange={this.onFilesAdded}
					/>
					<img alt="upload" className="Icon" src={DragDropIcon} />
					<span>Drag or Click to Upload Files</span>
				</div>
				{this.state.filename !== '' && (
					<div className="image-preview">
						<div className="image-wrapper">
							<img src={this.state.imageUrl} alt="this.state.filename" />
						</div>
						<button type="button" onClick={this.handleRemovePic}>
							x
						</button>
						<span>
							File name: <strong>{this.state.filename}</strong>
						</span>
					</div>
				)}
				{this.state.error !== '' && (
					<span>
						<strong style={{ color: 'red' }}>{this.state.error}</strong>
					</span>
				)}
			</DropzoneWrapper>
		);
	}
}

export default Dropzone;

const DropzoneWrapper = styled.div`
	.dropzone {
		height: 200px;
		width: 540px;
		background-color: #fff;
		border: 2px dashed rgb(187, 186, 186);
		/* border-radius: 50%; */
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		font-size: 16px;
		margin-bottom: 20px;
	}

	.Highlight {
		background-color: rgb(188, 185, 236);
	}

	.Icon {
		opacity: 0.3;
		height: 64px;
		width: 64px;
	}

	.FileInput {
		display: none;
	}
	.image-preview {
		width: 200px;
		height: 200px;
		position: relative;
		.image-wrapper {
			width: 150px;
			height: 150px;
			background: #fff;
			border-radius: 50%;
			overflow: hidden;
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}
		span {
			margin-bottom: 20px;
		}
		button {
			outline: none;
			position: absolute;
			top: 14px;
			right: 50px;
			background: #fff;
			border-radius: 50%;
			padding: -91px;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 30px;
			width: 30px;
			font-size: 18px;
		}
	}
`;
