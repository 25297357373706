class ApiUrlConstant {
	async LIST_PROJECTS() {
		return `projects`;
	}

	async LIST_USER_LEADING_PROJECTS(weekId) {
		return `user/leading/${weekId}/projects`;
	}

	async LIST_WEEKS() {
		return `weeks`;
	}

	async USER_WEEK_DETAIL(params) {
		return `users/${params.userId}/weeks/${params.weekId}`;
	}

	async CREATE_USER_PROJECT(userId) {
		return `users/${userId}/projects`;
	}

	async USER_PROJECT_DETAIL(projectId) {
		return `projects/${projectId}`;
	}

	async USER_WEEKLY_PROJECT(params) {
		if (params.userId)
			return `weeks/${params.weekId}/users/${params.userId}/projects`;
		else return `weeks/${params.weekId}/projects`;
	}

	async USER_PROJECT(params) {
		return `weeks/${params.weekId}/projects/${params.projectId}`;
	}

	async USER_LEAVE_SETTING(params) {
		return `leave/settings/${params.userId}/${params.supervisorId}`;
	}

	async USER_LEAVES_TODAY() {
		return `leave/today/users`;
	}

	async USER_LEAVE_APPLICATION(params) {
		return `leave/apply`;
	}

	async USER_LEAVES(params) {
		return `leave/user/${params.userId}?fiscalYear=${params.year}`;
	}

	async USER_LEAVE_DELETE(params) {
		return `leave/remove/${params.leaveId}?type=${params.type}`;
	}

	async HOLIDAY_PLANS() {
		return `holidaysplan`;
	}

	async USER_DETAIL(params) {
		if (params.userId === '') return `users/profile`;
		else return `users/${params.userId}/profile`;
	}

	async SUPERVISOR_TEAM(params) {
		return `weeks/${params.weekId}/teams/estimations`;
	}

	async USER_WORKING_FROM_HOME_APPLICATION(userId) {
		return `users/${userId}/working_homes`;
	}

	async USER_OFFICIAL_TRIP(userId) {
		return `users/${userId}/official_trips`;
	}

	async WFH_TODAY_USERS() {
		return `/wfh/today/users`;
	}

	async UPDATE_USER() {
		return `users/edit`;
	}

	async UPDATE_USER_IMAGE() {
		return `users/image/upload`;
	}

	async USER_ATTENDANCE(params) {
		return `users/${params.weekId}/attendance`;
	}

	async PEOPLE_INFORMATION(params) {
		return `/users/daily/status`;
	}

	async LIST_WEEK_PROJECT_DETAIL(params) {
		return `/weeks/projects`;
	}

	async USER_LOGIN_INFORMATION(params){
		return `/usersinfo`;
	}

	async PROJECT_DELIVERABLE(params) {
		return `/weeks/${params.weekId}/projects/${params.projectId}/deliverables`;
	}

	async LIST_WEEKS_BY_YEAR(params) {
		return `/weeks/year/${params.year}`;
	}

	async USER_PROJECT_TASKS(params) {
		if (params.projectId){
			return `weeks/${params.weekId}/projects/${params.projectId}/tasks`;
		}
		else return `weeks/${params.weekId}/tasks`
	}

	async ADMIN_PENDING_LEAVES(params) {
		return `leave/admin/${params.id}?role=${params.role}`;
	}

	async USER_CURRENT_PROJECT(params) {
		return `week/${params.weekId}/user/${params.userId}/projects`;
	}

	async USER_BIRTHDAYS(params){
		return `users/birthdays`;
	}
}

export default new ApiUrlConstant();
