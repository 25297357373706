/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import styled from 'styled-components';

const loadBar = width => {
	let interval;
	const start = 0;
	const end = parseInt(width, 10);
	let current = start;

	const countUp = () => {
		if (end === 0) {
			// document.getElementById('demo').innerHTML = `${current}%`;
			clearInterval(interval);
		} else if (end <= current) {
			current--;
			// document.getElementById('demo').innerHTML = `${current}%`;
		} else {
			current++;
			// document.getElementById('demo').innerHTML = `${current}%`;

			if (current === end) {
				clearInterval(interval);
			}
		}
	};

	interval = setInterval(countUp, 1000 / (end + 1));
};
class ProgressBar extends Component {
	constructor(props) {
		super(props);
		this.state = { width: 0 };
	}

	componentDidMount() {
		const { width } = this.props;
		this.setState({ width });
		loadBar(width);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.width !== prevState.width) {
			loadBar(nextProps.width);
			return { width: nextProps.width };
		}

		return null;
	}

	render() {
		const { width } = this.state;
		const { color } = this.props;

		const background = `rgba(${color},0.2)`;
		const progressColor = `rgba(${color})`;
		return (
			<Progress
				background={background}
				progressColor={progressColor}
				className="meter"
				progress={width}
			>
				<span style={{ width: `${parseInt(width, 10)}%` }} />
			</Progress>
		);
	}
}

export default ProgressBar;
const Progress = styled.div`
	/* position: absolute;
	bottom: 10%;
	left: 50%;
	transform: translate(-50%, 0%); */
	@media screen and (max-width: 767px) {
		position: unset;
		bottom: unset;
		left: unset;
		transform: unset;
	}
	&.meter {
		width: 241px;
		height: 10px;
		border-radius: 5px;
		background: ${props => props.background};
		@media screen and (max-width: 767px) {
			width: 320px;
		}
	}

	span {
		display: block;
		height: 100%;
		width: 0%;
		animation: grower 1s linear;
		transition: 0.5s all linear;
		position: relative;
		top: 0;
		left: -1px;
		border-bottom-left-radius: 30px;
		border-top-left-radius: 30px;
		border-top-right-radius: ${props =>
			props.progress < 100 ? '15px' : '30px'};
		border-bottom-right-radius: 15px;
		-webkit-box-shadow: inset 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
		-moz-box-shadow: inset 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
		box-shadow: inset 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
		opacity: 1;
		background: ${props => props.progressColor};

		@media screen and (max-width: 767px) {
			background: rgb(51, 181, 229);
			background: linear-gradient(
				90deg,
				rgba(0, 153, 204, 1) 0%,
				rgba(51, 181, 229, 1) 100%
			);
		}
	}

	p {
		margin: 0;
		padding-left: 3px;
		text-align: right;
		line-height: 27px;
		font-family: 'Helvetica';
		font-weight: 500;
		-webkit-font-smoothing: antialised;
		font-size: 14px;
		text-shadow: 0 1px rgba(255, 255, 255, 0.6);
	}

	@keyframes grower {
		0% {
			width: 0%;
		}
		6% {
			width: 6%;
		}
	}

	@-moz-keyframes grower {
		0% {
			width: 0%;
		}
		6% {
			width: 6%;
		}
	}

	@-webkit-keyframes grower {
		0% {
			width: 0%;
		}
		6% {
			width: 6%;
		}
	}

	@-o-keyframes grower {
		0% {
			width: 0%;
		}
		6% {
			width: 6%;
		}
	}
`;
