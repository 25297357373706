import React, { Component } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
class ModalView extends Component {
	render() {
		return (
			<Modal
				{...this.props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						{this.props.header}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<Row>
							<Col sm={12}>
								<p> Are you sure you want to cancel the leave request ? </p>
							</Col>
						</Row>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="btn-wrap">
						<Button bsStyle="primary" onClick={this.props.delete}>
							Yes, Cancel it!
						</Button>
						<Button onClick={this.props.onHide}> Close </Button>
					</div>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default ModalView;
