import { 
	USER_TASKS_FETCHING,
	USER_TASKS_FETCHED,
	USER_TASKS_ERROR
 } from '../types'

export function fetching() {
	return { type: 'PROJECT_FETCHING' };
}

export function fetched(payload) {
	return { type: 'PROJECT_FETCHED', payload };
}

export function error(payload) {
	return { type: 'PROJECT_ERROR', payload };
}

export function userProjectFetching() {
	return { type: 'USER_PROJECT_FETCHING' };
}

export function userProjectFetched(payload) {
	return { type: 'USER_PROJECT_FETCHED', payload };
}

export function userProjectError(payload) {
	return { type: 'USER_PROJECT_ERROR', payload };
}

export function userDeliverableFetching() {
	return { type: 'USER_DELIVERABLE_FETCHING' };
}

export function userDeliverableFetched() {
	return { type: 'USER_DELIVERABLE_FETCHED' };
}

export function userDeliverableError(payload) {
	return { type: 'USER_DELIVERABLE_ERROR', payload };
}

export function userTaskFetching() {
	return { type: 'USER_TASK_FETCHING' };
}

export function userTaskFetched() {
  return { type: 'USER_TASK_FETCHED' };
}

export function userLeadingProjectsFetching() {
	return { type: 'USER_LEADING_PROJECTS_FETCHING' };
}

export function userLeadingProjectsFetched(payload) {
	return { type: 'USER_LEADING_PROJECTS_FETCHED', payload};
}

export function userTasksFetching() {
	return { type: USER_TASKS_FETCHING };
}

export function userTasksFetched(payload) {
	return { type: USER_TASKS_FETCHED, payload };
}

export function userTasksError(payload) {
	return { type: USER_TASKS_ERROR, payload };
}