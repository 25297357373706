import Api from './api';
import ApiUrlConstant from '../helpers/constant/api-url';

class HolidayApi {
	async holidayPlans(userId, supervisorId) {
		try {
			let holidays = await Api.get(await ApiUrlConstant.HOLIDAY_PLANS());
			return holidays.body;
		} catch (error) {
			return error;
		}
	}
}

export default new HolidayApi();
