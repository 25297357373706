export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const WORKING_FROM_HOME_LEAVE = "Working From Home";
export const OFFICIAL_TRIP_LEAVE = "Official Trip";
export const ANNUAL_LEAVE = "Annual Leave";
export const SICK_LEAVE = "Sick Leave";
export const MENSTRUAL_LEAVE = "Menstrual Leave";

export const getYearArr = () => {
    const initialYear = +'2018'
    const finalYear = +process.env.REACT_APP_CURRENT_YEAR
    const loopingVal = finalYear - initialYear
    let yearArr = []
    let year = finalYear
    for (let i = loopingVal; i > 0; i--) {
        yearArr.push({ value: `${year - 1}/${year}`, label: `${year - 1}/${year}` })
        year = year - 1
    }
    return yearArr;
}
