import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import 'react-scrollbar/dist/css/scrollArea.css';
import { Panel } from 'react-bootstrap';
import { withRouter } from "react-router-dom";


import Project from './Index';

import * as UserAction from '../../actions/user';
import * as ProjectAction from '../../actions/project';

import UserService from '../../services/UserApi';
import ProjectApi from '../../services/ProjectApi';

import Background from '../../components/Background';

import WorkFromHomeIcon from '../../images/ic-work-home.svg';
import OfficialTripIcon from '../../images/ic-official-trip.svg';
import OfficialEventIcon from '../../images/podium.svg';

import AddEstimate from '../layout/AddEstimate';
import AddDeliverables from '../layout/AddDeliverables';

import Birthday from './../../images/birthday.png';

import Spinner from '../../components/layout/Loader';

class Main extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menuOpen: false,
			deliverableOpen: false,
			usersBirthday: null,
			hour: null
		};
		this.getWeekDetail = this.getWeekDetail.bind(this);
		this.handleMenuClick = this.handleMenuClick.bind(this);
		this.handleDeliverableClick = this.handleDeliverableClick.bind(this);
	}

	/**
	 * Navigating next or previus week with keyboard arrows
	 *
	 * keycode 37 is for left arrow.
	 * keycode 39 is for right arrow.
	 * keycode 27 is for ESC button.
	 */

	triggerWithKeydown = (event, props) => {
		let currentWeekNumber = this.props.val.weekNo;
		console.log('Keypress ---' + this.props.isMenuOpenOrClosed);
		switch (event.keyCode) {
			case 37:
				if (!this.props.isMenuOpenOrClosed && currentWeekNumber > 1) {
					this.props.handleTabSelect(this.props.weekList[currentWeekNumber - 2]);
				}
				break;

			case 39:
				if (!this.props.isMenuOpenOrClosed && currentWeekNumber < this.props.weekList.length) {
					this.props.handleTabSelect(this.props.weekList[currentWeekNumber]);
				}
				break;

			case 27:
				if (this.props.isMenuOpenOrClosed) {
					this.setState(prevState => ({
						menuOpen: false,
						deliverableOpen: false
					}));
				}
				break;

			default:
				break;
		}
	}

	componentDidMount() {
		// document.getElementById("months").addEventListener("onChange", this.triggerOnChange);
		document.addEventListener("keydown", this.triggerWithKeydown);
		this.getWeekDetail(this.props.val);
		this.setState({
			selectedWeek: this.props.val
		});
		this.fetchBirthdayUsers();
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.triggerWithKeydown);
	}

	//Function which is called whenever the component is updated
	componentDidUpdate(prevProps) {
		if (this.props.val !== prevProps.val) {
			this.getWeekDetail(this.props.val);
			this.setState({
				selectedWeek: this.props.val,
				prevWeekId: this.props.val.previousWeekId
			});
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let data = {};
		if (nextProps.userAttendance !== prevState.userAttendance) {
			data.userAttendance = nextProps.userAttendance.attendance;
		}

		if (
			Object.keys(nextProps.projectList).length &&
			nextProps.projectList !== prevState.projectList
		) {
			data.projectList = nextProps.projectList;
			let hours = 0;
			nextProps.projectList.projects.forEach(p =>
				p && p.hour ? (hours = hours + p.hour) : hours
			);
			data.hour = hours;
		}

		return data;
	}

	getWeekDetail(week) {
		if (week) {
			let weekId = week.id;
			this.props.getUserAttendance(weekId);
			this.props.listUserProjects(weekId);
		}
	}

	fetchBirthdayUsers = () => {
		UserService.listBirthdayUsers()
			.then(response => {
				this.setState({ usersBirthday: response.data.users });
			})
			.catch(err => {
				console.log(err);
			});
	};

	renderDate() {
		const days = [];
		const { userLeaves } = this.props.userLeaves;
		const { userAttendance, selectedWeek } = this.state;
		var currDate = moment(this.state.selectedWeek.fromDate).startOf('day');
		var lastDate = moment(this.state.selectedWeek.toDate).startOf('day');
		let dates = [currDate.clone().toDate()];

		while (currDate.add(1, 'days').diff(lastDate) <= 0) {
			dates.push(currDate.clone().toDate());
		}

		dates.forEach(date => {
			let dt = moment(date);
			let year = dt.format('YYYY');
			let month = dt.format('MM');
			let today = moment();
			let dat = dt.format('DD');
			let day = moment(`${year}-${month}-${dat}`, 'YYYY-MM-DD HH:mm:ss').format(
				'dd'
			);
			let holiday = selectedWeek.holidays.find(
				h =>
					h.startDate ===
					moment(`${year}-${month}-${dat}`, 'YYYY-MM-DD HH:mm:ss').format(
						'MMM DD, YYYY'
					)
			);
			let workingHome = selectedWeek.workingHomes.find(
				w =>
					w.startDate ===
					moment(`${year}-${month}-${dat}`, 'YYYY-MM-DD HH:mm:ss').format(
						'MMM DD, YYYY'
					)
			);

			let officialTrip = selectedWeek.officialTrips.find(
				t =>
					t.startDate ===
					moment(`${year}-${month}-${dat}`, 'YYYY-MM-DD HH:mm:ss').format(
						'MMM DD, YYYY'
					)
			);
			let leave = userLeaves && userLeaves.length > 0 &&
				userLeaves.sort(
					(a, b) => new moment.utc(b.createdAt) - new moment.utc(a.createdAt)
				)
					.find(
						l =>
							moment(l.from).format('MMM DD, YYYY') ===
							moment(`${year}-${month}-${dat}`, 'YYYY-MM-DD HH:mm:ss').format(
								'MMM DD, YYYY'
							)
					);

			let hours = null;
			if (userAttendance) {
				const attendance = userAttendance.find(a => {
					if (a.in) {
						return (
							moment(a.in.inTime).format('MMM DD, YYYY') ===
							moment(`${year}-${month}-${dat}`, 'YYYY-MM-DD HH:mm:ss').format(
								'MMM DD, YYYY'
							)
						);
					} else {
						return null;
					}
				});
				if (attendance) {
					const duration = moment.duration(
						moment(attendance.out.inTime).diff(moment(attendance.in.inTime))
					);
					hours =
						duration.asHours().toFixed(1) > 0
							? duration.asHours().toFixed(1)
							: -1;
				} else if (leave) {
					hours = 0;
				} else if (holiday) {
					hours = 0;
				} else if (officialTrip) {
					hours = 0;
				} else if (workingHome) {
					hours = 0;
				} else if (
					!attendance &&
					moment().subtract(1, 'days') >=
					moment(`${year}-${month}-${dat}`, 'YYYY-MM-DD HH:mm:ss')
				) {
					hours = -1;
				}
				hours =
					+today.format('DD') === +dat && +today.format('MM') === +month
						? 0
						: hours;
			}
			days.push(
				<span key={dat}>
					{+today.format('DD') === +dat &&
						+today.format('MM') === +month &&
						+today.format('YYYY') === +year ? (
							<span className='indicate-today'>TODAY</span>
						) : (
							''
						)}

					{day.charAt(0)}
					{holiday ? (
						holiday.womenOnly ?
							this.props.auth.user.gender === 'f' ? (
								<HolidayWrap>
									<Image name={holiday.displayTitle.toLowerCase()} />
								</HolidayWrap>
							)
								: (
									<span className='day-wrap'>
										<strong>{dat}</strong>
										{dt.format('MMM')}
									</span>
								)
							: (
								<HolidayWrap>
									<Image name={holiday.displayTitle.toLowerCase()} />
								</HolidayWrap>
							)
					) : leave ? (
						<span
							className={`day-wrap ${
								!leave.halfDayPeriod && leave.approvedStatus !== 'rejected'
									? 'indicate-leave'
									: leave.halfDayPeriod === 'firstHalf' &&
										leave.approvedStatus !== 'rejected'
										? 'indicate-leave-first'
										: leave.halfDayPeriod === 'secondHalf' &&
											leave.approvedStatus !== 'rejected'
											? 'indicate-leave-second'
											: ''
								}`}
						>
							<strong>{dat}</strong>
							{dt.format('MMM')}
						</span>
					) : workingHome ? (
						<HolidayWrap>
							<span className='out-of-office'>
								<img
									src={WorkFromHomeIcon}
									alt='WorkFromHome'
									width='20'
									height='17'
								/>
							</span>
						</HolidayWrap>
					) : officialTrip ? (
						<HolidayWrap>
							<span className='out-of-office trip'>
								<img
									src={
										officialTrip.type === 'officialTrip'
											? OfficialTripIcon
											: OfficialEventIcon
									}
									alt='OfficialTrip'
									width='16'
									height='16'
								/>
							</span>
						</HolidayWrap>
					) : (
										<span className='day-wrap'>
											<strong>{dat}</strong>
											{dt.format('MMM')}
										</span>
									)}

					{moment().diff(+dt, 'days') > 0 ?

						hours === null ? <span className="in-hours hr-loader">{' '}</span> : ''
						: <span className='empty-span'>{''}</span>
					}

					{/* {hours !== null ? '' : <span className="in-hours hr-loader">{' '}</span>} */}


					{hours > 0 && <span className='in-hours'>{hours}</span>}

					{hours < 0 && (
						<span className='in-hours err'>
							!
							<span>
								Looks like you’ve missed signing out on{' '}
								{moment(
									`${year}-${month}-${dat}`,
									'YYYY-MM-DD HH:mm:ss'
								).format('dddd DD MMM, YYYY')}
								. Please contact our operations head :)
							</span>
						</span>
					)}

				</span>
			);
		});
		return days;
	}

	handleMenuClick = (projects, projectData = null) => {
		this.setState({
			menuOpen: !this.state.menuOpen,
			projects,
			projectData
		});

		if (!this.state.menuOpen) {
			this.props.history.push(this.props.match.url + '/add-task');
		}
	};

	handleDeliverableClick = (projectsList, project = null) => {
		this.setState({
			deliverableOpen: !this.state.deliverableOpen,
			projectDetailForDeliverable: project,
			deliverableProjectsList: projectsList
		});

		if (!this.state.deliverableOpen) {
			this.props.history.push(this.props.match.url + '/add-deliverable');
		}
	};

	isMenuOpen = state => {
		const { match } = this.props;
		if (this.state.menuOpen !== state.isOpen) {
			this.setState({ menuOpen: !this.state.menuOpen });
		}

		this.props.shortcutKey();

		if (!state.isOpen) {
			if (this.props.match.path === '/dashboard/:add') {
				this.props.history.push('/dashboard')
			}
			if (this.props.match.path == '/dashboard/:weekNo/:year/:add') {
				this.props.history.push(`/dashboard/${match.params.weekNo}/${match.params.year}`)
			}
			// this.props.history.push(this.props.match.url)
		}
		return state.isOpen;
	};

	isDeliverableOpen = state => {
		const { match } = this.props;
		if (this.state.deliverableOpen !== state.isOpen) {
			this.setState({ deliverableOpen: !this.state.deliverableOpen });
		}

		this.props.shortcutKey();

		if (!state.isOpen) {
			if (this.props.match.path === '/dashboard/:add') {
				this.props.history.push('/dashboard')
			}
			if (this.props.match.path == '/dashboard/:weekNo/:year/:add') {
				this.props.history.push(`/dashboard/${match.params.weekNo}/${match.params.year}`)
			}
			// this.props.history.push(this.props.match.url)
		}
		return state.isOpen;
	};

	render() {
		let userDob = false;
		const { usersBirthday } = this.state;
		const { auth } = this.props;

		if (auth.user.name && usersBirthday && usersBirthday.length > 0) {
			let user = usersBirthday.find(u => u.dob === auth.user.dob);
			if (user) {
				userDob = true;
			}
		}
		if (!this.state.selectedWeek) return <p></p>;
		return (
			<Template>
				<Background />
				<AddEstimate
					mode={this.state.projectData ? 'edit' : 'add'}
					userId={this.props.auth.user.id}
					isOpen={this.state.menuOpen}
					isMenuOpen={this.isMenuOpen}
					projects={this.state.projects}
					projectData={this.state.projectData}
					weekId={this.state.selectedWeek.id}
					prevWeekId={this.state.prevWeekId}
				/>
				<AddDeliverables
					mode={this.state.projectDetailForDeliverable ? 'edit' : 'add'}
					isOpen={this.state.deliverableOpen}
					isDeliverableOpen={this.isDeliverableOpen}
					weekId={this.state.selectedWeek.id}
					prevWeekId={this.state.prevWeekId}
					projectDeliverableDetail={this.state.projectDetailForDeliverable}
					deliverableProjectsList={this.state.deliverableProjectsList}
				/>
				<InnerTemplate>
					<Panel className='centeralign'>
						<Panel.Heading>
							{userDob ? (
								<div className='birthday-img'>
									<img
										src={Birthday}
										alt='birthday'
										style={{
											width: '100%',
											height: '150px',
											objectFit: 'cover',
											margin: '-96px 0 10px 0'
										}}
									/>
								</div>
							) : (
									''
								)}
							<Panel.Title componentClass='div'>
								<div className='week-title'>
									<h1>Week {this.state.selectedWeek.weekNo}</h1>
									<span>Office in-hours →</span>
								</div>

								<WeekRange>{this.renderDate()}</WeekRange>
							</Panel.Title>
						</Panel.Heading>
						<Panel.Body>
							<EstimatedHoursWrap>
								<div>
									<h4>Your estimated hours</h4>
									<EstimatedHour>

										{/* {this.state.hour === 0 ? 'true' : 'false'} */}
										{this.state.hour || this.state.hour === 0
											? <strong>{this.state.hour}</strong>
											: <Spinner />}
										{' '}
                    hrs
									</EstimatedHour>
								</div>
								<ProgressHourWrap>
									{this.state.hour || this.state.hour === 0
										? <ProgressHour
											style={{
												width: (Math.floor(this.state.hour) / 40) * 100 + '%'
											}}
										></ProgressHour>
										: <ProgressLoader />}
								</ProgressHourWrap>
							</EstimatedHoursWrap>
						</Panel.Body>
					</Panel>
					<Project
						val={this.state.selectedWeek}
						sendData={this.getWeekDetail}
						addEstimate={this.handleMenuClick}
						addDeliverable={this.handleDeliverableClick}
						profile={false}
						menuOpenStatus={this.state.menuOpen}
						deliverableOpenStatus={this.state.deliverableOpen}
						isMenuOpenOrClosed={this.props.isMenuOpenOrClosed}
					/>
				</InnerTemplate>
			</Template>
		);
	}
}

const Template = styled.div`
	padding-bottom: 60px;
	position: relative;
`;

const InnerTemplate = styled.div`
	margin: 0 auto;
	padding: 150px 0 40px;
	position: relative;
	width: 924px;
	.panel-title {
		align-items: flex-end;
		display: flex;
		.week-title {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			margin-right: 16px;
			h1 {
				color: #fff;
				display: flex;
				font-size: 72px;
				font-weight: 300;
				line-height: 68px;
				margin-bottom: 0;
			}
			span {
				color: #fff;
				font-weight: 300;
				font-size: 10px;
				opacity: 0.5;
			}
		}
	}
`;

const WeekRange = styled.div`
	align-items: flex-start;
	color: rgba(255, 255, 255, 0.5);
	display: flex;
	font-size: 30px;
	font-weight: 700;
	margin-left: 8px;
	text-transform: uppercase;
	span {
		font-size: 10px;
		font-weight: 400;
		margin: 0 3px;
		text-align: center;
		position: relative;
		display: block;
		/* display: flex;
		flex-direction: column;
		justify-content: space-between; */
		.indicate-today {
			position: absolute;
			font-size: 6px;
			font-weight: 700;
			margin: 0;
			top: -10px;
			right: 12px;
		}
		.day-wrap {
			font-size: 8px;
			width: 37px;
			height: 37px;
			margin-top: 6px;
		}
		.indicate-leave {
			background-image: linear-gradient(221deg, #ff6200, #db2b2b);
			border-radius: 50%;
		}
		.indicate-leave-first {
			border-radius: 50%;
			background-image: linear-gradient(-45deg, #464a53 50%, #e84021 50%);
		}
		.indicate-leave-second {
			border-radius: 50%;
			background-image: linear-gradient(135deg, #464a53 50%, #e84021 50%);
		}
		.in-hours {
			width: 35px;
			height: 16px;
			line-height: 14px;
			background: rgba(255, 255, 255, 0.1);
			border: 1px solid transparent;
			border-radius: 8px;
			color: #fff;
			margin-top: 4px;
			&.err {
				background: #f3405c;

				span {
					visibility: hidden;
					width: 232px;
					height: 113px;
					box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.5);
					background-color: #ffffff;
					color: #333333;
					text-align: left;
					font-size: 12px;
					line-height: 18px;
					padding: 22px 26px;
					z-index: 2;
					position: relative;
					margin-top: 10px;
					border-radius: 8px;
					text-transform: none;
					&::before {
						content: '';
						width: 0;
						height: 0;
						border-left: 10px solid transparent;
						border-right: 10px solid transparent;
						border-bottom: 10px solid #fff;
						position: absolute;
						top: -9px;
						left: 15px;
					}
				}

				&:hover {
					cursor: default;
					border: 1px solid #fff;
					span {
						visibility: visible;
					}
				}
			}
		}
		.empty-span {
			width: 35px;
			height: 16px;
			margin-top: 4px;
		}
		img {
			margin-top: 7px;
		}

		strong {
			display: block;
			font-size: 12px;
			padding-top: 3px;
    }
    
    &.hr-loader {
      border: 0px;
      animation-duration: 2s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: hourShimmer;
      animation-timing-function: linear;
      background-color: #6b6c70;
      background-image: linear-gradient(90deg, #6B6C70 4.33%, #575757 41.75%, #6B6C70 80.77%);
      background-repeat: no-repeat;
      background-size: 87px 28px;
      position: relative;
    }

    @keyframes hourShimmer {
      0% {
        background-position: calc(0% - 300px) 0;
      }

      20%{
        background-position: calc(0% - 300px) 0;
      }

      80% {
        background-position: calc(100% + 300px) 0;
      }

      100% {
        background-position: calc(100% + 300px) 0;
      }
    }
	}
`;

const HolidayWrap = styled.div`
	align-items: center;
	display: flex;
	img {
		background-color: #ddd;
		border-radius: 50%;
		/* margin-right: 6px; */
		height: 37px;
		overflow: hidden;
		width: 37px;
	}
	.holiday-name {
		color: #fff;
		display: block;
		font-size: 12px;
		font-weight: 700;
	}
	.holiday-date {
		color: rgba(255, 255, 255, 0.5);
		display: block;
		font-size: 10px;
		line-height: 18px;
	}
	.out-of-office {
		background: #494f61;
		border-radius: 50%;
		height: 37px;
		line-height: 30px;
		margin-top: 5px;
		width: 37px;
		img {
			background: transparent;
			border-radius: 0;
			height: auto;
			margin: 0;
			width: 60%;
		}
		&.trip {
			line-height: 34px;
			img {
				width: 54%;
			}
		}
	}
`;

const EstimatedHoursWrap = styled.div`
	margin-bottom: 22px;
	> div {
		align-items: center;
		color: rgba(255, 255, 255, 0.5);
		display: flex;
		font-size: 14px;
		justify-content: space-between;
		position: relative;
		h4 {
			font-size: 14px;
			margin: 0;
			text-transform: uppercase;
		}
	}
`;

const EstimatedHour = styled.span`
	font-size: 14px;
	strong {
		color: #fff;
		font-size: 30px;
	}
`;

const ProgressHourWrap = styled.div`
  position: relative;
  background: rgba(242, 103, 34, 0.2);
  border-radius: 5px;
  height: 3px;
  width: 100%;
  overflow: hidden;
`;

const ProgressLoader = styled.div`
  position: absolute;
  width: 27px;
  height: 3px;
  background-color: #F26722;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loading;
  animation-timing-function: linear;
  
  @keyframes loading {
    0% {
      left: 0;
      transform: translateX(-100%);
    }

    20% {
      left: 0;
      transform: translateX(-100%);
    }

    80% {
      left: 100%;
      transform: translateX(0%);
    }

    100% {
      left: 100%;
      transform: translateX(0%);
    }
  }
`;

const ProgressHour = styled.div`
	background: #f26722;
	border-radius: 5px;
	height: 3px;
`;
const tryRequire = imageName => {
	try {
		return require(`./../../images/holidays/${imageName}.svg`);
	} catch (err) {
		return null;
	}
};
const Image = props => {
	if (props.name) {
		return (
			<img
				src={
					tryRequire(props.name)
						? require(`./../../images/holidays/${props.name}.svg`)
						: require(`./../../images/holidays/holiday.svg`)
				}
				alt={props.name}
				title={props.name}
			/>
		);
	}
};

Main.propTypes = {
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	userLeaves: state.leave.userLeaves,
	userAttendance: state.user.userAttendanceDetail,
	projectList: state.project.projectList
});

const mapDispatchToProps = dispatch => ({
	getUserAttendance: weekId => {
		dispatch(UserAction.attendanceFetching);
		UserService.fetchUserAttendance(weekId)
			.then(response => {
				if (response.success) {
					dispatch(UserAction.attendanceFetched(response));
				} else {
					dispatch(UserAction.attendanceError(response));
				}
			})
			.catch(err => dispatch(UserAction.attendanceError(err)));
	},
	listUserProjects: weekId => {
		dispatch(ProjectAction.userProjectFetching);
		// ProjectApi.listUserWeekProject({ weekId: weekId })
		//   .then((response) => {
		//     if (response.success) {
		//       dispatch(ProjectAction.userProjectFetched(response));
		//     } else {
		//       dispatch(ProjectAction.userProjectError(response));
		//     }
		//   })
		//   .catch((err) => {
		//     dispatch(ProjectAction.userProjectError(err));
		//   });
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Main));
