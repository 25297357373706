import React, { Component } from "react";
import styled from "styled-components";

import ProjectApi from "../../services/ProjectApi";

import FlashIcon from "../../images/flash.png";

const tips = [
  { key: "N", action: "for adding a new task" },
  { key: "L", action: "for leave application" },
  { key: "← or →", action: "to navigate through weeks" },
  { key: "D", action: "for adding a new deliverable" },
];

class ProTip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tip: { key: "", action: "" },
      ran: "",
    };
  }

  async componentDidMount() {
    let d = this.listProject(this.props.val);
    let r;
    await d.then((projects) => {
      projects.projectsForDeliverable &&
      projects.projectsForDeliverable.length > 0
        ? (r = Math.floor(Math.random() * 4))
        : (r = Math.floor(Math.random() * 3));
    });
    this.setState({
      tip: {
        key: tips[r].key,
        action: tips[r].action,
      },
      ran: r,
    });
  }

  async listProject(val) {
    if (val && val.weekNo && val.year) {
      return ProjectApi.listProjects();
    }
  }

  render() {
    const { key, action } = this.state.tip;

    if (this.state.ran == 2) {
      return (
        <Wrapper>
          {action.length > 0 ? (
            <ProTipContainer>
              <span className='protip-span'>
                Pro Tip: Press <span className="key">←</span> or
                <span className="key">→</span> to navigate through weeks{" "}
              </span>
            </ProTipContainer>
          ) : (
            ""
          )}
        </Wrapper>
      );
    } else
      return (
        <Wrapper>
          {action.length > 0 ? (
            <ProTipContainer>
              <span className='protip-span'>
                Pro Tip: Press <span className="key">{key}</span> {action}{" "}
              </span>
            </ProTipContainer>
          ) : (
            ""
          )}
        </Wrapper>
      );
  }
}

const Wrapper = styled.div`
  position: fixed;
  left: 390px;
  top: 22px;
  color: #c1d1e3;
  z-index: 100;
  font-size: 12px;
  line-height: 16px;
`;

const ProTipContainer = styled.div`
  position: relative;
  &:before {
    content: "";
    background: url(${FlashIcon}) no-repeat left;
    height: 16px;
    left: -16px;
    position: absolute;
    width: 20px;
  }
  .key {
    color: white;
    font-size: 14px;
  }
`;

export default ProTip;
