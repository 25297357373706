import React, { Component } from 'react';
import styled from "styled-components";
import AnnualIcon from '../../images/ic-annual-leave.svg';
import SickIcon from '../../images/ic-sick-leave.svg';
import MenstrualIcon from '../../images/menstrualleave.svg';
import moment from 'moment';
import ProgressBar from '../../components/layout/ProgressBar';
import { connect } from 'react-redux';

class LeaveStatsView extends Component {
    	constructor(props) {
            super(props);
            const date = moment(new Date());
            this.state = {
                filter: 'all',
                leaveError: false,
                leaveList: [],
                userLeaves: [],
                selectedOption: {
                    value: date.month(),
                    label: date.format('MMMM').toUpperCase()
                }
            };
        }    

    	static getDerivedStateFromProps(nextProps, prevState) {
    	    let data = {};
    	    if (nextProps.auth !== prevState.auth) {
    	        data.auth = nextProps.auth;
    	    }
    	    if (nextProps.holidays !== prevState.holidays) {
    	        data.holidays = nextProps.holidays;
    	    }
    	    if (nextProps.userLeaves !== prevState.userLeaves) {
    	        data.userLeaves = nextProps.userLeaves;
    	    }

    	    if (
    	        Object.keys(nextProps.leaveTypes).length &&
    	        nextProps.leaveTypes !== prevState.leaveTypes
    	    ) {
    	        data.leaveTypes = nextProps.leaveTypes.leaveTypes;
    	    }
			data.user = nextProps.user.userDetail.userDetail;

    	    return data;
    	}

    render() {
        const {
            filter,
            selectedOption,
            userLeaves,
            leaveTypes,
			user
        } = this.state;

        const leaveList = [],
            showLeaves = [];
        let totalSickLeave = 0,
            sickLeaveTaken = 0,
            totalAnnualLeave = 0,
            annualLeaveTaken = 0,
			totalMenstrualLeave = 0,
			menstrualLeaveTaken = 0;
        if (leaveTypes) {
			leaveTypes.forEach(l => {
				totalSickLeave =
					l.leave_setting.leaveType === 'Sick Leave'
						? l.noOfDays
						: totalSickLeave;
				totalAnnualLeave =
					l.leave_setting.leaveType === 'Annual Leave'
						? l.noOfDays + l.accumulated
						: totalAnnualLeave;
				totalMenstrualLeave =
					l.leave_setting.leaveType === 'Menstrual Leave'
						? l.noOfDays
						: totalMenstrualLeave;
			});
        }
        if (userLeaves.userLeaves) {
			userLeaves.userLeaves.map(l => {
				let date = moment(l.from);
				let month = date.format('MMM').toLowerCase();
				let day = date.format('D');
				let monthVal = date.month();

				sickLeaveTaken =
					l['user_leave_type.leave_setting.leaveType'] === 'Sick Leave' &&
					l.approvedStatus === 'approved'
						? sickLeaveTaken + l.days
						: sickLeaveTaken;
				menstrualLeaveTaken =
					l['user_leave_type.leave_setting.leaveType'] === 'Menstrual Leave' &&
					l.approvedStatus === 'approved'
						? menstrualLeaveTaken + l.days
						: menstrualLeaveTaken;
				annualLeaveTaken =
					l['user_leave_type.leave_setting.leaveType'] === 'Annual Leave' &&
					l.approvedStatus === 'approved'
						? annualLeaveTaken + l.days
						: annualLeaveTaken;
				leaveList.push({
					month: month,
					day: parseInt(day),
					period: l.halfDayPeriod ? l.halfDayPeriod : 'full'
				});

				if (
					(selectedOption.value === monthVal && l.approvedStatus === filter) ||
					(selectedOption.value === monthVal && filter === 'all')
				) {
					showLeaves.push({
						id: l.id,
						month: month,
						day: parseInt(day),
						period: l.halfDayPeriod ? l.halfDayPeriod : 'full',
						type: l['user_leave_type.leave_setting.leaveType'],
						noOfDays: l.days,
						status: l.approvedStatus,
						appliedOn: l.createdAt,
						approvedOn: l.updatedAt
					});
				}
			});
		}
        return (
            <StatsBlock>
                <LeaveStatLabelWrap className="leave-stat">
                    <div className="leave-icon-container">
                        <img src={SickIcon} alt="sick-leave" />
                    </div>
                    <div className="progress-block">
                        <LeaveStatLabel className="leave-stat-labels">
                            <span className="leave-label">SICK LEAVE remaining</span>
                            <span className="days">
                                {totalSickLeave - sickLeaveTaken}
                                <span className="days-small">{`/${totalSickLeave} days`}</span>
                            </span>
                        </LeaveStatLabel>
                        <ProgressBar
                            color={'163, 122, 255'}
                            width={
                                ((totalSickLeave - sickLeaveTaken) / totalSickLeave) * 100
                            }
                        />
                    </div>
                </LeaveStatLabelWrap>
                <LeaveStatLabelWrap className="leave-stat">
                    <div className="leave-icon-container">
                        <img src={AnnualIcon} alt="annual-leave" />
                    </div>
                    <div className="progress-block">
                        <LeaveStatLabel className="leave-stat-labels">
                            <span className="leave-label">ANNUAL LEAVE remaining</span>
                            <span className="days">
                                {totalAnnualLeave - annualLeaveTaken}
                                <span className="days-small">{`/${totalAnnualLeave} days`}</span>
                            </span>
                        </LeaveStatLabel>
                        <ProgressBar
                            color={'255, 182, 68'}
                            width={
                                ((totalAnnualLeave - annualLeaveTaken) / totalAnnualLeave) *
                                100
                            }
                        />
                    </div>
                </LeaveStatLabelWrap>
				{ user.gender === 'f' &&
					<LeaveStatLabelWrap className="leave-stat">
						<div className="leave-icon-container">
							<img src={MenstrualIcon} alt='menstrual-leave' />
						</div>
						<div className="progress-block">
							<LeaveStatLabel className="leave-stat-labels">
								<span className="leave-label">MENSTRUAL LEAVE remaining</span>
								<span className="days">
                                {totalMenstrualLeave - menstrualLeaveTaken}
									<span className="days-small">{`/${totalMenstrualLeave} days`}</span>
                            </span>
							</LeaveStatLabel>
							<ProgressBar
								color={'255, 182, 68'}
								width={
									((totalMenstrualLeave - menstrualLeaveTaken) / totalMenstrualLeave) *
									100
								}
							/>
						</div>
					</LeaveStatLabelWrap>
				}
            </StatsBlock>
        );
    }
}


const mapStateToProps = state => ({
    userLeaves: state.leave.userLeaves, 
    // holidays: state.holiday.holidays,
    leaveTypes: state.leave.leaveTypes,
	user: state.user
});

export default connect(
    mapStateToProps
)(LeaveStatsView);

const StatsBlock = styled.div `
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-bottom: 40px;
    .leave-days-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
	.leave-stat {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 300px;
		display: flex;
		align-items: center;
		margin-right: 40px;
		.leave-icon-container {
			width: 44px;
			height: 44px;
			border-radius: 4px;
			background-color: #ffffff;
			display: flex;
			justify-content: center;
			img {
				width: 24px;
			}
		}
	}
	.progress-block {
		.leave-stat-labels {
			display: flex;
			justify-content: space-between;
			align-items: baseline;
			.leave-label {
				font-size: 10px;
				color: #fff;
				text-transform: uppercase;
			}
			.days {
				color: #fff;
				font-size: 40px;
				font-weight: bold;
				line-height: 22px;
				.days-small {
					font-size: 9px;
					color: rgba(255, 255, 255, 0.5);
				}
			}
		}
    }
`

const LeaveStatLabelWrap = styled.div `
	display: flex;
	justify-content: space-between;
	width: 300px;
	.leave-icon-container {
		width: 44px;
		height: 44px;
		border-radius: 4px;
		background-color: #ffffff;
		display: flex;
		justify-content: center;
		margin-right: 8px;
		img {
			width: 24px;
		}
	}
`;

const LeaveStatLabel = styled.div `
	display: flex;
	justify-content: space-between;
	align-items: center;
	.leave-label {
		font-size: 10px;
		color: #ffffff;
		text-transform: uppercase;
	}
	.days {
		font-size: 22px;
		font-weight: bold;
		color: #ffffff;
		.days-small {
			font-size: 9px;
			color: rgba(255, 255, 255, 0.5);
		}
	}
`;