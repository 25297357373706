import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Main from '../../components/project/Main';
import WeekApi from '../../services/WeekApi';
import WeekLists from '../../components/layout/WeekList';
import ProTip from '../../components/layout/ProTip';

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isActive: true,
			isMenuOpenOrClosed: false,
		};

		this.handleTabSelect = this.handleTabSelect.bind(this);
	}

	componentDidMount() {
		let { weekNo, year } = this.props.match.params;
		if (!year) {
			year = null
		}
		this.setState({ weekNo, year }, () => this.getWeekData());
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let data = {}
		if (nextProps.auth !== prevState.auth) {
			data.auth = nextProps.auth;
			return data;
		}
		return null;
	}

	getWeekData() {
		const { weekNo, year } = this.state;

		WeekApi.listWeeksByYear({ year: year ? year.toString() : null })
			.then(response => {
				this.setState({ weekList: response.data.weeks });

				let selectedWeek = response.data.weeks.find(week => week.isCurrentWeek);

				if (weekNo)
					selectedWeek = response.data.weeks.find(
						week => +weekNo === +week.weekNo
					);

				this.setState({ selectedWeek });
			})
			.catch(err => {
				toastr.error('', err.error);
			});
	}

	handleTabSelect(week) {
		this.setState({ selectedWeek: week, isActive: true });
		this.props.history.push(`/dashboard/${week.weekNo}/${week.year}`);
	}

	/**
	 * Disable shortcut if menu/delivarable is opened
	 */
	forKeyShortcut = (n) => {
		this.setState(prevState => ({
			isMenuOpenOrClosed: !prevState.isMenuOpenOrClosed
		}));
	}

	render() {
		if (!this.state.weekList) return <p>Loading Data...</p>;


		return (
			<WeekContainer className="nav-tabs">
				{this.state.selectedWeek ?
					<ProTip
						val={this.state.selectedWeek}
					/> : ''
				}
				<WeekLists
					handleTabSelect={this.handleTabSelect}
					selectedWeek={this.state.selectedWeek}
					isActive={this.state.isActive}
					year={this.state.year}
					shortcutKey={this.forKeyShortcut}
					isMenuOpenOrClosed={this.state.isMenuOpenOrClosed}
				/>
				<Main
					handleTabSelect={this.handleTabSelect}
					val={this.state.selectedWeek}
					weekList={this.state.weekList}
					year={this.state.year}
					shortcutKey={this.forKeyShortcut}
					isMenuOpenOrClosed={this.state.isMenuOpenOrClosed}
				/>
			</WeekContainer>
		);
	}
}

Dashboard.propTypes = {
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth
});

const WeekContainer = styled.div`
	border-bottom: 0;
	.confetti{
		width: 100%
	}
`;

export default connect(mapStateToProps)(Dashboard);
