import React, { Component, Fragment } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';

import './App.css';

import { setCurrentUser, userLogInData } from './services/Auth';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';

import store from './store';

import Login from './components/auth/Login';
import Header from './components/auth/Header';
import Dashboard from './screens/dashboard/Index';
import PrivateRoute from './components/private-route/PrivateRoute';
import Leave from './screens/leave/Index';
import LeaveStats from './screens/leave/Stats';
import Profile from './screens/profile/Index';
import WorkingFromHome from './screens/workingFromHome/Index';
import OfficialTrip from './screens/officialTrip/Index';
import ProfileEdit from './screens/profile/ProfileEdit';
import People from './screens/people/Index';
import Project from './screens/project/Index';
// import SnowStorm from 'react-snowstorm';
// import snowstorm from 'react-snowstorm/lib/snowstorm';

let cookieToken = Cookies.get('sombarToken');
if (localStorage.sombarToken && cookieToken) {
	let parsedToken = JSON.parse(localStorage.sombarToken);
	let { token } = parsedToken;
	let { timestamp } = parsedToken;
	let today = new Date();
	today.setHours(0, 0, 0, 0)
	userLogInData();
	let previousDate = new Date(timestamp);
	previousDate.setDate(previousDate.getDate() + 7);

	let fullDateToday = today.getTime();
	let fullPreviousDate = previousDate.getTime();
	let tomorrowDate = new Date(timestamp);
	tomorrowDate.setDate(tomorrowDate.getDate() + 1);
	tomorrowDate.setHours(0, 0, 0, 0)
	let fullNextDate = tomorrowDate.getTime();

	if (fullDateToday <= fullPreviousDate) {
		const decoded = jwt_decode(token);
		store.dispatch(setCurrentUser(decoded));
	} else {
		localStorage.removeItem('sombarToken');
		Cookies.remove('sombarToken');
	}
	if (fullNextDate <= fullDateToday) {
		localStorage.removeItem('removeConfetti');
		localStorage.removeItem('hoverBell')
	}
}

class App extends Component {
	render() {
		return (
			<Provider store={store}>
				{/* <SnowStorm
					followMouse={false}
					snowCharacter={'❅'}
					flakeWidth={30}
					flakeHeight={30}
					flakesMax={200}
					vMaxY={1}
					vMaxX={1}
				/> */}
				<div>
					<ReduxToastr
						timeOut={4000}
						newestOnTop={false}
						preventDuplicates
						position='bottom-left'
						transitionIn='fadeIn'
						transitionOut='fadeOut'
						progressBar={false}
						closeOnToastrClick
					/>
				</div>
				<BrowserRouter basename={process.env.PUBLIC_URL}>
					<Switch>
						<Route exact path='/' component={Login} />
						<Fragment>
							<Header />
							<PrivateRoute exact path={['/dashboard', '/dashboard/:add']} component={Dashboard} />
							<PrivateRoute
								exact
								path={['/dashboard/:weekNo/:year', '/dashboard/:weekNo/:year/:add']}
								component={Dashboard}
							/>
							<PrivateRoute exact path='/profile' component={Profile} />
							<PrivateRoute exact path='/profile/:userId' component={Profile} />
							<PrivateRoute
								exact
								path='/edit/profile'
								component={ProfileEdit}
							/>

							<PrivateRoute exact path='/leave' component={Leave} />
							<PrivateRoute exact path='/leave/stats' component={LeaveStats} />
							<PrivateRoute
								exact
								path='/workingfromhome'
								component={WorkingFromHome}
							/>
							<PrivateRoute exact path='/trips' component={OfficialTrip} />
							<PrivateRoute exact path='/people' component={People} />
							<PrivateRoute exact path='/projects' component={Project} />
						</Fragment>
					</Switch>
				</BrowserRouter>
			</Provider>
		);
	}
}

export default App;
