import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
// import Select from 'react-select';
import { Tab, Tabs } from 'react-bootstrap';
import queryString from 'query-string';

import Background from '../../components/Background';
import WeekLists from '../../components/layout/WeekList';

import WeekStatus from '../../components/people/WeekStatus';
import AdvancedFilter from '../../components/people/Filter';
import UserDetailCard from '../../components/people/UserDetailCard';
import Shimmer from '../../components/layout/ProjectShimmer';

import * as UserAction from '../../actions/user';
import UserService from '../../services/UserApi';

import WorkFromHomeIcon from '../../images/ic-work-home.svg';
import TripIcon from '../../images/ic-official-trip.svg';
import AnnualIcon from '../../images/ic-annual-leave.svg';
import SickIcon from '../../images/ic-sick-leave.svg';
import MenstrualIcon from '../../images/menstrualleave.svg';
import MaternityIcon from '../../images/maternity-paternity.svg';
import OfficialEventIcon from '../../images/podium.svg';
import OfficialTripIcon from '../../images/ic-official-trip.svg';
import Filter from '../../images/filter.svg';
import Reset from '../../images/reset.svg';
import BirthdayIcon from '../../images/birthday-cake.svg';
import SearchIcon from '../../images/search.svg';

const clusters = [
	{ value: '0', label: 'All Users' },
	{ value: '7', label: 'Undefined Cluster' },
	{ value: '1', label: 'Cluster 1' },
	{ value: '2', label: 'Cluster 2' },
	{ value: '3', label: 'Cluster 3' },
	{ value: '4', label: 'Cluster 4 (Israel)' },
	{ value: '5', label: 'Cluster 5 (Policy Lab)' },
	{ value: '6', label: 'Cluster 6 (Admin)' }
]

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			key: 1,
			isActive: true,
			cluster: { value: '', label: 'All Users' },
			filters: { q: '' },
			currentUser: '-1'
		};
		this.handleTabSelect = this.handleTabSelect.bind(this);
	}
	componentDidMount() {
		this.props.fetchPeopleInformation();
		const { cluster } = this.props.authenticatedUser.user;
		let qs = queryString.parse(this.props.location.search)

		if (qs && Object.keys(qs).length > 0) {
			this.setState({
				tabKey: qs.cluster,
				filters: qs
			});
			if (qs.bloodGroup || qs.projects || qs.hours) {
				this.advancedFilterOptions();
			}
		} else {
			if (cluster) {
				this.setState({
					cluster: {
						value: cluster,
						label: clusters.find(c => c.value == cluster).label
					},
					tabKey: String(clusters.find(c => c.value == cluster).value),
					filters: { cluster: String(clusters.find(c => c.value == cluster).value) }
				});
				qs['cluster'] = String(clusters.find(c => c.value == cluster).value)
			} else {

				this.setState({
					cluster: { value: '', label: 'All Users' },
					tabKey: '0',
					filters: { cluster: '0' }
				});
				qs['cluster'] = '0'
			}
			this.props.history.push(this.props.location.pathname + '?' + queryString.stringify(qs))
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let data = {};
		if (nextProps.people.users !== prevState.users) {
			data.users = nextProps.people.users
		}
		return data;
	}

	handleTabSelect(week) {
		this.setState({ selectedWeek: week, isActive: true });
		this.props.history.push(`/dashboard/${week.weekNo}/${week.year}`);
	}

	setLeaveStatus = (leave) => {
		let daysF = ''
		if (leave.length > 0) {
			let sortLeave = [...leave];
			sortLeave.sort(function compare(a, b) {
				var dateA = new Date(a.date);
				var dateB = new Date(b.date);
				return dateA - dateB;
			});
			if (leave.length === 5) {
				return String(5)
			} else {
				sortLeave.map((s, i) => {
					daysF = daysF + days[new Date(s.date).getDay()] +
						(i === sortLeave.length - 1 ? ' ' : ', ');
				})
				return daysF
			}
		}
	}

	peopleInfo = (d, i) => {
		let sick_leaves = '',
			menstrual_leaves = '',
			annual_leaves = '',
			paternity_leaves = '',
			wfh_leaves = '',
			official_trips = '',
			official_events = '';
		if (d.weekLeaves) {
			sick_leaves = this.setLeaveStatus(d.weekLeaves.sick_leave);
			menstrual_leaves = this.setLeaveStatus(d.weekLeaves.menstrual_leave);
			annual_leaves = this.setLeaveStatus(d.weekLeaves.annual_leave);
			paternity_leaves = this.setLeaveStatus(d.weekLeaves.paternity_leave);
		}

		if (d.weekOfficialTrips) {
			official_trips = this.setLeaveStatus(d.weekOfficialTrips.official_trip);
			official_events = this.setLeaveStatus(d.weekOfficialTrips.official_event);
		}

		if (d.weekWorkingFromHome) {
			wfh_leaves = this.setLeaveStatus(d.weekWorkingFromHome);
		}
		let userDob = false;
		let birth = new Date(d.dob);
		let birthMonth = birth.getMonth();
		let birthDay = birth.getDate();
		let todayDate = new Date();
		let todayMonth = todayDate.getMonth();
		let todayDay = todayDate.getDate();

		if (birthMonth === todayMonth && birthDay === todayDay) {
			userDob = true;
		}

		return (
			<Link
				to={`/profile/${d.id}`}
				style={{ color: 'rgb(0, 0, 0,0.8)', textDecoration: 'none' }}
			>
				<div className={!d.inTime && !d.todayStatus ? 'row__people not-checked-in' : 'row__people'} key={i}>
					<div
						className={parseInt(this.state.currentUser) === i ? "user-info active" : "user-info"}
						onMouseEnter={() => this.setState({ currentUser: i })}
						onMouseLeave={() => this.setState({ currentUser: '-1' })}
					>
						{/* <img
								src={ArrowUp}
								className="arrow-icon"
							/> */}
						<UserDetailCard
							user={d}
						/>
					</div>
					<div className='people-info'>

						<div className='people-photo'
							onMouseEnter={() => this.setState({ currentUser: i })}
							onMouseLeave={() => this.setState({ currentUser: '-1' })}
						>
							<div className='people-photo-wrapper'>
								<img src={d.profilePic} alt={d.firstName} width='47' height='47' key={d.firstName} />{' '}

							</div>
						</div>
						<div className='people-detail'>
							<div className='people-name'
								onMouseEnter={() => this.setState({ currentUser: i })}
								onMouseLeave={() => this.setState({ currentUser: '-1' })}
							>
								<strong>
									{`${d.firstName}  ${d.lastName}`}
								</strong>
								{
									userDob ?
										<img src={BirthdayIcon} alt="birthday" className="birthday-not-ic" /> : ''
								}
								{/* <em className="designation">{d.role}</em> */}
							</div>
							<div className='in-hours'>
								{d.inTime ? (
									<span className='status status--present'>Present</span>
								) : (
									<span className='status status--absent'>Absent</span>
								)}
								{d.inTime ? (
									<em>
										{`Present (In at ${moment(d.inTime).format('HH:mm')} am today)`}{' '}
										{d.leave}{' '}
										{
											d.todayStatus && d.todayStatus === 'Annual Leave' ?
												<img src={AnnualIcon} title="Annual Leave" alt="annual" className="user-status-icon" /> : d.todayStatus === 'Sick Leave' ?
													<img src={SickIcon} title="Sick Leave" alt="sick" className="user-status-icon" /> : d.todayStatus === 'Menstrual Leave' ?
														<img src={MenstrualIcon} title="Menstrual Leave" alt="menstrual" className="user-status-icon" /> : d.todayStatus === 'Paternity/Maternity Leave' ?
															<img src={MaternityIcon} title="Paternity/Maternity Leave" alt="paternity/maternity" className="user-status-icon" /> : d.todayStatus === 'Official Trip' ?
																<img src={OfficialTripIcon} title="Official Trip" alt="official trip" className="user-status-icon" /> : d.todayStatus === 'Official Event' ?
																	<img src={OfficialEventIcon} title="Official Event" alt="official event" className="user-status-icon" /> : d.todayStatus === 'Work From Home' ?
																		<img src={WorkFromHomeIcon} title="Work From Home" alt="wfh" className="user-status-icon" /> :
																		''
										}
									</em>
								) : d.officialTrip ? (
									<Fragment>
										<em>
											{`Out on an Offical ${d.officialTrip === 'officialTrip' ? 'Trip' : 'Event'
												}`}
											<img
												src={d.officialTrip === 'officialTrip' ? TripIcon : OfficialEventIcon}
												alt='officialtrip'
												className="user-status-icon"
											/>
										</em>
									</Fragment>
								) : d.isWorkingFromHome ? (
									<Fragment>
										<em>Working from home
											<img
												src={WorkFromHomeIcon}
												alt='workingfromhome'
												className="user-status-icon"
											/>
										</em>
									</Fragment>
								) : d.leave ? (
									<em>
										{`On ${d.leave}`}{' '}
										{
											d.todayStatus && d.todayStatus === 'Annual Leave' ?
												<img src={AnnualIcon} title="Annual Leave" alt="annual" className="user-status-icon" /> : d.todayStatus === 'Sick Leave' ?
													<img src={SickIcon} title="Sick Leave" alt="sick" className="user-status-icon" /> : d.todayStatus === 'Menstrual Leave' ?
														<img src={MenstrualIcon} title="Menstrual Leave" alt="menstrual" className="user-status-icon" /> : d.todayStatus === 'Paternity/Maternity Leave' ?
															<img src={MaternityIcon} title="Paternity/Maternity Leave" alt="paternity/maternity" className="user-status-icon" /> : d.todayStatus === 'Official Trip' ?
																<img src={OfficialTripIcon} title="Official Trip" alt="official trip" className="user-status-icon" /> : d.todayStatus === 'Official Event' ?
																	<img src={OfficialEventIcon} title="Official Event" alt="official event" className="user-status-icon" /> : d.todayStatus === 'Work From Home' ?
																		<img src={WorkFromHomeIcon} title="Work From Home" alt="wfh" className="user-status-icon" /> :
																		''
										}
									</em>
								) : (
									<em>Hasn't Arrived Yet/not checked in</em>
								)}
							</div>
						</div>
					</div>
					<div className='project-involvement'>
						<div className="project-detail">
							<span>
								{d.projects > 1 ? (
									<span>
										<strong>{d.projects}</strong> projects
									</span>
								) : d.projects === 0 ? (
									<span>
										<strong>
											{d.projects}
										</strong>{' '}
										project
									</span>
								) : (
									<span>
										<strong>{d.projects}</strong> project
									</span>
								)}
								&nbsp;for{' '}
								{d.hours > 1 ? (
									<span>
										<strong>{d.hours}</strong> hours
									</span>
								) : d.hours === 0 ? (
									<span>
										<strong>
											{d.hours}
										</strong>{' '}
										hour
									</span>
								) : (
									<span>
										<strong>{d.hours}</strong> hour
									</span>
								)}{' '}
								this week
							</span>
						</div>
						<div className="week-detail">
							{d.weekLeaves && d.weekLeaves.paternity_leave.length > 0 ?
								<StatusDiv icon={MaternityIcon} alt="Paternity Leave" day={paternity_leaves} /> : ''
							}
							{d.weekOfficialTrips && d.weekOfficialTrips.official_trip.length > 0 ?
								<StatusDiv icon={TripIcon} alt="Official Trip" day={official_trips} /> : ''
							}
							{d.weekOfficialTrips && d.weekOfficialTrips.official_event.length > 0 ?
								<StatusDiv icon={OfficialEventIcon} alt="Official Event" day={official_events} /> : ''
							}
							{d.weekWorkingFromHome && d.weekWorkingFromHome.length > 0 ?
								<StatusDiv icon={WorkFromHomeIcon} alt="Work From Home" day={wfh_leaves} /> : ''
							}
							{d.weekLeaves && d.weekLeaves.sick_leave.length > 0 ?
								<StatusDiv icon={SickIcon} alt="Sick Leave" day={sick_leaves} /> : ''
							}
							{d.weekLeaves && d.weekLeaves.menstrual_leave.length > 0 ?
								<StatusDiv icon={MenstrualIcon} alt="Menstrual Leave" day={menstrual_leaves} /> : ''
							}
							{d.weekLeaves && d.weekLeaves.annual_leave.length > 0 ?
								<StatusDiv icon={AnnualIcon} alt="Annual Leave" day={annual_leaves} /> : ''
							}
						</div>
					</div>
				</div>
			</Link>
		);
	};

	selectedUser = e => {
		const { filters } = this.state;
		filters['q'] = e.target.value
		this.setState({
			filters,
			selectedUsers: e.target.value
		}, () => {
			this.props.history.push(this.props.location.pathname + '?' + queryString.stringify(this.state.filters))
		});
	};

	changeCluster = e => {
		this.setState({ cluster: { value: e.value, label: e.label } });
	};

	setCluster = i => {
		let filters = this.state.filters;
		filters['cluster'] = i;
		this.setState({
			filters,
			tabKey: i
		}, () => {
			this.props.history.push(this.props.location.pathname + '?' + queryString.stringify(this.state.filters))
		})

	}

	removeLeaveFilter = () => {
		const { filters } = this.state;
		for (let key in filters) {
			if (key !== 'cluster' && key !== 'q') {
				delete filters[key]
			}
		}
		this.setState({ filters }, () => {
			this.props.history.push(this.props.location.pathname + '?' + queryString.stringify(this.state.filters))
		})
	}

	setUserFilter = (todayLeave = '') => {
		let { filters } = this.state;
		if (todayLeave.length > 0) {
			filters['todayStatus'] = todayLeave
		}
		if (filters['weekStatus'] && filters['weekStatus'].length > 0) {
			delete filters['weekStatus']
		}
		this.setState({ filters }, () => {
			this.props.history.push(this.props.location.pathname + '?' + queryString.stringify(this.state.filters))
		})
	}

	setWeekFilter = (weekLeave = '') => {
		let { filters } = this.state;
		if (weekLeave.length > 0) {
			filters['weekStatus'] = weekLeave
		}
		if (filters['todayStatus'] && filters['todayStatus'].length > 0) {
			delete filters['todayStatus']
		}
		this.setState({ filters }, () => {
			this.props.history.push(this.props.location.pathname + '?' + queryString.stringify(this.state.filters))
		})
	}

	advancedFilterOptions = () => {
		const { displayAdvanced } = this.state;
		this.setState({ displayAdvanced: !displayAdvanced }, () => {
			this.state.displayAdvanced ?
				document.getElementById('all-people-info').classList.add('overlay-div') :
				document.getElementById('all-people-info').classList.remove('overlay-div')
		})
		document.getElementById('caret-icon').classList.toggle('display-div')
	}

	setAdvancedFilterParams = (params) => {
		let { filters } = this.state;
		if (params.bloodGroup) {
			filters['bloodGroup'] = params.bloodGroup.value
		}

		if (params.hours[1]) {
			filters['hours'] = params.hours
		} else {
			delete filters['hours']
		}

		if (params.projects[1]) {
			filters['projects'] = params.projects
		} else {
			delete filters['projects']
		}

		this.setState({
			filters,
		}, () => {
			this.props.history.push(this.props.location.pathname + '?' + queryString.stringify(this.state.filters))
			this.advancedFilterOptions()
		})
	}

	resetAdvancedFilterParams = () => {
		let { filters } = this.state;
		if (filters.bloodGroup) {
			delete filters['bloodGroup']
		}
		if (filters.hours && parseInt(filters.hours[1]) > 0) {
			delete filters['hours']
		}
		if (filters.projects && parseInt(filters.projects[1]) > 0) {
			delete filters['projects']
		}
		this.setState({
			filters,
		}, () => {
			this.props.history.push(this.props.location.pathname + '?' + queryString.stringify(this.state.filters))
		})
	}

	render() {
		const { users, displayAdvanced, tabKey, filters } = this.state;

		let filteredUser = users;
		for (let prop in filters) {
			if (prop === 'cluster' && filters[prop] !== String(0)) {
				filteredUser = filteredUser && filteredUser.filter(
					eachUser => String(eachUser[prop]) === filters[prop]
				)
			}
			if (prop === 'todayStatus' || prop === 'bloodGroup') {
				filteredUser = filteredUser && filteredUser.filter(
					eachUser => eachUser[prop] === filters[prop]
				)
			}
			if (prop === 'weekStatus') {
				filteredUser = filteredUser && filteredUser.filter(
					eachUser => {
						if (eachUser[prop] && eachUser[prop].indexOf(filters[prop]) > -1) {
							return eachUser
						}
					}
				)
			}
			if (prop === 'q' && filters['q'] && filters['q'].length > 0) {
				filteredUser = filteredUser && filteredUser.filter(
					eachUser => {
						let pattern = new RegExp(`${filters['q']}`);
						let matchedPattern =
							eachUser.firstName.toLowerCase().match(pattern) ||
							eachUser.firstName.match(pattern) ||
							eachUser.lastName.toLowerCase().match(pattern) ||
							eachUser.lastName.match(pattern);
						if (matchedPattern) {
							return eachUser;
						}
					}
				)
			}
			if (prop === 'hours' || prop === 'projects') {
				if (filters[prop][0] === '<') {
					filteredUser = filteredUser && filteredUser.filter(
						eachUser => eachUser[prop] < filters[prop][1]
					)
				}
				if (filters[prop][0] === '>') {
					filteredUser = filteredUser && filteredUser.filter(
						eachUser => eachUser[prop] > filters[prop][1]
					)
				}
				if (filters[prop][0] === '=') {
					filteredUser = filteredUser && filteredUser.filter(
						eachUser => eachUser[prop] === filters[prop][1]
					)
				}
				if (filters[prop][0] === '>=') {
					filteredUser = filteredUser && filteredUser.filter(
						eachUser => eachUser[prop] >= filters[prop][1]
					)
				}
				if (filters[prop][0] === '<=') {
					filteredUser = filteredUser && filteredUser.filter(
						eachUser => eachUser[prop] <= filters[prop][1]
					)
				}
			}
		}

		let allPeopleInfo = users;
		for (let prop in filters) {
			allPeopleInfo = allPeopleInfo && allPeopleInfo.filter(
				prop === 'cluster' && filters[prop] !== 0 ?
					eachUser => {
						return String(eachUser[prop]) === filters[prop]
					} : eachUser => true
			)
		}

		return (
			<PeopleWrapper>
				<Background />
				<WeekLists
					selectedKey={this.state.key}
					handleTabSelect={this.handleTabSelect}
					selectedWeek={this.state.selectedWeek}
					isActive={this.state.isActive}
				/>
				<PeopleApplication filteredUser={filteredUser ? filteredUser : []}>
					<h1>People</h1>
					<Tabs
						id="controlled-tab-example"
						activeKey={tabKey}
						onSelect={this.setCluster}
					>
						{
							clusters.map((c, i) => {
								return <Tab key={i} eventKey={c.value} title={c.label} />
							})
						}

					</Tabs>
					<WeekStatus
						peopleInfo={filteredUser}
						peopleFullInfo={allPeopleInfo}
						setTodayFilter={this.setUserFilter}
						setWeekFilter={this.setWeekFilter}
						filters={filters}
					/>
					{(filters['todayStatus'] || filters['weekStatus'] || filters['projects'] ||
						filters['hours'] || filters['bloodGroup']) ?
						<div className="remove-leave-filter-wrap">
							<div>
								{filters['todayStatus'] || filters['weekStatus'] ?
									<span>
										Showing people on
										<span className="leave-type"> {filters['todayStatus'] || filters['weekStatus']} </span>
										<span className="filter-type">
											{filters['todayStatus'] ? 'today.' : 'this week.'}
										</span>
									</span> : ''
								}
								{filters['projects'] || filters['hours'] || filters['bloodGroup'] ?
									<div>
										{filters['projects'] ?
											<span>
												<span> Involved in </span>
												<span className="leave-type"> {filters['projects'][0] === "=" ? '' : filters['projects'][0]} {filters['projects'][1]} projects. </span>
											</span> : ''
										}
										{filters['hours'] ?
											<span>
												<span> Estimated </span>
												<span className="leave-type"> {filters['hours'][0] === '=' ? '' : filters['hours'][0]} {filters['hours'][1]} hours </span>
												<span> in projects.</span>
											</span> : ''
										}
										{filters['bloodGroup'] ?
											<span>
												<span> Blood Group: </span>
												<span className="leave-type"> {filters['bloodGroup']}. </span>
											</span> : ''
										}
									</div> : ''
								}
							</div>
							<div className="reset" onClick={this.removeLeaveFilter}>
								<img
									src={Reset} className="reset-icon" alt="reset"
									title="remove-filters"
								/>
								<span>Reset</span>
							</div>
						</div> : ''
					}
					<form onSubmit={e => { e.preventDefault(); }} className="searchForm">
						<div className='advanced-filter-wrap'>
							<div className="search-icon-wrap">
								<img src={SearchIcon} alt="search" className="search-icon" />
							</div>
							<div className='form-group col-md-9 filter-bar'>
								<input
									type='text'
									placeholder='Search by name'
									value={filters['q'] ? filters['q'] : ''}
									onChange={this.selectedUser}
								/>
							</div>
							<div className="col-md-3 advanced-filter-opts">
								<span className="advanced-placeholder"> Advanced Filters </span>
								<span
									className="advanced-filter-icon-wrap"
									title="Advanced Filter"
									onClick={this.advancedFilterOptions}
								>
									<img src={Filter} className="advanced-filter-icon" />
									<b className='caret' id="caret-icon"></b>
								</span>
							</div>
						</div>
						{
							displayAdvanced ?
								<div className="col-md-6 filter-wrap">
									<AdvancedFilter
										setAdvancedFilterParams={this.setAdvancedFilterParams}
										filterParams={filters}
										resetAdvancedFilterParams={this.resetAdvancedFilterParams}
									/>
								</div> : ''
						}
					</form>

					<div className='accordion accordion__panel accordion__panel--people'>
						<div id="all-people-info" onClick={this.advancedFilterOptions}></div>
						{filteredUser ?
							filteredUser.map((d, i) => {
								return this.peopleInfo(d, i);
							}) : <Shimmer />}
					</div>
				</PeopleApplication>
			</PeopleWrapper>
		);
	}
}
const mapStateToProps = state => ({
	people: state.user.peopleDetail,
	authenticatedUser: state.auth
});

const mapDispatchToProps = dispatch => ({
	fetchPeopleInformation: () => {
		dispatch(UserAction.peopleFetching);
		UserService.getPeopleInformation()
			.then(response => {
				if (response.success) {
					dispatch(UserAction.peopleFetched(response));
				}
				dispatch(UserAction.error(response));
			})
			.catch(err => dispatch(UserAction.error(err)));
	},

	resetUserProfile: () => {
		dispatch(UserAction.fetched({ data: {} }));
	}
});

const StatusDiv = (props) => {
	return (
		<div className="leave-status">
			<img
				src={props.icon}
				alt={props.alt}
				className="status-icon"
			/>
			<span> {props.day} </span>
		</div>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);

const PeopleWrapper = styled.div``;
const PeopleApplication = styled.div`
	margin: 0 auto;
	padding: 150px 0 80px;
	position: relative;
	width: 924px;
	.select-cluster {
		width: 100%;
	}
	.accordion__panel--people {
		border: 0;
		padding: 0;
		position: relative;
		.overlay-div {
			position: absolute;
			width: 100%;
			height: ${props => props.filteredUser.length > 0 ? '100% + 40px' : '100%'};
			top: -40px;
			left: 0;
			right: 0;
			bottom: 0;
			background: linear-gradient(220.07deg, #272C39 32.74%, rgba(31, 37, 44, 0.37) 89.11%), linear-gradient(195.66deg, #2D3140 6.07%, #1B2128 38.58%);
			opacity: 0.9;
			z-index: 2;
			cursor: pointer;
		}
	}
	h1 {
		color: #fff;
		display: flex;
		font-size: 72px;
		font-weight: 300;
		margin-bottom: 40px;
	}
	#controlled-tab-example {
		>.nav-tabs {
			border-bottom: 2px solid rgba(255,255,255,0.1);
			margin-bottom: 40px;
			padding: 0;
			> li {
				a,
				a:hover,
				a:focus {
					border: 0;
					outline: 0;
				}
				a {
					color: rgba(255, 255, 255, 0.5);
					font-size: 11px;
					text-transform: uppercase;
					padding: 4px 12px;
					&:hover,
					&:focus {
						background: transparent;
					}
				}
				&.active {
					a,
					a:hover,
					a:focus {
						background: transparent;
						border-bottom: 1px solid #f26722;
						color: #fff;
						font-weight: bold;
					}
				}
			}
		}
	}

	.remove-leave-filter-wrap {
		color: rgba(255,255,255,0.5);
		display: flex;
		justify-content: space-between;
		.leave-type {
			color: #fff;
			font-weight: 600;
		}
		.reset {
			padding-left: 3px;
			cursor: pointer;
			float: right;
			font-size: 10px;
			text-transform: uppercase;
			color: #EDEDED;
			display: flex;
			align-items: center;
			.reset-icon {
				height: 12px;
				width: 12px;
				margin-right: 3px;
			}
		}
	}

	.row__people {
		border-bottom: 1px solid rgba(40, 45, 58, 0.1);
		display: flex;
		font-size: 12px;
		justify-content: space-between;
		padding: 23px 32px;
		position: relative
		&:hover {
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
		}
		.user-info {
			visibility: hidden;
			opacity: 0;
			position: absolute;
			top: 74px;
			z-index: 5;
			margin-top: 0px;
			transition: all 0.3s ease-out;
			height: auto;
		}
		.active {
			opacity: 1;
			visibility: visible;
			transition: all 0.3s ease-out;
			.arrow-icon {
				position: absolute;
				z-index: 7;
				top: -8px;
				left: 14px;
			}
		}
	}
	.not-checked-in {
		background: rgba(243, 64, 92, 0.08);
	}

	.people-info {
		display: flex;
	}

	.people-photo {
		height: 47px;
		width: 47px;
		position: relative;
		padding-right: 60px;
		.people-photo-wrapper {
			background: #ddd;
			border-radius: 50%;
			height: 47px;
			position: relative;
			width: 47px;
			overflow: hidden;
			img {
				object-fit: cover;
				object-position: top;
			}
		}
	}

	.people-name {
		font-size: 16px;
		margin-bottom: 3px;
		strong {
			margin-right: 6px;
			.user-prof-link{
				color: rgb(0, 0, 0,0.8);
				&:hover {
					text-decoration: none;
				}
			}
		}
		.birthday-not-ic {
			width: 16px;
			height: 16px;
			margin-bottom: 5px;
		}
	}

	.designation,
	.in-hours {
		color: rgba(51, 51, 51, 0.5);
		font-size: 12px;
		display: flex;
		align-items: center;
		.status {
			border-radius: 50%;
			display: block;
			height: 10px;
			text-indent: -9999px;
			top: 2px;
			width: 10px;
			margin-right: 4px
			&--present {
				background-color: #6da360;
			}
			&--absent {
				background-color: #f3405c;
			}
		}
	}

	.in-hours {
		color: rgba(51, 51, 51, 0.7);
		em {
			margin-right: 5px;
			font-style: normal;
			font-weight: normal;
			color: #333333;
		}
		.user-status-icon {
			height: 14px;
			width: 14px;
			margin-left: 2px;
		}
		img {
			margin-top: -4px;
		}
	}

	.project-involvement {
		color: #333;
		.project-detail{
			font-style: italic;
			margin-bottom: 3px;
			color: #333333;
			opacity: 0.7;
			width: auto;
			display: flex;
			justify-content: flex-end;
		}
		.week-detail{
			display: flex;
			flex-direction: row-reverse;
			margin-top: 5px;
			.leave-status{
				display: flex;
				align-items: center;
				padding: 4px 8px;
				background: #EDEDED;
				border-radius: 11px;
				margin: 0 6px;
				.status-icon{
					width: 14px;
					height: 14px;
					margin-right: 5px;
				}
			}
			&> :first-child{
				margin-right: 0px;
			}
		}
	}

	.css-2b097c-container {
		width: 100%;
		> .css-yk16xz-control,
		.css-1pahdxg-control {
			background: none;
			border-color: #51804d !important;
			> div > div {
				color: #fff;
				font-size: 14px;
			}
		}
		// svg {
		// 	fill: #fff !important;
		// }
		.css-yk16xz-control {
			border-radius: 2px;
		}
	}
	
	.searchForm {
		position: relative;
		.advanced-filter-wrap{
			position: relative;
			display: flex;
			.search-icon-wrap {
				border-bottom: 1px solid rgba(216, 216, 216, 0.3);
				height: auto;
				margin-bottom: 40px;
				display: flex;
				align-items: center;
				padding-right: 35px;
				justify-content: center;
				.search-icon {
					height: 18px;
					width: 18px;
					position: absolute;
					top: 28px;
					left: 4px;
				}
			}
			.filter-bar {
				margin-top: 20px;
				margin-bottom: 40px;
				padding: 0px;
				.search-icon {
					height: 18px;
					width: 18px;
					position: absolute;
					top: 9px;
				}
				input{
					width: 100%;
					&::placeholder {
						color: #959595;;
						margin-left: 20px;
					}
				}
			}
			.advanced-filter-opts {
				display: flex;
				margin-top: 20px;
				margin-bottom: 40px;
				padding: 0px;
				align-items: center;
				height: auto;
				border-bottom: 1px solid rgba(216, 216, 216, 0.3);
				.advanced-placeholder {
					color: #FFF;
					text-transform: uppercase;
					position: absolute;
					right: 43px;
					font-size: 10px;
				}
			}
			.advanced-filter-icon-wrap{
				position: absolute;
				top: 7px;
				right: 0px;
				cursor: pointer;
				.advanced-filter-icon {
					height: 18px;
					width: 18px;
					margin-right: 2px;
				}
				.caret {
					transition: transform 0.2s ease-in-out;
				}
				.display-div {
					transform: rotate(180deg);
				}
			}
		}
		.filter-wrap {
			position: absolute;
			right: 0;
			top: 75px;
			background: #fff;
			z-index: 5;
			border-radius: 5px;
			border: 1px solid rgba(40,45,58,0.1);
		}
	}
`;