import React, {Fragment} from 'react';
import styled from 'styled-components';

const Loader = () => {
  return (
    <Fragment>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        width="35"
        height="35"
        viewBox="0 0 128 128"
      >
        <g>
          <circle cx="16" cy="64" r="16" fill="#2c303f" />
          <circle
            cx="16"
            cy="64"
            r="16"
            fill="#72757f"
            transform="rotate(45 64 64)"
          />
          <circle
            cx="16"
            cy="64"
            r="16"
            fill="#a6a8ae"
            transform="rotate(90 64 64)"
          />
          <circle
            cx="16"
            cy="64"
            r="16"
            fill="#d5d6d9"
            transform="rotate(135 64 64)"
          />
          <circle
            cx="16"
            cy="64"
            r="16"
            fill="#e6e7e8"
            transform="rotate(180 64 64)"
          />
          <circle
            cx="16"
            cy="64"
            r="16"
            fill="#e6e7e8"
            transform="rotate(225 64 64)"
          />
          <circle
            cx="16"
            cy="64"
            r="16"
            fill="#e6e7e8"
            transform="rotate(270 64 64)"
          />
          <circle
            cx="16"
            cy="64"
            r="16"
            fill="#e6e7e8"
            transform="rotate(315 64 64)"
          />
          <animateTransform
            attributeName="transform"
            type="rotate"
            values="0 64 64;315 64 64;270 64 64;225 64 64;180 64 64;135 64 64;90 64 64;45 64 64"
            calcMode="discrete"
            dur="720ms"
            repeatCount="indefinite"
          />
        </g>
      </svg>
    </Fragment>
  );
};

export default Loader;
