import React, { Component } from 'react';
import styled from "styled-components";

class EmptyState extends Component {
    render() {
        return (
            <div>
                <EmptyStateWrapper>
                    <div className="wrapper">
                        <span>¯\_(ツ)_/¯ </span> No task has been added yet.
                    </div>
                </EmptyStateWrapper>
            </div>
        )
    }
}

const EmptyStateWrapper = styled.div`
    .wrapper {
        background-color: rgba(255,255,255, 0.2);
        border-radius: 4px;
        padding: 26px 37px 31px;
        font-size: 16px;
        line-height: 1.37;
        color: #fff;
        font-style: italic;
        span{
            margin-right: 8px;
        }
    }
`;

export default EmptyState;
