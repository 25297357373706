import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Select from 'react-select';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import * as UserAction from '../../actions/user';
import UserService from '../../services/UserApi';
import WeekLists from '../../components/layout/WeekList';
import Background from '../../components/Background';
import cameraIcon from '../../images/photo-camera.svg';
import DropZone from '../../components/DropZone';

const bloodGroupList = [
	{ label: 'A positive (A+)', value: 'A+' },
	{ label: 'A negative (A-)', value: 'A-' },
	{ label: 'B positive (B+)', value: 'B+' },
	{ label: 'B negative (B-)', value: 'B-' },
	{ label: 'O positive (O+)', value: 'O+' },
	{ label: 'O negative (O-)', value: 'O-' },
	{ label: 'AB positive (AB+)', value: 'AB+' },
	{ label: 'AB negative (AB-)', value: 'AB-' }
];

const clusterList = [
	{ value: 7, label: 'Undefined Cluster' },
	{ value: 1, label: 'Cluster-1' },
	{ value: 2, label: 'Cluster-2' },
	{ value: 3, label: 'Cluster-3' },
	{ value: 4, label: 'Cluster-4 (Israel)' },
	{ value: 5, label: 'Cluster-5 (Policy Lab)' },
	{ value: 6, label: 'Cluster-6 (Admin)' },
]

class ProfileEdit extends Component {
	constructor(props) {
		super(props);
		this.state = { showModal: false };
	}

	componentDidMount() {
		this.props.fetchUserProfile();
	}

	componentDidUpdate(nextProps, prevState) {
		if (this.state.userDetail !== prevState.userDetail) {
			const userDetail = this.state.userDetail || prevState.userDetail;
			const bloodGroup = bloodGroupList.find(
				b => b.value === userDetail.bloodGroup
			) || { label: 'A positive (A+)', value: 'A+' };
			const cluster = clusterList.find(
				c => c.value === userDetail.cluster
			)
			this.setState({ ...userDetail, bloodGroup, cluster });
			if (userDetail.emergencyContact && userDetail.emergencyContact.length) {
				const emergencyContact = userDetail.emergencyContact;
				this.setState({
					gender: userDetail.gender || 'm',

					emergencyName: emergencyContact[0],
					emergencyRelation: emergencyContact[1],
					emergencyMobile: emergencyContact[2],
					emergencyPhone: emergencyContact[3]
				});
			}
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let data = {};
		if (nextProps.user !== prevState.user) {
			data.userDetail = nextProps.user.userDetail;
		}

		return data;
	}

	// validateField = (val, label) => {
	// 	let canSubmit = true;
	// 	if (val === '') {
	// 		this.setState({ [label]: true });
	// 		canSubmit = false;
	// 	}
	// 	return canSubmit;
	// };

	handleFormSubmit = e => {
		const {
			firstName,
			middleName,
			lastName,
			email,
			bloodGroup,
			mobile,
			dob,
			gender,
			temporaryAddress,
			permanentAddress,
			emergencyName,
			emergencyRelation,
			emergencyMobile,
			emergencyPhone,
			cluster
		} = this.state;

		let canSubmit = true;

		if (firstName === '') {
			this.setState({ firstNameError: true });
			canSubmit = false;
		}
		if (lastName === '') {
			this.setState({ lastNameError: true });
			canSubmit = false;
		}
		if (!mobile || mobile === '') {
			this.setState({ mobileError: true });
			canSubmit = false;
		}
		if (!bloodGroup || bloodGroup === '') {
			this.setState({ bloodGroupError: true });
			canSubmit = false;
		}
		if (permanentAddress === '') {
			this.setState({ permanentAddressError: true });
			canSubmit = false;
		}
		if (!emergencyName || emergencyName === '') {
			this.setState({ emergencyNameError: true });
			canSubmit = false;
		}
		if (!emergencyRelation || emergencyRelation === '') {
			this.setState({ emergencyRelationError: true });
			canSubmit = false;
		}
		if (!emergencyMobile || emergencyMobile === '') {
			this.setState({ emergencyMobileError: true });
			canSubmit = false;
		}
		if (!dob || dob === '') {
			this.setState({ dobError: true });
			canSubmit = false;
		}
		if (!cluster || cluster === '') {
			this.setState({ clusterError: true });
			canSubmit = false;
		}

		if (canSubmit) {
			const emergencyContact = [
				emergencyName,
				emergencyRelation,
				emergencyMobile,
				emergencyPhone
			];
			const data = {
				firstName: firstName,
				middleName: middleName,
				lastName: lastName,
				dob: dob,
				email: email,
				mobile: mobile,
				bloodGroup: bloodGroup.value,
				gender: gender,
				permanentAddress: permanentAddress,
				temporaryAddress: temporaryAddress,
				emergencyContact: emergencyContact,
				cluster: cluster.value
			};

			UserService.updateUserProfile(data)
				.then(response => {
					if (response.success) {
						toastr.success('', `Your profile has been updated.`);
						this.props.history.push(`/profile`);
					} else {
						toastr.error('', response.error.message);
					}
				})
				.catch(err => toastr.error('', err.message));
		}
		e.preventDefault();
	};
	handleProfilePic = () => {
		const { files } = this.state;
		if (files) {
			const file = files[0];
			const form = new FormData();
			form.append('image_url', file);
			UserService.updateUserProfileImage(form)
				.then(response => {
					if (response.success) {
						toastr.success('', `Your profile image has been updated.`);
						this.setState({ showModal: false, ...response.data.user });
					} else {
						toastr.error('', response.error.message);
					}
				})
				.catch(err => toastr.error('', err.message));
		}
	};

	onFileChange = files => {
		this.setState({ files });
	};

	handleTabSelect = week => {
		this.setState({ selectedWeek: week, isActive: true });
		this.props.history.push(`/dashboard/${week.weekNo}/${week.year}`);
	};
	render() {
		const {
			id,
			firstName,
			middleName,
			lastName,
			email,
			bloodGroup,
			mobile,
			image,
			avatar,
			gender,
			temporaryAddress,
			permanentAddress,
			emergencyName,
			emergencyRelation,
			emergencyMobile,
			emergencyPhone,
			showModal,
			cluster
		} = this.state;
		let dob = moment(this.state.dob).format('YYYY-MM-DD');
		return (
			<ProfileWrapper>
				<WeekLists
					selectedKey={this.state.key}
					handleTabSelect={this.handleTabSelect}
					selectedWeek={this.state.selectedWeek}
					isActive={this.state.isActive}
				/>
				<Background />
				<InnerTemplate>
					{showModal && (
						<Modal
							show={this.state.showModal}
							onHide={() => this.setState({ showModal: false })}
							size='lg'
							aria-labelledby='contained-modal-title-vcenter'
						>
							<Modal.Header closeButton>
								<Modal.Title id='contained-modal-title-vcenter'>
									Upload image
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div>
									<Row>
										<Col sm={12}>
											<form encType='multipart-form'>
												{/* <input
													id="profilePic"
													type="file"
													onChange={this.onFileChange}
												/> */}
												<DropZone
													id='profilePic'
													onFilesAdded={this.onFileChange}
												/>
											</form>
										</Col>
									</Row>
								</div>
							</Modal.Body>
							<Modal.Footer>
								<div className='btn-wrap'>
									<Button bsStyle='primary' onClick={this.handleProfilePic}>
										Upload
									</Button>
									<Button onClick={() => this.setState({ showModal: false })}>
										{' '}
										Cancel{' '}
									</Button>
								</div>
							</Modal.Footer>
						</Modal>
					)}
					<h3>Edit Profile</h3>
					<form
						className='form__profile--edit'
						onSubmit={this.handleFormSubmit}
					>
						<div className='information-wrap'>
							<h4>Personal Information</h4>
							<div>
								<div className='form-row'>
									<div className='col-md-12 photo'>
										<div
											onClick={() => this.setState({ showModal: true })}
											className='form-group profile-image col-md-12'
										>
											<div className='image-wrapper'>
												<img
													src={
														avatar
															? `${process.env.REACT_APP_ASSETS_URL}images/uploads/users/${id}/${avatar}`
															: image
													}
													alt='profile'
												/>
											</div>
											<img className='camera' src={cameraIcon} alt='camera' />
										</div>
									</div>
									<div className='col-md-12'>
										<div className='form-row'>
											<div className='form-group col-md-6'>
												<label htmlFor='firstName'>First Name*</label>
												<input
													type='text'
													value={firstName}
													onChange={e =>
														this.setState({
															firstName: e.target.value,
															firstNameError: false
														})
													}
													className='form-control'
													maxLength='50'
												/>
												{this.state.firstNameError && (
													<span className='error-msg'>
														Please enter your first name.
													</span>
												)}
											</div>
											<div className='form-group col-md-6'>
												<label htmlFor='firstName'>Middle Name</label>
												<input
													type='text'
													value={middleName}
													onChange={e =>
														this.setState({
															middleName: e.target.value,
															middleNameError: false
														})
													}
													className='form-control'
													maxLength='50'
												/>
											</div>
										</div>

										<div className='form-row'>
											<div className='form-group col-md-6'>
												<label htmlFor='lastName'>Last Name*</label>
												<input
													type='text'
													value={lastName}
													onChange={e =>
														this.setState({
															lastName: e.target.value,
															lastNameError: false
														})
													}
													className='form-control'
													maxLength='50'
												/>
												{this.state.lastNameError && (
													<span className='error-msg'>
														Please enter your last name.
													</span>
												)}
											</div>

											<div className='form-group col-md-6'>
												<label htmlFor='email'>Email*</label>
												<input
													type='text'
													value={email}
													onChange={e =>
														this.setState({
															email: e.target.value,
															emailError: false
														})
													}
													className='form-control'
													readOnly={true}
												/>
											</div>
										</div>
										<div className='form-row'>
											<div className='form-group col-md-6'>
												<label htmlFor='MobileNo'>Mobile No.*</label>
												<input
													type='number'
													value={mobile}
													onChange={e =>
														this.setState({
															mobile: e.target.value,
															mobileError: false
														})
													}
													className='form-control'
												/>
												{this.state.mobileError && (
													<span className='error-msg'>
														Please enter your mobile.
													</span>
												)}
											</div>
											<div className='form-group col-md-6'>
												<label htmlFor='bloodGroup'>Blood Group*</label>
												<Select
													key='project'
													defaultValue={bloodGroup}
													value={bloodGroup}
													options={bloodGroupList}
													placeholder='Select blood group ...'
													onChange={e => this.setState({ bloodGroup: e })}
													className='select-wrapper select-project'
												/>
												{/* <input
													type="text"
													value={bloodGroup}
													onChange={e =>
														this.setState({
															bloodGroup: e.target.value,
															bloodGroupError: false
														})
													}
													className="form-control"
												/> */}
												{this.state.bloodGroupError && (
													<span className='error-msg'>
														Please enter your Blood group.
													</span>
												)}
											</div>
										</div>
									</div>
								</div>
								<div className='form-row'>
									<div className='col-md-12'>
										<div className='form-row'>
											<div className='form-group-checkbox col-md-6'>
												<label htmlFor='staticEmail'>Gender*</label>
												<div className='form-check form-check-inline'>
													<div className='form-control-wrap'>
														<input
															type='radio'
															value='m'
															onChange={e =>
																this.setState({
																	gender: 'm'
																})
															}
															checked={gender === 'm'}
															className='form-check-input'
														/>
													</div>
													<label className='form-check-label'>Male</label>
												</div>

												<div className='form-check form-check-inline'>
													<div className='form-control-wrap'>
														<input
															type='radio'
															value='f'
															onChange={e =>
																this.setState({
																	gender: 'f'
																})
															}
															checked={gender === 'f'}
															className='form-check-input'
														/>
													</div>
													<label className='form-check-label'>Female</label>
												</div>
											</div>
											<div className='form-group col-md-6'>
												<label htmlFor='pAddress'>DOB (in A.D)*</label>
												<input
													type='date'
													value={dob}
													onChange={e =>
														this.setState({
															dob: e.target.value,
															dobError: false
														})
													}
													className='form-control'
												/>
												{this.state.dobError && (
													<span className='error-msg'>
														Please enter your date of birth in A.D.
													</span>
												)}
											</div>
										</div>
									</div>
								</div>
								<div className='form-row'>
									<div className='col-md-12'>
										<div className='form-row'>
											<div className='form-group col-md-6'>
												<label htmlFor='pAddress'>Permanent Address*</label>
												<input
													type='text'
													value={permanentAddress}
													onChange={e =>
														this.setState({
															permanentAddress: e.target.value,
															permanentAddressError: false
														})
													}
													className='form-control'
												/>
												{this.state.permanentAddressError && (
													<span className='error-msg'>
														Please enter your permanent address.
													</span>
												)}
											</div>
											<div className='form-group col-md-6'>
												<label htmlFor='pAddress'>Temporary Address</label>
												<input
													type='text'
													value={temporaryAddress}
													onChange={e =>
														this.setState({
															temporaryAddress: e.target.value,
															temporaryAddressError: false
														})
													}
													className='form-control'
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="form-row">
									<div className="col-md-12">
										<div className="form-row">
											<div className="form-group col-md-6">
												<label htmlFor='cluster'>Cluster*</label>
												<Select
													key='cluster'
													defaultValue={cluster}
													value={cluster}
													options={clusterList}
													placeholder='Select cluster'
													onChange={e => this.setState({ cluster: e })}
													className='select-wrapper select-project'
												/>
												{this.state.clusterError && (
													<span className='error-msg'>
														Please enter your cluster.
													</span>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='information-wrap'>
							<h4>Emergency Contact</h4>
							<div className='form-row'>
								<div className='col-md-12'>
									<div className='form-row'>
										<div className='col-md-6'>
											<label htmlFor='emergencyName'>Name*</label>

											<input
												type='text'
												value={emergencyName}
												onChange={e =>
													this.setState({
														emergencyName: e.target.value,
														emergencyNameError: false
													})
												}
												className='form-control'
											/>
											{this.state.emergencyNameError && (
												<span className='error-msg'>
													Please enter emergency contact's name.
												</span>
											)}
										</div>
										<div className='form-group col-md-6'>
											<label htmlFor='relationship'>Relationship*</label>

											<input
												type='text'
												value={emergencyRelation}
												onChange={e =>
													this.setState({
														emergencyRelation: e.target.value,
														emergencyRelationError: false
													})
												}
												className='form-control'
											/>
											{this.state.emergencyRelationError && (
												<span className='error-msg'>
													Please enter your relation with emergency contact.
												</span>
											)}
										</div>
									</div>
									<div className='form-row'>
										<div className='form-group col-md-6'>
											<label htmlFor='mobile'>Mobile Number*</label>

											<input
												type='text'
												value={emergencyMobile}
												onChange={e =>
													this.setState({
														emergencyMobile: e.target.value,
														emergencyMobileError: false
													})
												}
												className='form-control'
											/>
											{this.state.emergencyMobileError && (
												<span className='error-msg'>
													Please enter emergency contact's mobile.
												</span>
											)}
										</div>
										<div className='form-group col-md-6'>
											<label htmlFor='mobile'>Phone</label>

											<input
												type='text'
												value={emergencyPhone}
												onChange={e =>
													this.setState({
														emergencyPhone: e.target.value,
														emergencyPhoneError: false
													})
												}
												className='form-control'
											/>
											{/* {this.state.emergencyPhoneError && (
											<span className="error-msg">
												Please enter emergency contact's phone.
											</span>
										)} */}
										</div>
									</div>
								</div>
							</div>
							<div className='row form-button'>
								<div className='col-sm-10'>
									<button type='submit' className='btn btn-primary'>
										Update Profile
									</button>
									{/* <a href="/v1/admin/users" className="btn btn-default">
									Cancel
								</a> */}
								</div>
							</div>
						</div>
					</form>
				</InnerTemplate>
			</ProfileWrapper>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user.userDetail
});

const mapDispatchToProps = dispatch => ({
	fetchUserProfile: (userId = '') => {
		dispatch(UserAction.fetching);
		UserService.fetchUserProfile(userId)
			.then(response => {
				if (response.success) {
					dispatch(UserAction.fetched(response));
				}
				dispatch(UserAction.error(response));
			})
			.catch(err => dispatch(UserAction.error(err)));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit);

const ProfileWrapper = styled.div`
	padding-bottom: 60px;
	position: relative;
`;
const InnerTemplate = styled.div`
	margin: 0 auto;
	padding: 150px 0 40px;
	position: relative;
	width: 924px;

	.select-wrapper {
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		height: auto;
		/* line-height: 26px; */
		margin-bottom: 0;
	}
	.select-wrapper:focus,
	.select-wrapper > div:focus {
		border-color: rgba(255, 255, 255, 0.2);
	}

	h3 {
		margin-bottom: 40px;
	}

	h4 {
		font-size: 13px;
		font-weight: 700;
		margin-bottom: 20px;
		text-transform: uppercase;
	}

	.form__profile--edit {
		.information-wrap {
			margin-bottom: 30px;
			width: 800px;
		}
		.form-row {
			margin: 0 -25px 10px;
			display: inline-block;
			width: 100%;
			vertical-align: top;
			.css-1uccc91-singleValue {
				color: #fff;
				font-size: 14px;
			}
			.css-tj5bde-Svg {
				fill: #fff !important;
			}
			label {
				color: rgba(255, 255, 255, 0.5);
				font-size: 10px;
				font-weight: 400;
				text-transform: uppercase;
			}
			input {
				background: none;
				border: 0;
				border-bottom: 1px solid rgba(255, 255, 255, 0.2);
				border-radius: 0;
				box-shadow: none;
				padding: 0;
			}
			.form-control {
				color: #fff;
				height: 30px;
			}
			div[class*='col-md-'] {
				padding: 0 25px;
			}
			.form-group-checkbox {
				> label {
					display: block;
				}
				> div {
					align-items: center;
					display: flex;
					float: left;
					margin-right: 20px;
					margin-top: 6px;
					label {
						margin: 0 0 0 10px;
					}
				}
			}
		}
		.btn {
			border-radius: 4px;
			font-size: 16px;
			height: 59px;
			text-transform: uppercase;
			width: 294px;
		}
	}

	.photo {
		margin: 0;
		padding: 0;
	}
	.profile-image {
		position: relative;
		width: 150px;
		height: 150px;
		margin: 0 0 20px;
		padding: 0 !important;
		cursor: pointer;
		.image-wrapper {
			width: 150px;
			height: 150px;
			background: #fff;
			border-radius: 50%;
			overflow: hidden;
			img {
				height: 100%;
				object-fit: cover;
				object-position: top;
				width: 100%;
			}
		}
		.camera {
			position: absolute;
			top: 50%;
			left: 50%;
			visibility: hidden;
			transform: translate(-50%, -50%);
		}
		&:hover {
			.image-wrapper {
				&::before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: rgba(0, 0, 0, 0.4);
					border-radius: 50%;
				}
			}
			.camera {
				visibility: visible;
			}
		}
	}
	.form-button {
		margin-top: 40px;
	}
	.error-msg {
		color: red;
		font-size: 12px;
		font-weight: 300;
	}
`;
