import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel
} from 'react-accessible-accordion';
import { Panel } from 'react-bootstrap';
import moment from 'moment';
import Select from 'react-select';

import * as ProjectAction from '../../actions/project';
import ProjectService from '../../services/ProjectApi';
import WeekLists from '../../components/layout/WeekList';
import Background from '../../components/Background';

import RedExternalLinkIcon from '../../images/red-external.svg';
import GreenExternalLinkIcon from '../../images/green-external.svg';

const clusters = [
	{ value: '', label: 'All Projects' },
	{ value: 7, label: 'Undefined Cluster' },
	{ value: 1, label: 'Cluster-1' },
	{ value: 2, label: 'Cluster-2' },
	{ value: 3, label: 'Cluster-3' },
	{ value: 4, label: 'Cluster-4 (Israel)' },
	{ value: 5, label: 'Cluster-5 (Policy Lab)' },
	{ value: 6, label: 'Cluster-6 (Admin)' }
]

class Project extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedProjects: 1,
			key: 1,
			isActive: true,
			cluster: { value: '', label: 'All Projects'}
		};
	}

	componentDidMount() {
		this.props.listWeekProjectDetail();
		const { cluster, role } = this.props.authenticatedUser.user;
		if (
			cluster &&
			role == 'user'
		) {
			this.setState({
				cluster: { 
					value: cluster,
					label: clusters.find(c => c.value === cluster).label
				}
			})
		} else {
			this.setState({
				cluster: { value: '', label: 'All Projects' }
			})
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let data = {};

		if (nextProps.projects && nextProps.projects !== prevState.projects) {
			data.projects = nextProps.projects;
		}

		return data;
	}

	handleTabSelect = week => {
		this.setState({ selectedWeek: week, isActive: true });
		this.props.history.push(`/dashboard/${week.weekNo}/${week.year}`);
	};

	changeCluster = e => {
		this.setState({ cluster: {value: e.value, label: e.label} })
	}

	render() {
		const { projects, cluster } = this.state;
		let filteredProjects = projects;
		let hours = 0,
			totalHours = 0;
		if (filteredProjects.length) {
			filteredProjects.map(p => {
				const currWeek = p.detail.find(d => +d.weekNo === +moment().week());
				hours = hours + currWeek.hours;
				totalHours = totalHours + currWeek.users * 40;
			});
		}

		if (cluster.value) {
			filteredProjects = projects.filter(eachProject => {
				return eachProject.cluster === cluster.value
			})
		}

		return (
			<Projectwrapper>
				<WeekLists
					selectedKey={this.state.key}
					handleTabSelect={this.handleTabSelect}
					selectedWeek={this.state.selectedWeek}
					isActive={this.state.isActive}
				/>
				<Background />
				<InnerTemplate>
					<div className="col-md-4" style={{paddingLeft: '0px'}}>
						{/* <div className="week-title">
							<h1>Change Cluster</h1>
						</div> */}
						<Select
							options={clusters}
							value={this.state.cluster}
							onChange={this.changeCluster}
							className="select-wrapper select-project-cluster"
						/>
					</div>
					{filteredProjects.length > 0 && (
						<div>
							<Panel className="centeralign">
								<Panel.Heading>
									<Panel.Title componentClass="div">
										<div className="week-title">
											<h1>Running Projects</h1>
										</div>
									</Panel.Title>
								</Panel.Heading>
								<Panel.Body>
									<EstimatedHoursWrap>
										<div>
											<h4>Total hours estimated this week</h4>
											<EstimatedHour>
												<strong>{hours}</strong> hrs
											</EstimatedHour>
										</div>
										<ProgressHourWrap>
											<ProgressHour
												style={{
													width: (hours / totalHours) * 100 + '%'
												}}
											></ProgressHour>
										</ProgressHourWrap>
									</EstimatedHoursWrap>
								</Panel.Body>
							</Panel>
							<div>
								<Accordion
									allowZeroExpanded={true}
									className="accordion accordion__panel--projects"
								>
									<AccordionItem key="0">
										<AccordionItemHeading>
											<AccordionItemButton></AccordionItemButton>
										</AccordionItemHeading>
										<div>
											<AccordionItemPanel style={{ display: 'block' }}>
												<h4>Weekly estimations for all projects</h4>
												<div className="running-projects">
													<table>
														<ProjectDetailHeader
															data={[
																moment().week() - 1,
																moment().week(),
																moment().week() + 1
														]}															
														selectedWeek={moment().week()}
														/>

														<ProjectDetailBody
															data={filteredProjects}
															selectedWeek={moment().week()}
														/>
													</table>
												</div>
											</AccordionItemPanel>
										</div>
									</AccordionItem>
								</Accordion>
							</div>
						</div>
					)}
				</InnerTemplate>
			</Projectwrapper>
		);
	}
}
const ProjectDetailHeader = props => {
	let currentDate = moment();
    let prevWeek = currentDate.clone().subtract(1, 'week');
    let futureWeek = currentDate.clone().add(1, 'week');
    let prevWeekNo = moment(prevWeek).week();
	let futureWeekNo = moment(futureWeek).week();
	let thisWeek = moment().week();
	let weeks = [prevWeekNo,thisWeek, futureWeekNo];
	if(weeks[2] === 1){
		weeks = [prevWeekNo,thisWeek];		
	}
	if(thisWeek === 1){
		weeks = [thisWeek,futureWeekNo];
	}	
	if (props.data) {
		// const header = props.data.map((h, i) => {
		const header = weeks.map((h, i) => {
			if (h === props.selectedWeek) {
				return (
					<th key={i} className="active">
						<table>
							<tbody>
								<tr>
									<th className="selected-week-table-head">This week</th>
								</tr>
								<tr>
									<th>{h}</th>
								</tr>
							</tbody>
						</table>
						{/* <span>This week</span>{h.weekNo} */}
					</th>
				);
			} else {
				return <th key={i}>{h}</th>;
			}
		});

		return (
			<thead>
				<tr>
					<th className="week-label">Week # →</th>
					<th></th>
					{header}
				</tr>
			</thead>
		);
	}
	return (
		<thead>
			<tr></tr>
		</thead>
	);
};

const ProjectDetailBody = props => {
	let currentDate = moment();
    let prevWeek = currentDate.clone().subtract(1, 'week');
    let futureWeek = currentDate.clone().add(1, 'week');
    let prevWeekNo = moment(prevWeek).week();
	let futureWeekNo = moment(futureWeek).week();
	let thisWeek = moment().week();
	let weeks = [prevWeekNo, thisWeek, futureWeekNo];
	if(weeks[2] === 1){
		weeks = [prevWeekNo, thisWeek];		
	}
	if(thisWeek === 1){
		weeks = [thisWeek,futureWeekNo];
	}
		
	// const weeks = [moment().week() - 1, moment().week(), moment().week() + 1];
	if (props.data) {
		const rows = props.data.map(p => {
			const currWeek = p.detail.find(d => +d.weekNo === +props.selectedWeek);
			return (
				<tr key={p.title}>
					<td>
						<strong>{p.title}</strong>
						<div className="stg-prod-cont">
							{
								p.stage_url && p.stage_url.length > 0?
								<>
									<a href ={`${p.stage_url}`} target="_blank" className="stag-link" data-toggle="Visit Staging Site">
										<img src={GreenExternalLinkIcon} fill="red" stroke="green" className="ext-link-icon" />
									</a>
								</>: ''
							}
							{
								p.stage_url && p.prod_url?
								<span> | </span>: ''
							}
							
							{
								p.prod_url && p.prod_url.length > 0?
								<>
									<a href={`${p.prod_url}`} target="_blank" className="stag-link">
										<img src={RedExternalLinkIcon} fill="red" stroke="geren" className="ext-link-icon" />
									</a>
								</>: ''
							}
						</div>
					</td>
					<td className="people-involved">{`${currWeek.users} people involved this week.`}</td>
					{weeks.map(w => {
						const week = p.detail.find(d => +d.weekNo === +w);
						if (week) {
							return (
								<td
									key={week.weekNo}
									className={
										+week.weekNo === +props.selectedWeek ? 'active' : ''
									}
								>
									{week.hours}
								</td>
							);
						}
						return <td key={w}>-</td>;
					})}
				</tr>
			);
		});
		return <tbody>{rows}</tbody>;
	}
	return null;
};

const mapStateToProps = state => ({
	projects: state.project.projects,
	authenticatedUser: state.auth
});
const mapDispatchToProps = dispatch => ({
	listWeekProjectDetail: () => {
		dispatch(ProjectAction.fetching);
		ProjectService.listWeekProjectDetail()
			.then(response => {
				if (response.success) {
					dispatch(ProjectAction.fetched(response));
				} else {
					dispatch(ProjectAction.error(response));
				}
			})
			.catch(err => dispatch(ProjectAction.error(err)));
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Project);

const Projectwrapper = styled.div`
	padding-bottom: 60px;
	position: relative;
`;
const InnerTemplate = styled.div`
	margin: 0 auto;
	padding: 150px 0 40px;
	position: relative;
	width: 924px;
	.css-1uccc91-singleValue{
		color: #fff;
	}
	.panel-title {
		align-items: flex-end;
		display: flex;
		width: 100%;
		.week-title {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			margin-right: 16px;
			h1 {
				color: #fff;
				display: flex;
				font-size: 72px;
				font-weight: 300;
				line-height: 68px;
				margin-bottom: 0;
			}
			span {
				color: #fff;
				font-weight: 300;
				font-size: 10px;
				opacity: 0.5;
			}
		}
	}
	.people-involved {
		font-size: 12px;
		font-weight: 500;
		color: #333333;
	}
	.accordion__panel {
		border-radius: 8px;
		&--projects {
			.accordion__panel {
				padding: 0;
			}
			h4 {
				border-bottom: 1px solid #ddd;
				font-size: 16px;
				font-weight: bold;
				padding: 24px 38px 40px;
			}
			.running-projects {
				padding: 9px;
				table {
					margin-top: -23px;
				}
			}
		}
		table tbody tr {
			&:hover {
				background: #f7f7f7;
			}
			td {
				padding: 16px 30px;
				.stg-prod-cont {
					margin-left: 5px;
					font-size: 12px;
					display: inline;
					position: relative;
					top: -2px;
					.stag-link{
						.ext-link-icon{
							height: 14px;
							width: 14px;
						}
					}
					.prod-link {
						color: red;
					}
				}
			}
		}
	}
`;
const EstimatedHoursWrap = styled.div`
	margin-bottom: 46px;
	> div {
		align-items: center;
		color: rgba(255, 255, 255, 0.5);
		display: flex;
		font-size: 14px;
		justify-content: space-between;
		position: relative;
		h4 {
			font-size: 14px;
			margin: 0;
			text-transform: uppercase;
		}
	}
`;

const EstimatedHour = styled.span`
	font-size: 14px;
	strong {
		color: #fff;
		font-size: 30px;
	}
`;

const ProgressHourWrap = styled.div`
	background: rgba(242, 103, 34, 0.2);
	border-radius: 5px;
	height: 3px;
	width: 100%;
`;

const ProgressHour = styled.div`
	background: #f26722;
	border-radius: 5px;
	height: 3px;
`;
