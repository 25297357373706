import React, { Component } from "react";
import styled from "styled-components";

import Arrow from '../../images/arrow-up.png';

class UserDetailCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { user } = this.props;

        return (
            <UserCardWrap>
                <img src={Arrow} alt="arr" className="user-arr"/>
                <div className="image-wrap">
                    <img
                        src={user.profilePic}
                        className="user-image"
                        alt={user.firstName}
                    />
                </div>
                <div className="info-wrap">
                    <h3> {user.firstName} {user.lastName} </h3>
                    <div className="user-details">
                        <p>
                            <span> {user.email} </span>
                            {user.mobile? <span className="info-divider">|</span>: ''}
                            <span> {user.mobile} </span>
                            {user.bloodGroup? <span className="info-divider">|</span>: ''}
                            <span> {user.bloodGroup} </span>
                        </p>
                    </div>
                </div>
            </UserCardWrap>
        );
    }
}

const UserCardWrap = styled.div`
    background: #fff;
    color: rgba(0,0,0,0.8);
    padding: 20px;
    border-radius: 8px;
    border: 1px solid rgba(40,45,58,0.1);
    display: flex;
    box-shadow: 0px 2px 44px rgba(0, 0, 0, 0.5);;
    margin-botom: 30px;
    .user-arr {
        position: absolute;
        top: -11px;
        left: 15px;
    }
    .image-wrap {
        margin-right: 20px;
        .user-image {
            width: 78px;
            height: 78px;
            object-fit: cover;
            border-radius: 50%;
        }
    }
    .info-wrap {
        h3 {
            color: rgba(0, 0, 0, 0.8);
        }
        .user-details {
            color: #2C303F;
            p{
                span{
                    margin-right: 2px;
                }
                .info-divider {
                    color: rgba(162, 162, 162, 0.7);
                }
            }
        }
    }
`;


export default UserDetailCard;
