const initialState = {
	fetching: true,
	fetched: false,
	error: null,
	adminPendingLeaveFetching: true,
	adminPendingLeaveFetched: false,
	adminPendingLeaveError: null,
	adminPendingLeaves: []
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case 'ADMIN_PENDING_LEAVE_FETCHING':
			state = {
				...state,
				...{ error: null, fetching: true, fetched: false }
			};
            break;
        case 'ADMIN_PENDING_LEAVE_FETCHED':
            state = {
                ...state,
                ...{
                    error: null,
                    adminPendingLeaves: payload.data,
                    fetching: false,
                    fetched: true
                }
            };
            break;
        case 'ADMIN_PENDING_LEAVE_ERROR':
            state = {
                ...state,
                ...{
                    error: payload,
                    fetching: false,
                    fetched: true
                }
            };
            break;
        default:
            break;
        }
    return state;
}

export default reducer;