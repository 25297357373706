import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import TextareaAutosize from 'react-autosize-textarea';
import Background from '../../components/Background';
import Select from 'react-select';
import * as LeaveAction from '../../actions/leave';

import LeaveService from '../../services/LeaveApi';
import OfficialTrip from '../../services/WorkinFromHomeApi';
import * as HolidayAction from '../../actions/holidays';
import HolidayService from '../../services/HolidayApi';
import Calendar from '../../components/calendar/Index';
import WeekLists from '../../components/layout/WeekList';
import TripIcon from '../../images/ic-official-trip.svg';
import PodiumIcon from '../../images/podium.svg';

class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			from: moment().format('YYYY-MM-DD'),
			to: moment().format('YYYY-MM-DD'),
			days: 0,
			holidays: [],
			remarks: '',
			leaveList: [],
			userLeaves: [],
			leaveError: false,
			flag: '',
			months: [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec'
			],
			trips: [
				{
					value: 'national',
					label: 'Domestic'
				},
				{
					value: 'international',
					label: 'International'
				}
			]
		};
	}


	componentDidMount() {
		const { user } = this.state.auth;
		this.props.fetchHolidayPlans();
		this.props.fetchLeaveTypes(user.id, user.supervisor);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let data = {};

		if (nextProps.auth !== prevState.auth) {
			data.auth = nextProps.auth;
		}

		if (nextProps.userLeaves !== prevState.userLeaves) {
			data.userLeaves = nextProps.userLeaves;
		}

		if (nextProps.holidays !== prevState.holidays) {
			data.holidays = nextProps.holidays;
		}

		if (
			Object.keys(nextProps.leaveTypes).length &&
			nextProps.leaveTypes !== prevState.leaveTypes
		) {
			data.supervisor = nextProps.leaveTypes.supervisor;
		}

		return data;
	}

	handleSave = e => {
		e.preventDefault();
		const { auth, leaveList, remarks, months, flag, visitType } = this.state;

		let canSubmit = true;
		if (leaveList.length < 1) {
			canSubmit = false;
			this.setState({ leaveError: true });
		}
		if (!visitType) {
			canSubmit = false;
			this.setState({ visitTypeError: true });
		}
		if (canSubmit) {
			const dates = [];
			const formData = [];

			leaveList.map(l => {
				let year =l.year;
				let month = months.indexOf(l.month) + 1;
				let day = l.day;
				let leave = moment(`${year}-${month}-${day}`).format('YYYY-MM-DD');
				let days = 1;
				dates.push(moment(leave).format('MMM DD, YYYY'));

				formData.push({
					userId: auth.user.id,
					from: leave,
					to: leave,
					days: days,
					type: visitType === 1 ? 'officialTrip' : 'officialEvent',
					remarks: remarks,
					approvedBy: auth.user.supervisor,
					flag: flag ? flag : 'national',
          fiscalYears:''
				});
			});

			let data = {
				body: dates,
				remarks: remarks || '',
				flag: flag ? flag : 'national'
			};

			OfficialTrip.submitTrip({ userId: auth.user.id }, { formData, data })
				.then(response => {
					if (response.success) {
						toastr.success(
							'',
							`Official Trip Application has been submitted successfully`
						);
						this.props.history.push('/profile?key=3');
					} else {
						toastr.error('', response.error.message);
					}
				})
				.catch(err => toastr.error('', err.error.message));
		}
	};

	handleDayPeriod = (month, day, period, year) => {
		const { leaveList } = this.state;
		let days = leaveList.length;
		let list = [];

		leaveList.map((l, i) => {
			let m = l.month;
			let d = l.day;
			let p = l.period;
      let y = l.year;
			l.cell.classList.add('bg-leave');
			l.cell.classList.remove('bg-half-leave');
			l.cell.classList.remove('bg-half-leave-second');
			if (l.day !== day || l.month !== month || l.year !== year) {
				if (l.period !== 'full') {
					days = days - 0.5;
					l.cell.classList.remove('bg-leave');
					l.cell.classList.add(
						l.period === 'firstHalf' ? 'bg-half-leave' : 'bg-half-leave-second'
					);
				}
			}
			if (l.day === day && l.month === month  && l.year === year) {
				if (period !== 'full') {
					days = days - 0.5;
					l.cell.classList.remove('bg-leave');
					l.cell.classList.add(
						period === 'firstHalf' ? 'bg-half-leave' : 'bg-half-leave-second'
					);
				}
				p = period;
			}
			list.push({ month: m, day: parseInt(d), period: p, cell: l.cell, year: y });
		});
		this.setState({ leaveList: list, days });
	};

	calculateDays = leaves => {
		let days = 0;
		leaves.forEach(l => {
			if (l.period === 'full') {
				days = days + 1;
			} else {
				days = days + 0.5;
			}
		});
		return days;
	};

	handleOnClick = (month, day, cell, year) => {
		const { leaveList } = this.state;
		let append = true,
			index = 0;
		leaveList.map((l, i) => {
			if (l.day === parseInt(day.nodeValue) && l.month === month && l.year === year) {
				append = false;
				index = i;
			}
		});
		let days = this.calculateDays(leaveList);
		if (append) {
			cell.classList.add('bg-leave');
			leaveList.push({
				month: month,
        year,
				day: parseInt(day.nodeValue),
				period: 'full',
				cell: cell
			});
			days = days + 1;

			this.setState({ leaveList, days: days, leaveError: false });
		} else {
			cell.classList.remove('bg-leave');
			cell.classList.remove('bg-half-leave');
			cell.classList.remove('bg-half-leave-second');
			leaveList.splice(index, 1);
			days = this.calculateDays(leaveList);
			this.setState({ leaveList, days: days, leaveError: false });
		}
	};

	handleTabSelect = week => {
		this.setState({ selectedWeek: week, isActive: true });
		this.props.history.push(`/dashboard/${week.weekNo}/${week.year}`);
	};

	changeTrip = i => e => {
		this.setState({ flag: e.value });
	};

	render() {
		const {
			days,
			remarks,
			leaveList,
			userLeaves,
			supervisor,
			holidays,
			leaveError,
			visitType,
			visitTypeError
		} = this.state;

		return (
			<LeaveWrapper>
				<Background />
				<WeekLists
					selectedKey={this.state.key}
					handleTabSelect={this.handleTabSelect}
					selectedWeek={this.state.selectedWeek}
					isActive={this.state.isActive}
				/>
				<LeaveApplication>
					<h1>Official Visit Application</h1>
					<form>
						<div className="row">
							<div className="form-group col-md-9">
								<label htmlFor="exampleInputEmail1">Trip or Event?</label>
								<div className={`trip-type ${visitTypeError ? 'error' : ''}`}>
									{[1, 2].map(t => (
										<button
											key={t}
											type="button"
											className={
												visitType === t
													? 'btn btn-leave-type selected-leave-type'
													: 'btn btn-leave-type'
											}
											onClick={() =>
												this.setState({ visitType: t, visitTypeError: false })
											}
										>
											<span
												className={t === 1 ? 'official-trip' : 'official-event'}
											>
												{t === 1 ? 'Official Trip' : 'Official Event'}
											</span>
										</button>
									))}
								</div>
							</div>
						</div>
						{visitType === 1 && (
							<div className="row">
								<div className="form-group col-md-9">
									<label htmlFor="exampleInputEmail1">
										Domestic or International?
									</label>
									<Select
										key="trips"
										defaultValue={{
											label: 'Domestic',
											value: 'national'
										}}
										options={this.state.trips}
										onChange={this.changeTrip()}
									/>
								</div>
								<div className="form-group col-md-3 leave-days-block">
									<label className="leave-days" htmlFor="exampleInputEmail1">
										{days}
									</label>
									<label
										className="leave-days-label"
										htmlFor="exampleInputEmail1"
									>
										{days <= 1 ? 'Day' : 'Days'}
									</label>
								</div>
							</div>
						)}
						<div className="row">
							<div className="form-group col-md-9">
								<label htmlFor="exampleInputEmail1">
									When are you going for the trip or event?
								</label>
								<Calendar
									leaveError={leaveError}
									leaveList={leaveList}
									leaveTags={true}
									clickable={true}
									userLeaves={userLeaves}
									holidays={holidays}
									handleOnClick={this.handleOnClick}
									handleDayPeriod={this.handleDayPeriod}
									type={'officialTrip'}
									user={this.props.auth.user}
								/>
							</div>
							<div className="form-group col-md-9">
								<div>
									<div className="supervisors">
										<label htmlFor="exampleInputEmail1">
											To Be Approved By
										</label>
										<div className="approver-wrapper">
											{supervisor && (
												<React.Fragment>
													<div className="approver">
														<img src={supervisor.image} alt="supervisor" />
													</div>
													<label htmlFor="approverName">
														{`${supervisor.firstName} ${supervisor.lastName}`}
													</label>
												</React.Fragment>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="form-group col-md-6">
								<label htmlFor="exampleInputEmail1">
									Any remarks or reasons for the trip ?
								</label>
								<TextareaAutosize
									style={{ minHeight: 38, maxHeight: 100 }}
									className="leave-remarks"
									onChange={e => this.setState({ remarks: e.target.value })}
									value={remarks}
									placeholder="Type your remarks or reasons here …"
								/>
							</div>
						</div>
						<div className="row">
							<div className="form-group col-md-1">
								<button
									type="submit"
									onClick={this.handleSave}
									className="btn btn-primary btn-apply"
								>
									APPLY FOR {visitType===1 ? 'OFFICIAL TRIP' : 'OFFICIAL EVENT' }
									</button>
							</div>
						</div>
					</form>
				</LeaveApplication>
			</LeaveWrapper>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	leaveTypes: state.leave.leaveTypes,
	userLeaves: state.leave.userLeaves,
	holidays: state.holiday.holidays
});

const mapDispatchToProps = dispatch => ({
	fetchLeaveTypes: (userId, supervisorId) => {
		dispatch(LeaveAction.fetching());
		LeaveService.getLeaveTypes(userId, supervisorId)
			.then(response => {
				if (response.success) {
					dispatch(LeaveAction.fetched(response));
				} else {
					dispatch(LeaveAction.error('error'));
				}
			})
			.catch(err => {
				dispatch(LeaveAction.error(err.message));
			});
	},
	fetchHolidayPlans: () => {
		dispatch(HolidayAction.fetching());
		HolidayService.holidayPlans()
			.then(response => {
				if (response.success) {
					dispatch(HolidayAction.fetched(response));
				} else {
					dispatch(HolidayAction.error('error'));
				}
			})
			.catch(err => {
				dispatch(HolidayAction.error(err.message));
			});
	}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Index);

const LeaveWrapper = styled.div``;
const LeaveApplication = styled.div`
	margin: 0 auto;
	padding: 150px 0 40px;
	position: relative;
	width: 924px;
	h1 {
		color: #fff;
		display: flex;
		font-size: 72px;
		font-weight: 300;
		margin-bottom: 40px;
	}
	.row {
		display: flex;
		justify-content: space-between;
		margin: 0 -9px;
		.form-group > .row {
			margin: 0;
			justify-content: flex-start;
		}
		&:first-child,
		&:nth-child(2) {
			> .form-group:last-child {
				padding-right: 0;
				width: 160px;
			}
		}
		&:before {
			display: none;
		}
		/* .leave-days-block {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			width: 150px;
		} */
		.supervisors {
			clear: both;
			margin: 37px 0 20px;
			.approver-wrapper {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				width: 200px;
				> label {
					color: rgba(255, 255, 255, 0.7);
					font-size: 12px;
					text-transform: none;
					margin-bottom: 0;
				}
				.approver {
					align-items: center;
					height: 32px;
					width: 32px;
					background: #dad9d9;
					border-radius: 50%;
					display: flex;
					margin-right: 10px;
					overflow: hidden;
					img {
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}
			}
		}
		label {
			text-transform: uppercase;
			font-weight: 500;
			font-size: 14px;
			margin-bottom: 12px;
			&.leave-days {
				display: block;
				font-size: 72px;
				font-weight: 700;
				line-height: 64px;
				margin-bottom: 0;
				text-align: center;
				width: 85px;
			}
			&.leave-days-label {
				color: rgba(255, 255, 255, 0.5);
				font-size: 18px;
				display: block;
				text-align: center;
				width: 85px;
			}
		}
		textarea {
			display: block;
			width: 342px;
			background: transparent;
			border: none;
			border-bottom: 1px solid rgba(216, 216, 216, 0.3);
			line-height: 20px;
			padding-bottom: 10px;
			margin: 12px 0 50px;
			padding: 0;
		}
		.form-check {
			margin-top: 28px;
			label {
				margin-left: 10px;
			}
		}
		.trip-type {
			display: flex;
			margin-bottom: 20px;
			&.error {
				border: 1px solid red;
			}
		}
		.btn-leave-type {
			height: 42px;
			border-radius: 4px;
			border: solid 1px rgba(151, 151, 151, 0.25);
			background-color: transparent;
			margin-right: 10px;
			position: relative;
			padding: 7px 18px 6px 10px;
			&:hover {
				color: #fff;
			}
			&.selected-leave-type {
				border: solid 1px #80d968;
				&:after {
					content: '';
					position: absolute; /* Sit on top of the page content */
					/* Hidden by default */
					width: 100%; /* Full width (cover the whole page) */
					height: 100%; /* Full height (cover the whole page) */
					border: 1px solid #979797;
					border-radius: 4px;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					border: none;
					color: #fff;
					background-color: transparent;
					z-index: 2;
				}
				span {
					color: #fff;
					&:before {
						opacity: 1;
					}
				}
			}
			span {
				color: rgba(255, 255, 255, 0.5);
				font-size: 16px;
				display: inline-flex;
				align-items: center;
				&:before {
					content: '';
					display: inline;
					height: 26px;
					width: 26px;
					background-repeat: no-repeat;
					object-fit: cover;
					margin-right: 4px;
					opacity: 0.5;
				}
				&.official-trip {
					&:before {
						background-image: url(${TripIcon});
						background-position: center;
					}
				}
				&.official-event {
					&:before {
						background-image: url(${PodiumIcon});
					}
				}
			}
		}
		.btn-apply {
			width: 294px;
			height: 59px;
			border-radius: 4px;
			font-size: 16px;
		}
	}
	.css-2b097c-container {
		width: 150px;
		> .css-yk16xz-control,
		.css-1pahdxg-control {
			background: none;
			border-color: #51804d !important;
			> div > div {
				color: #fff;
				font-size: 12px;
			}
		}
		svg {
			fill: #fff !important;
		}
		.css-yk16xz-control {
			border-radius: 2px;
		}
	}
`;
