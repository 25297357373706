import { 
	USER_TASKS_FETCHING,
	USER_TASKS_FETCHED,
	USER_TASKS_ERROR
 } from '../actions/types';

const initialState = {
	fetching: true,
	fetched: false,
	error: null,
	projects: [],
	userProjectFetching: true,
	userProjectFetched: false,
	userProjectError: null,
	projectList: [],
	userDeliverableFetching:true,
	userDeliverableFetched:false,
	userDeliverableError:null,
	userLeadingProjects: {
		fetching: true,
		fetched: false,
		leadingProjects: [],
		error: null
	},
	userTaskFetching: true,
	userTaskFetched: false,
	userTaskError: null,
	userTasks: null
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case 'PROJECT_FETCHING':
			state = {
				...state,
				...{ error: null, fetching: true, fetched: false }
			};
			break;
		case 'PROJECT_FETCHED':
			state = {
				...state,
				...{
					error: null,
					projects: payload.data.projects,
					fetching: false,
					fetched: true
				}
			};
			break;
		case 'PROJECT_ERROR':
			state = {
				...state,
				...{
					error: payload,
					fetching: false,
					fetched: true
				}
			};
			break;
		case 'USER_PROJECT_FETCHING':
			state = {
				...state,
				...{
					userProjectError: null,
					userProjectFetching: true,
					userProjectFetched: false
				}
			};
			break;
		case 'USER_PROJECT_FETCHED':
			state = {
				...state,
				...{
					userProjectError: null,
					projectList: payload.data,
					userProjectFetching: false,
					userProjectFetched: true
				}
			};
			break;
		case 'USER_PROJECT_ERROR':
			state = {
				...state,
				...{
					userProjectError: payload,
					userProjectFetching: false,
					userProjectFetched: true
				}
			};
			break;
		case 'USER_DELIVERABLE_FETCHING':
			state={
				...state,
				...{
					userDeliverableError: null,
					userDeliverableFetching: true,
					userDeliverableFetched: false

				}
			};
			break;
			case 'USER_DELIVERABLE_FETCHED':
			state={
				...state,
				...{
					userDeliverableError: null,
					userDeliverableFetching: true,
					userDeliverableFetched: false

				}
			};
			break;
			case 'USER_DELIVERABLE_ERROR':
			state={
				...state,
				...{
					userDeliverableError: payload,
					userDeliverableFetching: false,
					userDeliverableFetched: true

				}
			};
			break;
			case 'USER_LEADING_PROJECTS_FETCHING':
				state = {
					...state,
					...{
						userLeadingProjects: {
							fetching: true,
							fetched: false,
							error: false,
							leadingProjects: []
						}
					}
				}
			break;
			case 'USER_LEADING_PROJECTS_FETCHED':
				state = {
					...state,
					...{
						userLeadingProjects: {
							fetching: false,
							fetched: true,
							error: false,
							leadingProjects: payload
						}
					}
				}
			break;
			case USER_TASKS_FETCHING:
				state={
					...state,
					...{
						userTaskError: null,
						userTaskFetching: true,
						userTaskFetched: false
					}
				};
				break;
			case USER_TASKS_FETCHED:
			state={
				...state,
				...{
					userTaskError: null,
					userTaskFetched: true,
					userTaskFetching: false,
					userTasks: payload
				}
			};
			break;
			case 'USER_DELIVERABLE_ERROR':
			state={
				...state,
				...{
					userTaskError: payload,
					userTaskFetched: true,
					userTaskFetching: false,
				}
			};
		default:
			break;
	}
	return state;
};
export default reducer;
