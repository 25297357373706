import React, { Component } from "react";
import { connect } from "react-redux";
import Select from 'react-select';
import styled from "styled-components";

const selectors = [
    {label: 'less than', value: '<' },
    {label: 'greater than', value: '>' },
    {label: 'equal to', value: '=' },
    {label: 'greater than or equal to', value: '>=' },
    {label: 'less than or equal to', value: '<=' },
]

const bloodGroupList = [
	{ label: 'A positive (A+)', value: 'A+' },
	{ label: 'A negative (A-)', value: 'A-' },
	{ label: 'B positive (B+)', value: 'B+' },
	{ label: 'B negative (B-)', value: 'B-' },
	{ label: 'O positive (O+)', value: 'O+' },
	{ label: 'O negative (O-)', value: 'O-' },
	{ label: 'AB positive (AB+)', value: 'AB+' },
	{ label: 'AB negative (AB-)', value: 'AB-' }
];

class Filter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectSelector: selectors[0],
            hourSelector: selectors[1],
            hours: '',
            projects: ''
        };
    }

    componentDidMount() {
        const { filterParams } = this.props;
        if (filterParams.projects) {
            this.setState({ 
                projects: filterParams['projects'][1],
                projectSelector: selectors.find(s => s.value === filterParams['projects'][0])
            })
        }
        if (filterParams.hours) {
            this.setState({ 
                hours: filterParams['hours'][1],
                hourSelector: selectors.find(s => s.value === filterParams['hours'][0])
            })
        }
        if (filterParams.bloodGroup) {
            this.setState({
                bloodGroup: bloodGroupList.find(b => b.value === filterParams.bloodGroup)
            })
        }
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    submitForm = () => {
        const { bloodGroup, hours, projects, hourSelector, projectSelector } = this.state;
        let canSubmit = false;
        
        if((projects && projects >= 0) || (hours && hours >= 0) || (bloodGroup && bloodGroup.value.length) > 0) {
            canSubmit = true;
        } else {
            this.setState({ displayError: true })
        }
        
        if(canSubmit) {
            this.props.setAdvancedFilterParams({
                bloodGroup: bloodGroup,
                hours: [hourSelector.value, hours],
                projects: [projectSelector.value, projects]
            })
        }
    }

    removeAdvancedFilterParams = () => {
        this.setState({
            projectSelector: selectors[0],
            hourSelector: selectors[1],
            hours: '',
            projects: '',
            bloodGroup: ''
        })
        this.props.resetAdvancedFilterParams()
    }

    render() {
        const { bloodGroup, projectSelector, hourSelector, hours, projects } = this.state;

        return (
            <FilterMenuWrap>
                <div>
                    <div className="row row-item">
                        <div className="col-md-5 col-label">
                            Involved in projects
                        </div>
                        <div className="col-md-4 col-selector">
                            <Select
                                key='project'
                                // defaultValue={selectors[0]}
                                value={projectSelector}
                                options={selectors}
                                onChange={e => this.setState({ projectSelector: e })}
                                placeholder="Select one"
                                className='select-filter'
                            />
                        </div>
                        <div className="col-md-3 col-input">
                            <input
                                type="number"
                                className="input-numbers"
                                value={projects}
                                name="projects"
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="row row-item">
                        <div className="col-md-5 col-label">
                            Estimated hours in projects
                        </div>
                        <div className="col-md-4 col-selector">
                            <Select
                                key='project'
                                // defaultValue={selectors[1]}
                                value={hourSelector}
                                options={selectors}
                                onChange={e => this.setState({ hourSelector: e })}
                                placeholder="Select one"
                                className='select-filter'
                            />
                        </div>
                        <div className="col-md-3 col-input">
                            <input
                                type="number"
                                className="input-numbers"
                                value={hours}
                                name="hours"
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="row row-item final-row">
                        <div className="col-md-5 col-label">
                            Bloodgroup
                        </div>
                        <div className="col-md-7 col-selector">
                            <Select
                                key='project'
                                // defaultValue={Select}
                                value={bloodGroup}
                                options={bloodGroupList}
                                onChange={e => this.setState({ bloodGroup: e })}
                                placeholder="Select one"
                                className='select-filter'
                            />
                        </div>
                    </div>
                    {
                        this.state.displayError? 
                            <p className="error-msg"> *Enter a param </p>: ''
                    }
                    <div className="submit-wrap">
                        <button className="btn btn-primary filter-button set-filter" onClick={this.submitForm}>
                            <span>FILTER</span>
                        </button>
                        <button className="btn btn-primary filter-button reset" onClick={this.removeAdvancedFilterParams}>
                            <span>RESET</span>
                        </button>
                    </div>
                </div>
            </FilterMenuWrap>
        );
    }
}

const FilterMenuWrap = styled.div`
    background: #fff;
    color: rgba(0, 0, 0, 0.8);
    padding: 35px 20px;
    .select-filter {
		border-bottom: 1px solid rgba(102,84,84,0.2);
		height: auto;
        margin-bottom: 0;
        .css-yk16xz-control {
            border: none;
        }
        .css-1pahdxg-control {
            border: none;
        }
        .css-1wa3eu0-placeholder {
            color: rgba(51,51,51,0.7) !important;
        }
        .css-1uccc91-singleValue {
            color: rgba(51,51,51,0.7) !important;
        }
        .css-1okebmr-indicatorSeparator {
            width: 0px;
        }
        .css-tlfecz-indicatorContainer {
            color: rgba(0, 0, 0, 0.8) !important;
        }
	}
	.select-filter:focus,
	.select-filter > div:focus {
		border-color: rgba(255, 255, 255, 0.2);
    }
    input {
        background: none;
        border: 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 0;
        box-shadow: none;
        padding: 0;
    }
    .row-item {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .col-selector {
            padding: 0px;

        }
        .col-input {
            input { 
                width: 100%;
                border-bottom: 1px solid rgba(102, 84, 84, 0.2);
                color: rgba(51,51,51,0.7);
            }
            .input-numbers {
                height: 39px;
            }
        }
    }
    .final-row {
        padding-right: 12px;
    }
    .error-msg {
        color: red;
        display: flex;
        flex-direction: row-reverse;
    }
    .submit-wrap {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 30px;
        .filter-button{
            border-radius: 18px;
            font-size: 12px;
            line-height: 16px;
            height: 40px;
            width: 85px;
        }
        .set-filter {
            font-size: 12px;
            line-height: 16px;
            color: #FFFFFF;
        }
        .reset {
            margin-right: 10px;
            color: #667689;
            background: #FFFFFF;
            border: 1px solid #667689;
        }
    }
`;

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Filter);
