import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import { connect } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";
import WeekApi from "../../services/WeekApi";
import UserService from "../../services/UserApi";
import * as WeekAction from "../../actions/week";
import * as UserAction from "../../actions/user";
import ScrollBar from "react-scrollbar/dist/no-css";
import SearchIcon from "../../images/ic-search.svg";
import "react-scrollbar/dist/css/scrollArea.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

class weekList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: true,
      showProfileMessage: false,
      year: (moment().weekYear()).toString(),
    };
  }

  componentDidMount() {
    let { year } = this.props;
    if (year) {
      this.setState({ year })
    }

    this.props.getWeekData(year || this.state.year);
    this.props.fetchUserProfile(this.props.auth.user.id);

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let data = {};
    if (Object.keys(nextProps.weekData).length && nextProps.weekData !== prevState.weekData) {
      data.weekData = nextProps.weekData;
      data.weekList = nextProps.weekData.weeks;
      data.yearList = nextProps.weekData.totalYear;

      let selectWeek = nextProps.weekData.weeks.find(
        (week) => week.isCurrentWeek
      );

      data.selectedWeek = selectWeek ? selectWeek : prevState.selectedWeek ? prevState.selectedWeek : nextProps.weekData.weeks[nextProps.weekData.weeks.length - 1];


    }
    data.selectedWeekByUser = nextProps.selectedWeek ? nextProps.selectedWeek : prevState.selectedWeek ? prevState.selectedWeek : data.selectedWeek;

    if (nextProps.user !== prevState.user) {
      data.userDetail = nextProps.user.userDetail;
    }
    return data;
  }

  skipMessage = () => {
    let todayDate = new Date().getTime();
    localStorage.setItem("sombarDate", todayDate);
    this.setState({ showProfileMessage: false });
  };

  /**
     * Disable keydown event listner
     * @param {*} event 
     * @param {*} props 
     */

  disableKeydown = (event, props) => {
    this.props.shortcutKey();
  }

  selectedWeek = e => {
    this.setState({ userSearchWeek: e.target.value });
  };

  accordionChange = (year) => {
    this.props.getWeekData(year[0]);
    this.setState({ weekList: [] })
  };

  render() {
    let {
      weekList,
      userDetail,
      showProfileMessage,
      selectedWeekByUser,
      userSearchWeek,
      yearList,
      year
    } = this.state;

    let filteredWeek = weekList;
    if (
      userDetail &&
      (!userDetail.dob ||
        !userDetail.bloodGroup ||
        !userDetail.firstName ||
        !userDetail.lastName ||
        !userDetail.gender ||
        !userDetail.mobile ||
        !userDetail.permanentAddress ||
        !userDetail.emergencyContact[0] ||
        !userDetail.emergencyContact[1] ||
        !userDetail.emergencyContact[2])
    ) {
      let storedTime = localStorage.sombarDate;
      if (!storedTime) {
        showProfileMessage = true;
      } else {
        let pDate = new Date(+storedTime);
        let previousDate = moment(pDate).format("YYYY-MM-DD");
        let todayDate = moment().format("YYYY-MM-DD");
        showProfileMessage = todayDate > previousDate;
      }
    }

    if (weekList && selectedWeekByUser && userSearchWeek) {
      filteredWeek = weekList.filter(eachWeek => {
        return this.state.userSearchWeek === eachWeek.weekStartMonth.toLowerCase() || this.state.userSearchWeek === eachWeek.weekEndMonth.toLowerCase() || this.state.userSearchWeek.includes(eachWeek.weekStartMonth.toLowerCase()) || this.state.userSearchWeek.includes(eachWeek.weekEndMonth.toLowerCase())
      });
    }


    return (
      <Weeks>
        <WeekDetail>
          {selectedWeekByUser && (
            <div className="active-week">
              Week {selectedWeekByUser.weekNo}{" "}
              <span>
                {selectedWeekByUser.weekStartMonth}{" "}
                {selectedWeekByUser.weekStartDay} -{" "}
                {selectedWeekByUser.weekEndMonth}{" "}
                {selectedWeekByUser.weekEndDay}
              </span>
            </div>
          )}

          <Accordion preExpanded={[this.props.year || year]} onChange={(e) => this.accordionChange(e)}>

            {yearList && yearList.map((y, index) =>
              <AccordionItem uuid={y.year} key={index} >
                <AccordionItemHeading>
                  <AccordionItemButton >
                    <span className="year">{y.year}</span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="search-box">
                    <input id="months"
                      type="search"
                      placeholder="Type Month or jump to a date"
                      onChange={this.selectedWeek}
                      onFocus={this.disableKeydown}
                      onBlur={this.disableKeydown}
                    />
                  </div>
                  <ScrollBar
                    className="options"
                    contentClassName="content"
                    smoothScrolling={true}
                    minScrollSize={30}
                  >
                    <Tabs id="controlled-tab-example">
                      <Tab eventKey={1} title="Week">
                        <WeeklyYear weeklyList={filteredWeek} self={this} />
                      </Tab>
                    </Tabs>
                  </ScrollBar>
                </AccordionItemPanel>
              </AccordionItem>

            )}
          </Accordion>
        </WeekDetail>
        {showProfileMessage ? (
          <ProfileNotification>
            <div className="row">
              <p>Please complete your profile information.</p>
              <p>
                <a href="/edit/profile" className="btn btn-primary btn-profile">
                  Take me to profile
                </a>
                <span onClick={this.skipMessage} className="skip-now">
                  {" "}
                  Skip For Now{" "}
                </span>
              </p>
              <p>You will be seeing this until your profile is complete.</p>
            </div>
          </ProfileNotification>
        ) : (
            ""
          )}
      </Weeks>
    );
  }
}

const WeeklyYear = (props) => {
  if (!props.self.state.selectedWeek) {
    return null;
  }
  let selectedWeek = props.self.props.selectedWeek ? props.self.props.selectedWeek : props.self.state.selectedWeek;
  let isActive = props.self.props.isActive ? props.self.props.isActive : props.self.state.isActive;
  let year = props.weeklyList.length > 1 ? props.weeklyList[1].year : "";

  return props.weeklyList
    .map((week, index) => {
      let currentWeek;
      if (week.isCurrentWeek && week.year === (moment().weekYear()).toString()) {
        currentWeek = (
          <span className="current-week">
            Week {week.weekNo}
            <span className="month">
              {week.weekStartMonth} {week.weekStartDay} - {week.weekEndMonth}{" "}
              {week.weekEndDay}
            </span>
          </span>
        );
      } else {
        currentWeek = (
          <span>
            Week {week.weekNo}{" "}
            <span className="month">
              {week.weekStartMonth} {week.weekStartDay} - {week.weekEndMonth}{" "}
              {week.weekEndDay}
            </span>
          </span>
        );
      }

      return (
        <a onClick={() => props.self.props.handleTabSelect(week)} key={index}>
          <span
            className={
              isActive && selectedWeek.weekNo === index + 1 && selectedWeek.year === year ? "active" : ""
            }
          >
            {currentWeek}
          </span>
        </a>
      );
    })
    .reverse();
};

const mapStateToProps = (state) => ({
  weekData: state.week.weekData,
  user: state.user.userProfileDetail,
  auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
  getWeekData: (year) => {
    dispatch(WeekAction.fetching);
    WeekApi.listWeeksByYear({ year })
      .then((response) => {
        if (response.success) dispatch(WeekAction.fetched(response));
        dispatch(WeekAction.error("error fetching week"));
      })
      .catch((err) => {
        dispatch(WeekAction.error(err.message));
      });
  },

  fetchUserProfile: (userId) => {
    dispatch(UserAction.userProfileFetching);
    UserService.fetchUserProfile(userId)
      .then((response) => {
        if (response.success) dispatch(UserAction.userProfileFetched(response));
        dispatch(UserAction.error("Error fetching user information."));
      })
      .catch((err) => dispatch(UserAction.error(err)));
  }

});

export default connect(mapStateToProps, mapDispatchToProps)(weekList);

const Weeks = styled.div`
  #controlled-tab-example {
    /* display: flex;
		height: 60px;
		margin-left: 138px;
		position: fixed;
		z-index: 1;
		top: 0; */
    ul {
      border-bottom: 0;
      li a {
        background: transparent;
        border: 0;
        color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
        padding: 22px 40px 21px 22px;
        text-transform: uppercase;
      }
    }
    .nav-tabs {
      display: none;
    }
    .tab-content {
      /* background-color: rgba(216, 216, 216, 0.07); */
    }
  }
  #controlled-tab-example-pane-1 {
    /* display: flex;
		flex-direction: row-reverse;
		height: 64px;
		width: calc(100vw - 460px);
		overflow: hidden; */
    a {
      border-bottom: 1px solid #4f5569;
      color: rgba(255, 255, 255, 0.7);
      display: block;
      font-size: 12px;
      flex: 0 0 50px;
      height: 50px;
      line-height: 50px;
      padding: 0 50px;
      text-transform: uppercase;
      > span {
        display: block;
        position: relative;
        span {
          color: rgba(255, 255, 255, 0.5);
          &.month {
            color: rgba(255, 255, 255, 0.35);
            margin-left: 4px;
          }
        }
      }
      &:hover {
        background-color: #313747;
        cursor: pointer;
        text-decoration: none;
      }
      .current-week {
        &:before {
          background-image: linear-gradient(221deg, #ff6200, #db2b2b);
          border-radius: 50%;
          content: "";
          height: 9px;
          position: absolute;
          right: -30px;
          top: 20px;
          width: 9px;
        }
        small {
          color: rgba(255, 255, 255, 0.7);
          display: block;
          font-size: 8px;
          font-weight: 400;
          line-height: 0;
          margin-top: -12px;
          text-transform: uppercase;
        }
      }
      .active {
        color: #fff;
        span {
          color: #fff;
          &.month {
            color: rgba(255, 255, 255, 0.7);
          }
        }
        /* &:before {
					background-image: linear-gradient(221deg, #ff6200, #db2b2b);
					border-bottom-left-radius: 4px;
					border-bottom-right-radius: 4px;
					content: '';
					height: 64px;
					left: 0;
					position: absolute;
					width: 60px;
				} 
				.current-week:before {
					display: none;
				}*/
      }
    }
  }
`;

const ProfileNotification = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.5);
  padding: 32px 12px 8px;
  position: absolute;
  right: 20px;
  text-align: center;
  top: 70px;
  width: 344px;
  z-index: 1;
  &:before {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 8px solid #fff;
    content: "";
    height: 0;
    position: absolute;
    right: 20px;
    top: -8px;
    width: 0;
  }
  p {
    color: #282d3a;
    &:first-child {
      font-size: 16px;
      margin-bottom: 24px;
    }
    &:last-child {
      color: rgba(40, 45, 58, 0.5);
      font-size: 11px;
    }
  }
  .btn-profile {
    border-radius: 33px;
    display: block;
    font-weight: 700;
    line-height: 48px;
    margin: 0 auto 22px;
    padding: 0;
    text-align: center;
    width: 173px;
  }
  .skip-now {
    cursor: pointer;
    display: block;
    font-size: 11px;
    font-weight: 700;
    margin-bottom: 14px;
    text-transform: uppercase;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const WeekDetail = styled.ul`
  padding: 0;
  position: fixed;
  left: 160px;
  list-style: none;
  z-index: 100;
  .active-week {
    background-color: #373b48;
    font-size: 12px;
    padding: 21px 24px;
    text-transform: uppercase;
    width: 208px;
    position: relative;
    span {
      color: rgba(255, 255, 255, 0.7);
      margin-left: 2px;
      margin-right: 7px;
    }
    &:after {
      content: "";
      border-top: 4px solid #fff;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      // float: right;
      margin-top: 5px;
      position: absolute;
    }
  }
  .accordion {
	  width: 280px;
	  &__panel {
		  border: 0;
	  }
	  &__item{
		  border-bottom: 1px solid #434656;
		  &:hover {
			  box-shadow: none;
			  &:last-of-type .scrollarea {
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		  }
	  }
  }
  > li, .accordion {
    display: none;
    /* opacity: 0; */
    width: 280px;
  }
  &:hover li, &:hover .accordion {
    /* opacity: 1; */
    display: block;
  }
  .year {
	background-color: #373b48;
	color: #fff;
    display: block;
    font-size: 12px;
    font-weight: bold;
    padding: 13px 25px;
    &:before {
      content: "";
      border-top: 4px solid #fff;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      float: left;
      margin-top: 7px;
      margin-right: 14px;
    }
  }
  .search-box {
    background-color: #3e4352;
	padding: 15px 50px 5px;
	position: relative;
	&:before {
		background: url(${SearchIcon}) no-repeat left;
		bottom: 6px;
		content: "";
		height: 20px;
		left: 26px;
		position: absolute;
		width: 20px;
	}
  }
  input[type="search"] {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    display: block;
    font-size: 12px;
    padding-bottom: 5px;
    width: 190px;
    color: rgba(255,255,255,0.7);
  }
  .scrollarea {
    background-color: #3e4352;
    height: 298px;
    width: 280px;
    .scrollbar-container.vertical {
      height: calc(100% - 20px) !important;
      right: 6px;
    }
  }
`;
