import React, { Fragment } from 'react';
import styled from 'styled-components';

const ProjectShimmer = () => {
  return (
    <Fragment>
      <Shimmer>
        <div className="shimmer__wrapper">
          <div className="shimmer">
            <div className="shimmer__wrapper">

              <div className="shimmer__item">
                <div>
                  <div className="shimmer__block" style={{ maxWidth: "148px" }} />
                  <div className="shimmer__block" style={{ maxWidth: "128px" }} />
                  <div className="shimmer__block" style={{ maxWidth: "158px" }} />
                </div>
                <div>
                  <div className="shimmer__block" style={{ maxWidth: "515px" }} />
                </div>
              </div>

              <div className="shimmer__item">
                <div>
                  <div className="shimmer__block" style={{ maxWidth: "64px" }} />
                  <div className="shimmer__block" style={{ maxWidth: "286px" }} />
                </div>
                <div>
                  <div className="shimmer__block" style={{ maxWidth: "351px" }} />
                </div>
              </div>

              <div className="shimmer__item">
                <div>
                  <div className="shimmer__block" style={{ maxWidth: "254px" }} />
                  <div className="shimmer__block" style={{ maxWidth: "129px" }} />
                  <div className="shimmer__block" style={{ maxWidth: "162px" }} />
                </div>
                <div>
                  <div className="shimmer__block" style={{ maxWidth: "190px" }} />
                </div>
              </div>

              <div className="shimmer__item">
                <div>
                  <div className="shimmer__block" style={{ maxWidth: "59px" }} />
                  <div className="shimmer__block" style={{ maxWidth: "124px" }} />
                  <div className="shimmer__block" style={{ maxWidth: "161px" }} />
                </div>
                <div>
                  <div className="shimmer__block" style={{ maxWidth: "185px" }} />
                </div>
              </div>

              <div className="shimmer__item">
                <div>
                  <div className="shimmer__block" style={{ maxWidth: "59px" }} />
                  <div className="shimmer__block" style={{ maxWidth: "124px" }} />
                  <div className="shimmer__block" style={{ maxWidth: "161px" }} />
                </div>
                <div>
                  <div className="shimmer__block" style={{ maxWidth: "185px" }} />
                </div>
              </div>

              <div className="shimmer__item">
                <div>
                  <div className="shimmer__block" style={{ maxWidth: "208px" }} />
                  <div className="shimmer__block" style={{ maxWidth: "123px" }} />
                  <div className="shimmer__block" style={{ maxWidth: "159px" }} />
                </div>
                <div>
                  <div className="shimmer__block" style={{ maxWidth: "194px" }} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </Shimmer>
    </Fragment>
  );
};

const Shimmer = styled.div`
	.shimmer {
        &__wrapper {
          background-color: #fff;
          border-radius: 4px;
          overflow: hidden;
        }
        &__item {
            min-height: 79px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 14px 32px 13px;
            &:not(:last-child) {
              border-bottom: 1px solid rgba(40, 45, 58, 0.1);
            }
            >div {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              flex-basis: 100%;
              max-width: 100%;
            }
        }
        &__block {
          animation-duration: 2s;
          animation-fill-mode: forwards;
          animation-iteration-count: infinite;
          animation-name: placeHolderShimmer;
          animation-timing-function: linear;
          background-color: #ededed;
          background-image: linear-gradient(
            90deg,
            #ededed 14.36%,
            #d7d6d6 56.29%,
            #ededed 100%
          );
          background-repeat: no-repeat;
          background-size: 244px 104px;
          position: relative;
          height: 19px;
          border-radius: 10px;
          width: 100%;
          margin: 3px 6px 3px 0px;
        }
    }

    @keyframes placeHolderShimmer {
      0% {
        background-position: calc(0% - 300px) 0;
      }

      20%{
        background-position: calc(0% - 300px) 0;
      }

      80% {
        background-position: calc(100% + 300px) 0;
      }

      100% {
        background-position: calc(100% + 300px) 0;
      }
    }
`;

export default ProjectShimmer;
