export function adminPendingLeaveFetching() {
	return { type: 'ADMIN_PENDING_LEAVE_FETCHING' };
}

export function adminPendingLeaveFetched(payload) {
	return { type: 'ADMIN_PENDING_LEAVE_FETCHED', payload };
}

export function error(payload) {
	return { type: 'ADMIN_PENDING_LEAVE_ERROR', payload };
}